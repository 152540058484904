import React from "react";
import styles from "../../css/vendor/VendorMapsTableColumns.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const Column = ({ column = "" }) => {
	return (
		<div className={styles.Column}>
			<div className={styles.Column_name}>{column}</div>
		</div>
	);
};

const VendorMapsTableColumns = ({ columns = [] }) => {
	return (
		<div className={styles.VendorMapsTableColumns}>
			<div className={styles.VendorMapsTableColumns_inner}>
				{!isEmptyArray(columns) &&
					columns.map((col, idx) => (
						<Column key={`VENDOR-COL-${col}--${idx}`} column={col} />
					))}
			</div>
		</div>
	);
};

export default VendorMapsTableColumns;

VendorMapsTableColumns.defaultProps = {};

VendorMapsTableColumns.propTypes = {};
