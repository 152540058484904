import React from "react";
import styles from "../../css/banners/UpdateBannerForm.module.scss";
import { PropTypes } from "prop-types";
import { blue, blueGrey, green, red } from "../../helpers/utils_styles";
// components
import Textarea from "../shared/Textarea";
import CustomCheckbox from "../shared/CustomCheckbox";
import DatePickerSM from "../shared/DatePickerSM";
import ButtonSM from "../shared/ButtonSM";
import { format } from "date-fns";
import { isEmptyObj } from "../../helpers/utils_types";

const customCSS = {
	copy: {
		width: "50rem",
		fontSize: "1.5rem",
		fontWeight: "400",
		color: blueGrey[500],
	},
	clear: {
		padding: ".7rem 1.4rem",
		fontSize: "1.6rem",
		color: blueGrey[200],
		backgroundColor: red[600],
		marginRight: "1rem",
	},
	save: {
		padding: ".7rem 1.4rem",
		fontSize: "1.6rem",
		color: blueGrey[200],
		backgroundColor: blue[600],
	},
	msg: {
		backgroundColor: blueGrey[200],
	},
	selector: {
		width: "40rem",
	},
	expiry: {
		width: "40rem",
	},
	confirmed: {
		color: "#ffffff",
		backgroundColor: green[500],
		borderRadius: "5rem",
		padding: ".3rem 1.2rem",
	},
	notConfirmed: {
		color: "#ffffff",
		backgroundColor: red[600],
		borderRadius: "5rem",
		padding: ".3rem 1.2rem",
	},
};

const disableBtn = (formState) => {
	const { values, touched } = formState;
	const empty = isEmptyObj(touched);

	return empty;
};

const UpdateBannerForm = ({
	vals = {},
	formState = {},
	handleChange,
	handleCheckbox,
	handleDate,
	handleDelete,
	saveBannerUpdates,
}) => {
	return (
		<div className={styles.UpdateBannerForm}>
			<div className={styles.UpdateBannerForm_header}>
				<h2 className={styles.UpdateBannerForm_header_title}>
					Edit/Update/Remove Service Banner
				</h2>
				<p className={styles.UpdateBannerForm_header_desc}>
					Edit, update, remove or just disable this active service banner that's
					displayed in the User Portal (Admin Portal).
				</p>
				<p className={styles.UpdateBannerForm_header_created}>
					Service banner created on{" "}
					<b>{format(vals?.createdDate, "MM/DD/YYYY")}</b>
				</p>
			</div>
			<div className={styles.UpdateBannerForm_community}>
				Community: <b>{vals?.facilityName}</b>
			</div>
			<div className={styles.UpdateBannerForm_communityID}>
				Community ID: <b>{vals?.facilityID}</b>
			</div>
			<fieldset className={styles.UpdateBannerForm_form}>
				<div className={styles.UpdateBannerForm_form_item}>
					<Textarea
						name="message"
						id="message"
						label="Edit banner message"
						val={vals.message}
						handleChange={handleChange}
						maxChar={500}
						enableCharCount={true}
						customStyles={customCSS.msg}
					/>
				</div>

				<div className={styles.UpdateBannerForm_form_item}>
					<DatePickerSM
						label="Edit Expiration Date"
						id="expiry"
						name="expiry"
						val={format(vals.expiry, "MM/DD/YYYY")}
						handleDate={handleDate}
						focusMode={true}
						placeholder="Expiry date MM/DD/YYYY..."
						customStyles={customCSS.expiry}
					/>
				</div>
				<div className={styles.UpdateBannerForm_form_item}>
					<CustomCheckbox
						name="isEnabled"
						id="isEnabled"
						label="Enable Banner"
						val={vals.isEnabled}
						handleCheckbox={handleCheckbox}
					/>
				</div>
				<div
					className={styles.UpdateBannerForm_form_item}
					style={{ fontSize: "1.6rem", marginTop: "4rem" }}
				>
					Was Banner Notice Acknowledged?{" "}
					<b
						style={
							vals?.wasAcknowledged
								? customCSS.confirmed
								: customCSS.notConfirmed
						}
					>
						{vals?.wasAcknowledged ? "Yes" : "No"}
					</b>
				</div>
			</fieldset>
			<div className={styles.UpdateBannerForm_actions}>
				<ButtonSM handleClick={handleDelete} customStyles={customCSS.clear}>
					Delete Banner
				</ButtonSM>
				<ButtonSM
					isDisabled={disableBtn(formState)}
					handleClick={saveBannerUpdates}
					customStyles={customCSS.save}
				>
					Save Changes
				</ButtonSM>
			</div>
		</div>
	);
};

export default UpdateBannerForm;

UpdateBannerForm.defaultProps = {};

UpdateBannerForm.propTypes = {};
