import React, { useState, useEffect } from "react";
import styles from "../css/views/BannerControlsView.module.scss";
import { PropTypes } from "prop-types";
import {
	getAllCurrentServiceBanners,
	getFacilityServiceBanners,
} from "../helpers/utils_banners";
import { isEmptyArray } from "../helpers/utils_types";
// components
import BannersController from "../components/banners/BannersController";
import UrgentBanner from "../components/banners/UrgentBanner";
import ServiceBanner from "../components/banners/ServiceBanner";

// REQUIREMENTS:
// - Load ALL existing/currently showing banners
// - Search for banner by community name or facilityID
// - Create a new banner for a community
// - Edit/disable/delete an existing banner for a community

const BannerControlsView = ({
	globalState = {},
	currentFacility = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { parentsMap = {}, facilitiesMap = {} } = globalState;
	const { raw: facilities, ids, names } = parentsMap;
	const [allActiveBanners, setAllActiveBanners] = useState([]);
	const [userBanners, setUserBanners] = useState([]); // REMOVE THIS AFTER TESTING

	const fetchActiveBanners = async () => {
		const { token, userID } = currentUser;
		// const banners = await getAllCurrentServiceBanners(token);
		// BELOW IS JUST USED FOR TESTING THE APIS - REMOVE LATER!!! //
		// const altID = "30e9000c-0b38-4645-bde2-824bdbfed572";
		const [banners, bannersByUser] = await Promise.all([
			getAllCurrentServiceBanners(token),
			getFacilityServiceBanners(token, userID),
		]);

		if (!isEmptyArray(banners)) {
			setUserBanners([...bannersByUser]); // REMOVE THIS AFTER TESTING
			return setAllActiveBanners(banners);
		} else {
			setUserBanners([]); // REMOVE THIS AFTER TESTING
			return setAllActiveBanners([]);
		}
	};

	const refetchBanners = () => {
		fetchActiveBanners();
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchActiveBanners();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.BannerControlsView}>
			<BannersController
				currentUser={currentUser}
				currentFacility={currentFacility}
				activeBanners={allActiveBanners}
				parentsMap={parentsMap}
				facilitiesMap={facilitiesMap}
				// REMOVE THIS
				userBanners={userBanners}
				refetchBanners={refetchBanners}
			/>
		</div>
	);
};

export default BannerControlsView;

BannerControlsView.defaultProps = {};

BannerControlsView.propTypes = {};
