import { green, purple, red } from "./utils_styles";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import { isSpecialChar } from "./utils_validation";

//////////////////////////////////////////////////////////////////////
////////////////////// STRING PROCESSING UTILS //////////////////////
//////////////////////////////////////////////////////////////////////

const enforceStrMaxLength = (str, maxLength = 30) => {
	if (str?.length < maxLength) return str;
	return str.slice(0, maxLength);
};

const addEllipsis = (str, maxLength = 30) => {
	if (isEmptyVal(str)) return ``;
	if (str.length < maxLength) return str;
	const managedStr = enforceStrMaxLength(str, maxLength);
	return managedStr + "...";
};

/**
 * Checks for null/falsey values and provides fallback if null.
 * @param {String|Number} val - Target value to check if null/falsey.
 * @param {String|Number|Any} msg - A custom replacement to be returned if 'val' is null/falsey.
 * @returns {String|Number|Any} - Returns 'msg' value if 'val' is null/falsey otherwise returns 'val'.
 */
const replaceNullWithMsg = (val, msg) => {
	if (!val || val === null) return msg;
	return val;
};

// converts num to letter (MUST BE WITHIN RANGE: 97-122 A-Za-z)
// 65-122
const numToLetter = (num) => {
	const letter = String.fromCharCode(num);
	return letter;
};
// gets a 'random' number within a range
const numInRange = (min = 67, max = 122) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};
const getRandomNum = () => {
	return Math.round(Math.random() * (11 - 1) + 1);
};

// count: how many 'random chars' to generate
// used for ids in <ReassessReport/>
const generateID = (count = 8) => {
	const baseCount = range(1, count, (x) => x + 1);
	const random = baseCount
		.map((x) => {
			const inRange = numInRange();
			return numToLetter(inRange);
		})
		.join("");
	return random;
};

// creates a unique ID, w/ a 'timestamp' of when it was created
const generateUID = (idLength = 32) => {
	const x1 = generateID(idLength);
	return `${x1}=${Date.now()}`;
};

// generates a temp password of a given length
const generateTempPassword = (length = 10) => {
	let tempPwd = ``;

	for (let i = 0; i < length; i++) {
		const num = numInRange(65, 122);
		const char = numToLetter(num);
		tempPwd += isSpecialChar(char) ? `${getRandomNum()}` : `${char}`;
	}
	return tempPwd;
};

// copies a given val to user's clipboard
const copyTextToClipboard = (val) => {
	if (navigator?.clipboard) {
		return navigator.clipboard.writeText(val);
	} else {
		return null;
	}
};
// CONVERT DATA TO MIME TYPE //

const convertArrayData = (arrData) => {
	const converted = arrData.join(", ");
	return converted;
};

//////////////////////////////////////////////////////////////////////
/////////////////////// ARRAY PROCESSING UTILS ///////////////////////
//////////////////////////////////////////////////////////////////////

const range = (start, stop, callback) => {
	return Array.from({ length: stop - start }, (_, i) => callback(i + start));
};

const groupBy = (list, iteratee) => {
	return list.reduce((acc, item) => {
		const keyToSortBy = iteratee(item);
		if (!acc[keyToSortBy]) {
			acc[keyToSortBy] = [];
		}
		acc[keyToSortBy].push(item);
		return acc;
	}, {});
};

const removeDups = (list) => {
	return [...new Set(list)];
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;

	return function executedFunction() {
		var context = this;
		var args = arguments;

		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		var callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) func.apply(context, args);
	};
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// sorts string alphabetically (A-Z)
const sortByAlphaAsc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.localeCompare(b);
	});
};
// sorts string alphabetically (Z-A)
const sortByAlphaDesc = (list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return b?.localeCompare(a);
	});
};

/**
 * Sorts an array alphabetically by object property (key).
 * - Key value MUST be a <String>
 */

// sorts alphabetically (ascending order) by key
const sortAlphaAscByKey = (key, list = []) => {
	return list.sort((a, b) => {
		return a?.[key]?.localeCompare(b?.[key]);
	});
};

// sorts alphabetically (descending order) by key
const sortAlphaDescByKey = (key, list = []) => {
	return list.sort((a, b) => {
		return b?.[key]?.localeCompare(a?.[key]);
	});
};

// returns 'true' values first
const sortBoolAscByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.[key] === b?.[key] ? 0 : a?.[key] ? -1 : 1;
	});
};

// returns 'false' values first
const sortBoolDescByKey = (key, list = []) => {
	if (isEmptyArray(list)) return [];
	return [...list].sort((a, b) => {
		return a?.[key] === b?.[key] ? 0 : a?.[key] ? 1 : -1;
	});
};

// OBJECT HANDLING UTILS //
// deletes a single key from an object
const deleteKey = (key, obj) => {
	const { [key]: _, ...rest } = obj;
	return rest;
};
// removes multiple keys from an obj
const deleteKeysMany = (obj, keys = []) => {
	return keys.reduce((newObj, key) => {
		// destructure, drop 'key' & keep the rest
		const { [key]: _, ...rest } = newObj;
		newObj = rest;
		return newObj;
	}, obj);
};

// LOGGING UTILS //

const migrationLogger = (facilityName, userCount = 0) => {
	console.group(`Migration: STARTING...`);
	console.log(`Migrating ${facilityName}...`);
	console.log(`Migrating ${userCount} users...`);
	console.groupEnd();
};

/**
 * Logs environment, and maintenance mode status upon application's mounting.
 * @param {String} env - Custom 'REACT_APP_CURRENT_ENV_NAME' value as manual env/override.
 * @param {String} nodeEnv - Current 'NODE_ENV' value.
 * @param {Boolean} maintenanceMode - Boolean defining whether 'MAINTENANCE_MODE' is enabled/disabled.
 * @returns {Null} - Returns null and merely logs out the current environment and status of maintenance mode.
 */
const envLogger = (env, nodeEnv, maintenanceMode) => {
	switch (env) {
		case "development":
		case "test": {
			console.group(
				`%cENVIRONMENT: ${env}/test`,
				`color: ${green[500]};font-size: 1rem;`
			);
			console.log(`%cENV(custom): ${env}`, `color: ${green[500]}`);
			console.log(`ENV(node): ${nodeEnv}`);
			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);
			console.groupEnd();
			return;
		}
		case "prod":
		case "production": {
			console.group(
				`%cENVIRONMENT: ${env}`,
				`color: ${red[600]};font-size: 1rem;`
			);
			console.log(`%cENV(custom): ${env}`, `color: ${red[600]}`);
			console.log(`ENV(node): ${nodeEnv}`);
			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);
			console.groupEnd();
			return;
		}
		case "custom": {
			console.group(`%cENVIRONMENT`, `color: ${purple[700]};font-size: 1rem;`);
			console.log(`ENV(custom): ${env}`);
			console.log(`ENV(node): ${nodeEnv}`);
			console.log(
				`%cMaintenance Mode: ${maintenanceMode ? "Enabled" : "Disabled"}`,
				`color: ${maintenanceMode ? red[500] : green[500]};`
			);
			console.groupEnd();
			return;
		}
		default:
			return "unknown";
	}
};

// performance & misc utils
export { range, debounce, delay };

// id & guid utils
export {
	getRandomNum,
	numToLetter,
	numInRange,
	generateID,
	generateUID,
	generateTempPassword,
	copyTextToClipboard,
	// mimetype data converters
	convertArrayData,
};

// array-sorting utils
export {
	groupBy,
	removeDups,
	sortByAlphaAsc,
	sortByAlphaDesc,
	sortAlphaAscByKey,
	sortAlphaDescByKey,
	sortBoolAscByKey,
	sortBoolDescByKey,
};

// string processing utils
export { enforceStrMaxLength, addEllipsis, replaceNullWithMsg };

// object utils
export { deleteKey, deleteKeysMany };

// logging utils
export { migrationLogger, envLogger };
