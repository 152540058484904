import React, { useState, useEffect } from "react";
import styles from "../../css/admin/ChangeUserType.module.scss";
import { PropTypes } from "prop-types";
import { getAndProcessUserTypes } from "../../helpers/utils_security";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	matchUserFromNames,
	USER_TYPES as allTypes,
} from "../../helpers/utils_user";
import { updateUserType } from "../../helpers/utils_createAdmin";
// components
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";
import UserTypesController from "./UserTypesController";

// REQUIREMENTS:
// - Enable changing any user's user type to:
//    - Admin
//    - Facilit Admin
//    - Super User
//    - Staff, Contractor, Manager (ie non-admin types)

// UI:
// - Select target user
// - Select target user type
// - Save Changes

const customCSS = {
	save: {
		padding: "1rem 1.6rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
	},
	cancel: {
		padding: "1rem 1.6rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: "1rem",
	},
	types: {
		width: "35rem",
	},
};

// extracts user's email/username to use to find their userID from state map
const getUsersLogin = (user) => {
	const names = user.split(" ~ ")[1];
	return names;
};

const formatUsers = (users) => {
	if (isEmptyArray(users)) return [];
	return users.map((user) => {
		const { firstName, lastName, email } = user;
		const userStr = `${lastName}, ${firstName} ~ ${email}`;
		return userStr;
	});
};

const Summary = ({ selectedUser, selectedType }) => {
	if (isEmptyVal(selectedUser) || isEmptyVal(selectedType)) {
		return null;
	}
	return (
		<div className={styles.Summary}>
			<span className={styles.Summary_desc}>
				Update <b>{selectedUser}</b> user(s) as <b>{selectedType}</b>
			</span>
		</div>
	);
};

const ChangeUserType = ({
	currentFacility,
	currentUser,
	globalState,
	dispatchToState,
	dispatchAlert,
}) => {
	const [selectedUser, setSelectedUser] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [userTypes, setUserTypes] = useState([...allTypes]);

	// selects target user to update
	const handleUserSelection = (name, user) => {
		setSelectedUser(user);
	};

	// selects user type
	const handleUserType = (name, type) => {
		setSelectedType(type);
	};

	// saves user type changes
	const saveUserTypeChange = async () => {
		const { token } = currentUser;
		// extracts user from 'namesMap' in state
		const { userMaps } = currentFacility;
		const names = getUsersLogin(selectedUser);
		const user = matchUserFromNames(names, userMaps?.namesMap);
		// fire off request
		const { UserLoginID: userID } = user;
		const wasUpdated = await updateUserType(token, userID, selectedType);

		if (wasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `User type was saved!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong!`,
				subheading: `Try again later, please.`,
			});
		}
	};
	// cancels user type changes
	const cancelUserType = () => {
		setSelectedType("");
		setSelectedUser("");
		console.log(`Cancelling changes...`);
	};

	return (
		<div className={styles.ChangeUserType}>
			<div className={styles.ChangeUserType_header}>
				<h4 className={styles.ChangeUserType_header_title}>
					Other User Types <b>(single-select)</b>
				</h4>
				<p className={styles.ChangeUserType_header_subtitle}>
					Change user to other type: 'Super User', 'Manager', 'Contractor' etc.
				</p>
			</div>
			<div className={styles.ChangeUserType_selector}>
				<CustomDropdown
					name="selectedUser"
					id="selectedUser"
					label="Select User to Update"
					options={formatUsers(currentFacility.users)}
					selection={selectedUser}
					setSelection={handleUserSelection}
					customStyles={customCSS.types}
				/>
			</div>
			<div className={styles.ChangeUserType_selector}>
				<CustomDropdown
					key={userTypes?.length}
					label="Select User Type"
					selection={selectedType}
					setSelection={handleUserType}
					options={userTypes}
					disabledOptions={[]}
					customStyles={customCSS.types}
				/>
			</div>
			<div className={styles.ChangeUserType_summary}>
				<Summary selectedUser={selectedUser} selectedType={selectedType} />
			</div>
			<div className={styles.ChangeUserType_actions}>
				<ButtonSM handleClick={cancelUserType} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={isEmptyVal(selectedUser) || isEmptyVal(selectedType)}
					handleClick={saveUserTypeChange}
					customStyles={customCSS.save}
				>
					Save User {selectedType ? `as ${selectedType}` : ""}
				</ButtonSM>
			</div>

			{/* OTHER USER TYPES (MULTI-SELECT) */}
			{/* <div className={styles.ChangeUserType_otherTypes}>
				<div className={styles.ChangeUserType_header}>
					<h4 className={styles.ChangeUserType_header_title}>
						Other User Types <b>(multi-select)</b>
					</h4>
					<p className={styles.ChangeUserType_header_subtitle}>
						Select one or more user types to apply to selected user.
					</p>
				</div>
				<UserTypesController
					users={currentFacility.users}
					currentFacility={currentFacility}
					currentUser={currentUser}
					dispatchToState={dispatchToState}
					dispatchAlert={dispatchAlert}
				/>
			</div> */}
		</div>
	);
};

export default ChangeUserType;

ChangeUserType.defaultProps = {};

ChangeUserType.propTypes = {};
