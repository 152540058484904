import React, { useEffect, useContext, useState } from "react";
import styles from "../css/pages/LoginPage.module.scss";
import sprite from "../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { AuthContext } from "../state/AuthContext";
import { useForm } from "../utils/useForm";
// utils & helpers
import {
	getUserAccessByEmail,
	getUserAuthStatus,
	login,
} from "../helpers/utils_auth";
import { SERVICES } from "../helpers/utils_apps";
import { addMinutes } from "date-fns";
import { themeColors } from "../helpers/utils_styles";
import { openUrl } from "../helpers/utils_params";
// components
import LoginForm from "../components/forms/LoginForm";
import Dialog from "../components/shared/Dialog";
import ButtonSM from "../components/shared/ButtonSM";

const custom = {
	backgroundColor: themeColors.main.red,
	color: "#ffffff",
	borderRadius: ".5rem",
};

const showLogoutMsg = (history) => {
	const wasLoggedOut = history?.location?.state?.wasLoggedOut;
	return wasLoggedOut;
};

// logged out message
const Message = ({ children }) => {
	return (
		<aside className={styles.Message}>
			<svg className={styles.Message_icon}>
				<use xlinkHref={`${sprite}#icon-check_circle`}></use>
			</svg>
			<div className={styles.Message_msg}>{children}</div>
		</aside>
	);
};

const LoginPage = ({ history }) => {
	const { authData, setAuthData } = useContext(AuthContext);
	const { formState, handleChange } = useForm({
		username: "",
		password: "",
	});
	const { values } = formState;
	const [isLoading, setIsLoading] = useState(false);
	const [errorDialog, setErrorDialog] = useState({
		show: false,
		title: null,
		msg: null,
		subMsg: null,
	});

	// triggers login error msg, based off 'login()' response
	const errorHandler = (authState = {}) => {
		const { isValidUser = false, token } = authState;

		switch (true) {
			// INVALID USER & UNKNOWN ERROR (ie. 'server' error, or backend-related)
			case token instanceof Error: {
				console.log("1st case triggered");
				return setErrorDialog({
					show: true,
					title: `Ooops! Server Failure.`,
					msg: `Server Didn't Respond.`,
					subMsg: `Sorry. The server didn't respond. \n\nTry refreshing the page please.`,
				});
			}
			// VALID USER (ie. 'invalid credentials', 'locked-out' etc)
			case isValidUser: {
				console.log("2nd case triggered");
				return setErrorDialog({
					show: true,
					title: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
					msg: authState?.reasonForFailure?.name ?? "Unknown Reason!!!",
					subMsg: authState?.reasonForFailure?.desc,
				});
			}
			// INVALID USER (ie. account doesn't exist)
			case !isValidUser: {
				return setErrorDialog({
					show: true,
					title: `INVALID USER!`,
					msg: `User Does Not Exist`,
					subMsg: `Sorry. That user was not found in our system.`,
				});
			}

			default:
				return setErrorDialog({
					show: true,
					title: `UNKNOWN ERROR`,
					msg: `An uknown error occurred`,
					subMsg: `Sorry. An error occured. Try logging again.`,
				});
		}
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		const { AdminPortal } = SERVICES;
		const { username, password } = values;
		const token = await login(username, password, AdminPortal.alias);

		if (!token || token instanceof Error) {
			setIsLoading(false);

			// ##TODOS:
			// - Check if 'isValidUser'
			// - Wire up 'SYSTEM_USER' account & auth onMount

			return errorHandler({ ...authData, token });
		} else {
			const newAuth = {
				username: username,
				password: password,
				token: token,
				expiry: addMinutes(Date.now(), 180),
				isAuthenticated: true,
			};
			setIsLoading(false);
			setAuthData(newAuth);
			return history.replace({
				pathname: "/internal",
				state: {
					from: history.location.pathname,
					wasLoggedOut: false,
				},
			});
		}
	};

	const resetLogin = () => {
		setErrorDialog({
			show: false,
			msg: null,
			title: null,
			subMsg: null,
		});
	};

	// ❌ THIS IS JUST A TEST CASE... ❌
	// ❌ ...FOR THE SSO REDIRECT... ❌
	// ❌ ...IN THE 'ADMIN PORTAL'... ❌
	// ❌ ...REMOVE THIS WHEN DONE TESTING!!! ❌
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		// window.location.replace("https://portaltest.aladvantage.com");

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.LoginPage}>
				<div className={styles.LoginPage_form}>
					<LoginForm
						showSignup={false}
						isLoading={isLoading}
						vals={values}
						handleChange={handleChange}
						handleLogin={handleLogin}
					/>
				</div>
			</div>

			{showLogoutMsg(history) && <Message>Your session has ended!</Message>}

			{errorDialog.show && (
				<Dialog
					icon="ERROR"
					title={errorDialog.title}
					heading={errorDialog.msg}
					subheading={errorDialog.subMsg}
					closeModal={resetLogin}
				>
					<ButtonSM customStyles={custom} handleClick={resetLogin}>
						<span>Try Again?</span>
					</ButtonSM>
				</Dialog>
			)}
		</>
	);
};

export default LoginPage;

LoginPage.defaultProps = {};

LoginPage.propTypes = {};
