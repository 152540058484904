import { isEmptyVal, isEmptyObj, isEmptyArray } from "./utils_types";
import { auth, security } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { extractParams, extractRawParams } from "./utils_params";
import { getFromStorage } from "./utils_caching";
import {
	getFailedLogins,
	getPasswordResetTypes,
	LOGIN_FAILURES as reasonsForFailure,
} from "./utils_lockouts";
import { addMinutes, differenceInMinutes } from "date-fns";

//////////////////////////////////////////////////////////////////////////
/////////////////////////// AUTH REQUEST UTILS ///////////////////////////
//////////////////////////////////////////////////////////////////////////

/**
 * Various auth-related web-service utils for logging in & out of ALA Services.
 */

/**
 * @description - Login utility for a single user.
 * @param {String} username - A user's username, typically an email address.
 * @param {String} password - A user's password
 * @param {String} appID - An application ID name (ie 'AdvantageTracker')
 * @param {Function} callback - An optional callback function to be invoked upon success.
 *
 * - Updated as of 2/2/2021 at 11:29 AM
 * 		- Removed password encoding
 * 		- Replaced 'new URLSearchParams()' usage w/ hard-coded fields
 */
const login = async (username, password, appName, callback = null) => {
	let url = currentEnv.base + auth.login;
	url += "?" + new URLSearchParams({ loginId: username });
	url += "&" + new URLSearchParams({ loginApp: appName });
	url += "&loginPwd=" + encodeURIComponent(password); // DO NOT USE 'new URLSearchParams' FOR PASSWORD!!!

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		if (callback) return callback();

		return response.Data;
	} catch (err) {
		return err;
	}
};
/**
 * @description - Logou utility for a single user.
 * @param {String} token - A user's auth token.
 * @returns {Boolean} - Returns 'true' if successful.
 */
const logout = async (token) => {
	let url = currentEnv.base + auth.logout;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err;
	}
};
/**
 * @description - Auth utility that checks if a user's session is valid for a given application.
 * @param {String} token - A base54 auth token.
 * @param {String} appID - A string-form application ID name.
 * @param {Function} callback - An optional callback to be invoked upon success.
 * @returns {Boolean} - Returns 'true' if user's auth session is valid.
 */
const checkLoginStatus = async (token, appID, callback = null) => {
	let url = currentEnv.base + auth.loginStatus;
	url += "?loginApp=" + appID;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		if (callback) return callback();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Auth util that refreshes a stale or close to expiring token, with a new, fresh token.
 * @param {String} token - An auth security token, that's close to expiry.
 * @returns {String} - Returns a fresh, new string token.
 */
const refreshAuthToken = async (token) => {
	let url = currentEnv.base + auth.refreshToken;
	// if no 'token', request is invalid
	if (isEmptyVal(token)) return false;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Auth utility that validates whether a user's session token is valid or not.
 * @param {String} token - An auth token
 * @returns {Boolean} - Returns 'true' if token is still valid.
 */
const validateAuth = async (token) => {
	let url = currentEnv.base + auth.validateToken;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Auth/session util that fetches both application, user, facility and session metadata based off the security token.
 * @param {String} token - Base64 encoded auth token. If coming from 'extractParams()' util, then MUST re-encode the token via "btoa()"
 * @returns {Object} - Returns an object of Application, User, Facility, and Session data.
 * - "response.ApplicationId": unique string-form numeric id, representing the associated ALA app.
 * - "response.ApplicationName": the app name, as a string.
 * - "response.Environment": the current environment associated w/ the token.
 * - "response.FacilityId": the primary facilityID associated w/ the user & token.
 * - "response.FacilityName": the primary facility name.
 * - "response.FacilityTimeZoneId": timezone of the facility.
 * - "response.SecurityToken": the actual refreshed security token.
 * - "response.SessionDate": date & time of the session start time.
 * - "response.SessionTimeoutInMinutes": remaining time in user's session, in minutes.
 * - "response.UserEmail": user's email.
 * - "response.UserFirstName": user's first name.
 * - "response.UserLastName": user's last name.
 * - "response.UserId": user's unique id.
 * - "response.UserPasswordEncrypt": encrypted version of the user's password.
 */
const getSessionDetails = async (token) => {
	let url = currentEnv.base + auth.sessionDetails;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Fetches application access details for a user from their email address.
 * @param {String} token - Base64 encoded auth token
 * @param {String} userEmail - Current user's email address.
 * - "response.AccessibleApps": an array of application detail objects:
 * 		- "AccessibleApps.ApplicationId": string-form numeric app id.
 * 		- "AccessibleApps.ApplicationName": string application name.
 * 		- "AccessibleApps.IsAccessible": boolean for user access.
 * - "response.FacilityId": facility id.
 * - "response.FacilityName": string facility name.
 * - "response.FacilityTimeZoneId": time zone description.
 * - "response.IsValidUser": boolean for whether username exists in ALA Services.
 * - "response.PossibleUserMatches": list of username matches, implying typo or incorrect spelling.
 * - "response.UserEmail": user's email address.
 * - "response.UserId": uid.
 * - "response.UserFirstName": user's first name.
 * - "response.UserLastname": user's last name.
 * - "response.UserPasswordEncrypt": encrypted user password.
 */
const getUserAccessByEmail = async (token, userEmail) => {
	let url = currentEnv.base + auth.userAccessByEmail;
	url += "?" + new URLSearchParams({ userEmail });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Checks the current session expiry
 * @param {Object} authData - An instance of the 'authData' global state object.
 * @returns {Object} - Returns the 'authData' object; either w/ fresh auth data or the current state's auth.
 */
const getFreshAuth = async (authData = {}) => {
	const { expiry } = authData;

	if (shouldRefreshAuth(expiry)) {
		const freshToken = await refreshAuthToken(authData.token);
		return {
			...authData,
			expiry: addMinutes(Date.now(), 60),
			token: freshToken,
		};
	} else {
		return { ...authData };
	}
};

/**
 * @description - Takes the existing 'authData' object, extracts the user credentials and re-authenticates.
 * @param {Object} authData - Auth data such as username & password, used to re-authenticate a user session.
 * @param {Function} callback - An optional callback function to be invoked upon successful authentication.
 * @returns {String|Function} - Returns the token or callback w/ the token, IF a callback is provided.
 */
const reLogin = async (authData, callback = null) => {
	const appID = `AdvantageTracker`;
	const { username, password } = authData;
	const newToken = await login(username, password, appID, null);

	if (!isEmptyVal(newToken)) {
		return !callback ? newToken : callback(newToken);
	} else {
		return newToken;
	}
};

/**
 * Checks if a username/email already exists or is taken in the system.
 * @param {String} token - Security token
 * @param {String} username - A username to validate/check for.
 * @returns {Boolean} - Returns true|false
 */
const checkIfUsernameExists = async (token, username) => {
	if (isEmptyVal(username)) return;
	let url = currentEnv.base + security.userLogin.validate;
	url += "?" + new URLSearchParams({ name: username });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

//////////////////////////////////////////////////////////////////////////
////////////////////////// DERIVED AUTH HELPERS //////////////////////////
//////////////////////////////////////////////////////////////////////////

/**
 * Extracts & formats a user's session data into client-side camelCase.
 * @param {Object} session - Session details object.
 */
const processSession = (session) => {
	const {
		// User Data
		SecurityToken,
		UserEmail,
		UserFirstName,
		UserLastName,
		UserId,
		UserPasswordEncrypt,
		SessionTimeOutInMinutes,
		// Facility Data
		FacilityId,
		FacilityName,
	} = session;

	return {
		user: {
			token: SecurityToken,
			username: UserEmail,
			firstName: UserFirstName,
			lastName: UserLastName,
			userID: UserId,
			encryptedPassword: UserPasswordEncrypt,
			expiry: calculateExpiry(SessionTimeOutInMinutes),
		},
		facility: {
			facilityID: FacilityId,
			communityName: FacilityName,
		},
	};
};

/**
 * Calculates a user's session expiration based off 'SessionTimeOutInMinutes'.
 * @param {Number} minsToExpiry - Number of minutes left in a user's session.
 * @returns {Date} - Returns the session expiry as a date.
 */
const calculateExpiry = (minsToExpiry) => {
	return addMinutes(Date.now(), minsToExpiry);
};

/**
 * Inspects a user's session data, to confirm a valid user session.
 * @param {Object} sessionDetails - The payload from 'GetSecurityTokenDetail' API.
 * @returns {Boolean} - Returns true|false
 */
const isValidSession = (sessionDetails = {}) => {
	if (isEmptyObj(sessionDetails)) return false;
	const {
		ApplicationName,
		ApplicationId,
		SecurityToken,
		SessionDate,
		SessionTimeOutInMinutes,
		UserEmail,
		UserPasswordEncrypt,
	} = sessionDetails;

	const isInvalid =
		isEmptyVal(ApplicationId) ||
		isEmptyVal(ApplicationName) ||
		isEmptyVal(SecurityToken) ||
		isEmptyVal(SessionDate) ||
		isEmptyVal(SessionTimeOutInMinutes) ||
		isEmptyVal(UserEmail) ||
		isEmptyVal(UserPasswordEncrypt);

	return !isInvalid && SessionTimeOutInMinutes > 1;
};

/**
 * @description - Checks for a user's 'authData' in localStorage.
 * @param {String} authKey - A unique key for a user's auth data in localStorage.
 * @returns {Object} - Returns any existing 'authData' or returns an empty {}
 */
const getExistingAuth = (authKey) => {
	const auth = getFromStorage(authKey);
	if (!isEmptyVal(auth?.token)) {
		return { ...auth };
	} else {
		return {};
	}
};

/**
 * @description - Extracts params from referrer URL, fetches & validates user sesssion & returns session details, if valid.
 * @param {String} url - The current url (ie window.location/history.location) w/ query params etc.
 */
const getDerivedAuth = async (
	url,
	sessionParams = [`token`, `facilityID`, `residentID`]
) => {
	// Requires decoding the token (previously was btoa(atob(token)), but that's been removed)...
	// ...since the URL will encode the values with non - base64 characters
	const params = extractRawParams(url, sessionParams);
	const token = atob(params.token);
	// console.log(`Raw Token`, params.token);
	// console.log("token", token);
	const session = await getSessionDetails(token);

	if (!isEmptyObj(session)) {
		return isValidSession(session) ? processSession(session) : null;
	} else {
		return null;
	}
};
/**
 * @description - Extracts query params from url, checks for 'securityToken', then fetches the auth & session data.
 * @param {URL.href} windowLocation - Accepts the 'window.location' of the current tab.
 * @returns {Object} - Returns an object w/ the user, facility, app & session data; includes 'residentID'.
 */
const getDerivedState = async (windowLocation) => {
	const { href: url } = windowLocation;
	const { token, residentID, facilityID } = extractParams(url, [
		`token`,
		`residentID`,
		`facilityID`,
	]);
	if (!isEmptyVal(token)) {
		const sessionData = await getDerivedAuth(token);
		return {
			token: token,
			residentID: Number(residentID),
			facilityID: facilityID,
			sessionData: sessionData,
		};
	} else {
		return {
			token: null,
			residentID: null,
			facilityID: null,
			sessionData: {},
		};
	}
};

/**
 * @description - Checks if a user's session 'expiry' is within the allowed range for user sessions.
 * @param {Date} expiry - A date instance (typicall from the 'authData' object) to compare it's age.
 * @param {Number} maxRange - A maximum value for allowed user sessions. Defaults to 30 (mins).
 * @returns {Boolean} - Returns true|false; whether session is active/valid
 */
const isActiveSession = (expiry, threshold = 30) => {
	return shouldRefreshAuth(expiry, threshold);
};
const hasSession = (appState, authData = {}) => {
	if (!appState.hasLoaded) {
		// check is 'authData' exists
		return isActiveSession(authData?.expiry, 30);
	} else {
		return false;
	}
};
/**
 * @description - Checks how long until expiry, if less than the allowed threshold, then returns 'true', otherwise 'false'.
 * @param {Date} expiry - The expiry time as a date instance, in the local timezone.
 * @param {Number} threshold - A numeric threshold. The time to expiry should be less than the threshold to return 'true'.
 * @returns {Boolean} - Returns true|false
 */
const shouldRefreshAuth = (expiry, threshold = 30) => {
	return Math.abs(differenceInMinutes(expiry, Date.now())) <= threshold;
};

// HANDLING USER ACCESS/AUTHORIZATION //

/**
 * Fetches 'UserAccess' records and 'FailedLogins' for a given user.
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email address or username
 * @param {Number} rangeForLogins - Number of minutes to check for failed logins (eg within 30 mins)
 * Processed Payload Return:
 * @returns {Object} - Returns an object with:
 * @property {Boolean} isValidUser - true|false
 * @property {Boolean} hasAppAccess - true|false
 * @property {Array} possibleMatches - An array of string usernames/emails that resemble the credentials entered.
 * @property {Number} failedLogins - The number of failed logins for the user.
 * @property {String} reasonForFailure - A string description for the failed login (ie 'Account Locked', 'Invalid Credentials', 'User Does Not Exists')
 */
const getUserAuthStatus = async (token, userEmail, rangeForLogins = null) => {
	const { access, failedLogins } = await getAuthAccessAndLogins(
		token,
		userEmail,
		rangeForLogins
	);
	const accessInfo = processAccessInfo("AdminPortal", access);
	const logins = processFailedLogins(failedLogins);

	return {
		...accessInfo,
		...logins,
	};
};

/**
 * Fetches 'UserAccess' records and 'FailedLogins' for a given user.
 * @param {String} token - Security token
 * @param {String} userEmail - A user's email address or username
 * @param {Number} rangeForLogins - Number of minutes to check for failed logins (eg within 30 mins)
 * @returns {Object} - Returns an object with 'access' and 'failedLogins'
 */
const getAuthAccessAndLogins = async (
	token,
	userEmail,
	rangeForLogins = 4320
) => {
	const [userAccess, failedLogins] = await Promise.all([
		getUserAccessByEmail(token, userEmail),
		getFailedLogins(token, userEmail, rangeForLogins),
	]);

	return {
		access: userAccess,
		failedLogins: failedLogins[0],
	};
};

// checks 'userAccess' records for app authorization
const checkAppAccess = (appName, accessRecords = []) => {
	if (isEmptyArray(accessRecords)) return false;
	if (appName === "AdminPortal") return true; // remove later

	return accessRecords.reduce((hasAccess, record) => {
		const { ApplicationName: name, IsAccessible } = record;
		if (name === appName && IsAccessible) {
			hasAccess = IsAccessible;
			return hasAccess;
		} else {
			return hasAccess;
		}
	}, false);
};

// processes & extracts useful info from 'userAccess'
const processAccessInfo = (appName = "AdminPortal", info = {}) => {
	const {
		IsValidUser,
		AccessibleApps,
		PossibleUserMatches,
		UserId,
		UserEmail,
	} = info;

	return {
		userEmail: UserEmail,
		userID: UserId,
		isValidUser: IsValidUser,
		hasAppAccess: checkAppAccess(appName, AccessibleApps),
		possibleMatches: PossibleUserMatches,
	};
};

// processes 'failedLogins' records & determines root cause of  failure
const processFailedLogins = (record = {}) => {
	const { FailedAttempts, FailedCauses } = record;

	// get 1st entry in 'FailedCauses'
	const failedEntry = FailedCauses?.[0];

	const failureReason =
		reasonsForFailure[failedEntry?.FailedStatus ?? "UnknownError"];

	console.log(`Reason for failure:`, failureReason?.name ?? `Unknown Failure`);

	if (isEmptyArray(FailedCauses)) {
		return {
			failedLogins: !FailedAttempts ? null : FailedAttempts,
			reasonForFailure: "Unknown Error",
		};
	} else {
		return {
			failedLogins: FailedAttempts,
			reasonForFailure: failureReason,
		};
	}
};

/**
 * Checks IF a user is authorized for a given application
 * @param {String} token - Security token (base64 encoded)
 * @param {String} userEmail - A user email string.
 */
const getAuthAccess = async (token, userEmail) => {
	const accessData = await getUserAccessByEmail(token, userEmail);

	if (!isEmptyObj(accessData)) {
		const msg = getAuthMessage(`AdvantageTracker`, accessData);
		return msg;
	} else {
		return `Invalid user.\n\n

		"${userEmail}" was not found.`;
	}
};

/**
 * @description - Checks if a user is authorized to access an app.
 * @param {String} targetApp - An app name (ie 'AdvantageTracker', 'SeniorCareVB' etc.)
 * @param {Array} allApps - An array of application meta data that defines whether user has access to an app.
 * @returns {Boolean} - Returns 'true' if user has access to the 'targetApp'
 */
const isAuthorizedForApp = (targetApp, allApps = []) => {
	return allApps.reduce((hasAccess, appData) => {
		const { ApplicationName, IsAccessible } = appData;
		if (ApplicationName === targetApp && IsAccessible) {
			hasAccess = true;
			return hasAccess;
		}
		return hasAccess;
	}, false);
};

/**
 * @description - Returns a custom message indicating whether a user has access to an app.
 * @param {String} targetApp - String-form application name (ie 'AdvantageTracker', 'SeniorCareVB', 'CareCalculator' etc).
 * @param {Object} accessData - An object w/ user auth data detailing the user's application authorization(s).
 * @returns {String} - Returns a string description: "<username> is authorized/unauthorized"
 */
const getAuthMessage = (targetApp = `AdvantageTracker`, accessData = {}) => {
	const { IsValidUser, UserEmail, AccessibleApps } = accessData;
	if (!IsValidUser) return `Not a valid user.`;

	let accessMsg = `${UserEmail} is `;
	accessMsg += isAuthorizedForApp(targetApp, AccessibleApps)
		? `authorized`
		: `unauthorized`;

	return accessMsg;
};

export {
	login,
	logout,
	reLogin,
	getFreshAuth,
	refreshAuthToken,
	shouldRefreshAuth,
	validateAuth,
	checkLoginStatus,
	getSessionDetails,
	getUserAccessByEmail,
	checkIfUsernameExists,
};

export { isAuthorizedForApp, getAuthMessage, getAuthAccess };

export {
	calculateExpiry,
	getExistingAuth,
	isActiveSession,
	isValidSession,
	hasSession,
	getDerivedAuth,
	getDerivedState,
	getAuthAccessAndLogins,
	getUserAuthStatus,
};
