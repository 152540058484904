import { reports } from "./utils_endpoints";
import { currentEnv } from "./utils_env";

/**
 * Fetches the list of ALL active users from our billed customers
 * @param {String} token - Auth token
 * @param {Boolean} params.isSuspended - Boolean flag for suspended users
 * @param {Boolean} params.isLockout - Boolean flag for locked-out users
 * @param {Number} params.index - Index in db to start pulling at.
 * @param {Number} params.rows - Number of rows in db to pull.
 * @returns {Array} - Returns a list of ALL active users from our billed customers
 */
const getActiveUsersList = async (token, params = {}) => {
	let url = currentEnv.base + reports.getActiveUsersList;
	// add optional params
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetches a list of all active communities with various related data
 * @param {String} token - Auth token
 * @param {Object} params - Custom params to be provided, optional
 * @returns {Array} - Returns array of objects
 */
const getActiveCommunitiesList = async (token, params = {}) => {
	let url = currentEnv.base + reports.getActiveCommunitiesList;
	// add optional params
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

export { getActiveUsersList, getActiveCommunitiesList };
