import React from "react";
import styles from "../../css/vendor/VendorMapsTableForm.module.scss";
import { PropTypes } from "prop-types";
import { green, red } from "../../helpers/utils_styles";
// components
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import { isEmptyObj } from "../../helpers/utils_types";

const customCSS = {
	reset: {
		padding: ".8rem 2rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "auto",
	},
	clear: {
		padding: ".8rem 2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: ".8rem 2rem",
		backgroundColor: green[500],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
	input: {
		backgroundColor: "#ffffff",
	},
};

const VendorMapsTableForm = ({
	vals = {},
	formState = {},
	selectedRow = {},
	handleChange,
	resetForm,
}) => {
	console.log("vals", vals);
	return (
		<div className={styles.VendorMapsTableForm}>
			<div className={styles.VendorMapsTableForm_label}>Edit selected row:</div>
			<div className={styles.VendorMapsTableForm_form}>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="ALASourceName"
						id="ALASourceName"
						label="ALA Source Name:"
						val={vals?.["ALASourceName"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="VendorSourceName"
						id="VendorSourceName"
						label="Vendor Source Name:"
						val={vals?.["VendorSourceName"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="ALAFieldName"
						id="ALAFieldName"
						label="ALA Field Name:"
						val={vals?.["ALAFieldName"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="VendorFieldName"
						id="VendorFieldName"
						label="Vendor Field Name:"
						val={vals?.["VendorFieldName"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="ALAFieldValue"
						id="ALAFieldValue"
						label="ALA Field Value:"
						val={vals?.["ALAFieldValue"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
				<div className={styles.VendorMapsTableForm_form_field}>
					<TextInput
						name="VendorFieldValue"
						id="vendorFieldValue"
						label="Vendor Field Value:"
						val={vals?.["VendorFieldValue"]}
						handleChange={handleChange}
						customStyles={customCSS.input}
					/>
				</div>
			</div>
			<div className={styles.VendorMapsTableForm_actions}>
				<ButtonSM
					isDisabled={isEmptyObj(formState.touched)}
					customStyles={customCSS.clear}
				>
					Clear
				</ButtonSM>
				<ButtonSM
					isDisabled={isEmptyObj(formState.touched)}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default VendorMapsTableForm;

VendorMapsTableForm.defaultProps = {};

VendorMapsTableForm.propTypes = {};
