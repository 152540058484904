import React from "react";
import styles from "../../css/sso/FlushCache.module.scss";
import { PropTypes } from "prop-types";
import { cacheNames } from "../../helpers/utils_sso";
// components
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	selector: {
		width: "40rem",
		height: "4rem",
	},
	flush: {
		padding: "1rem 2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.7rem",
		fontWeight: "600",
	},
};

const FlushCache = ({ cacheToFlush, handleSelection, flushCache }) => {
	return (
		<div className={styles.FlushCache}>
			<div className={styles.FlushCache_options}>
				<CustomDropdown
					name="cacheToFlush"
					id="cacheToFlush"
					label="Select Cache to Flush"
					selection={cacheToFlush}
					setSelection={handleSelection}
					options={[...cacheNames]}
					customStyles={customCSS.selector}
				/>
			</div>

			<div className={styles.FlushCache_actions}>
				<ButtonSM
					isDisabled={isEmptyVal(cacheToFlush)}
					handleClick={flushCache}
					customStyles={customCSS.flush}
				>
					Execute Flush
				</ButtonSM>
			</div>
		</div>
	);
};

export default FlushCache;

FlushCache.defaultProps = {};

FlushCache.propTypes = {};
