import React from "react";
import styles from "../css/views/ExportView.module.scss";
import { PropTypes } from "prop-types";
import ExportFacilityData from "../components/export/ExportFacilityData";

const ExportView = ({
	globalState,
	currentFacility,
	currentUser,
	dispatchAlert,
	dispatchToState,
}) => {
	const { facilities } = currentUser;
	return (
		<div className={styles.ExportView}>
			<div className={styles.ExportView_header}>
				<h2 className={styles.ExportView_header_title}>Export Facility Data</h2>
			</div>
			<div className={styles.ExportView_main}>
				<ExportFacilityData
					globalState={globalState}
					currentFacility={currentFacility}
					currentUser={currentUser}
					dispatchAlert={dispatchAlert}
					dispatchToState={dispatchToState}
					facilities={facilities}
				/>
			</div>
		</div>
	);
};

export default ExportView;

ExportView.defaultProps = {};

ExportView.propTypes = {};
