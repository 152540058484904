import React from "react";
import styles from "../css/views/CommunityActivityView.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../helpers/utils_types";
import CommunityActivity from "../components/facility/CommunityActivity";

const CommunityActivityView = ({
	globalState,
	currentFacility,
	currentUser,
	dispatchAlert,
	dispatchToState,
}) => {
	return (
		<div className={styles.CommunityActivityView}>
			<header className={styles.CommunityActivityView_header}>
				<h2 className={styles.CommunityActivityView_header_title}>
					Community Activity
				</h2>
				<p className={styles.CommunityActivityView_header_desc}>
					Check activity logins for the currently selected facility within a
					target date range.
				</p>
				{isEmptyVal(currentFacility.facilityID) && (
					<p className={styles.CommunityActivityView_header_subdesc}>
						Please select a facility above.
					</p>
				)}
			</header>
			<main className={styles.CommunityActivity_main}>
				<CommunityActivity
					globalState={globalState}
					currentFacility={currentFacility}
					currentUser={currentUser}
					dispatchToState={dispatchToState}
					dispatchAlert={dispatchAlert}
				/>
			</main>
		</div>
	);
};

export default CommunityActivityView;

CommunityActivityView.defaultProps = {};

CommunityActivityView.propTypes = {
	globalState: PropTypes.object,
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	dispatchAlert: PropTypes.func,
	dispatchToState: PropTypes.func,
};
