import React from "react";
import styles from "../../css/sso/UIController.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
// components
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	save: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: "1.5rem 3rem",
		borderRadius: "5rem",
		fontSize: "1.7rem",
		fontWeight: "600",
	},
};

const UIController = ({
	name,
	id,
	label,
	isEnabled,
	saveChanges,
	handleCheckbox,
	btnLabel,
}) => {
	return (
		<div className={styles.UIController}>
			<CustomCheckbox
				name={name}
				id={id}
				label={label}
				val={isEnabled}
				handleCheckbox={handleCheckbox}
			/>
			<div className={styles.UIController_actions}>
				<ButtonSM customStyles={customCSS.save} handleClick={saveChanges}>
					{btnLabel}
				</ButtonSM>
			</div>
		</div>
	);
};

export default UIController;

UIController.defaultProps = {};

UIController.propTypes = {};
