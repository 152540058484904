import React from "react";
import styles from "../../css/facility/RenameFacility.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { purple, red } from "../../helpers/utils_styles";
// components
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	newName: {
		backgroundColor: "#ffffff",
		width: "35rem",
	},
	cancel: {
		padding: ".8rem 1.4rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	submit: {
		padding: ".8rem 1.4rem",
		backgroundColor: purple[700],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const RenameFacility = ({
	vals,
	handleChange,
	saveNameChange,
	cancelNameChange,
}) => {
	return (
		<div className={styles.RenameFacility}>
			<TextInput
				name="newCommunityName"
				id="newCommunityName"
				label="Enter New Community Name"
				placeholder="Enter name..."
				val={vals?.newCommunityName}
				handleChange={handleChange}
				customStyles={customCSS.newName}
			/>
			<div className={styles.RenameFacility_warning}>
				Please verify the new name entered is correct before clicking submit.
			</div>
			<div className={styles.RenameFacility_actions}>
				<ButtonSM
					isDisabled={false}
					handleClick={cancelNameChange}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={isEmptyVal(vals.newCommunityName)}
					handleClick={saveNameChange}
					customStyles={customCSS.submit}
				>
					Submit
				</ButtonSM>
			</div>
		</div>
	);
};

export default RenameFacility;

RenameFacility.defaultProps = {};

RenameFacility.propTypes = {};
