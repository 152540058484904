import React from "react";
import styles from "../css/views/ImportView.module.scss";
import { PropTypes } from "prop-types";
import ImportResidents from "../components/facility/ImportResidents";

const ImportView = ({
	currentUser = {},
	currentFacility = {},
	globalState = {},
	dispatchAlert,
	dispatchToState,
}) => {
	return (
		<div className={styles.ImportView}>
			<ImportResidents
				globalState={globalState}
				currentFacility={currentFacility}
				currentUser={currentUser}
				dispatchAlert={dispatchAlert}
				dispatchToState={dispatchToState}
			/>
		</div>
	);
};

export default ImportView;

ImportView.defaultProps = {};

ImportView.propTypes = {};
