import React from "react";
import styles from "../../css/loc/LOCTemplateSelector.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";

const LOCTemplateSelector = ({
	label,
	selectedTemplate,
	handleTemplate,
	templateNames = [],
	currentFacility = {},
	customStyles = {},
	custom = {},
}) => {
	return (
		<div className={styles.LOCTemplateSelector} style={custom}>
			<CustomDropdown
				name="selectedLOCTemplate"
				id="selectedLOCTemplate"
				placeholder="Choose existing template..."
				label={label}
				selection={selectedTemplate}
				setSelection={handleTemplate}
				options={templateNames}
				customStyles={customStyles}
			/>
		</div>
	);
};

export default LOCTemplateSelector;

LOCTemplateSelector.defaultProps = {};

LOCTemplateSelector.propTypes = {};
