import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/user/RemoveUsers.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { fetchAndProcessUsers } from "../../helpers/utils_facility.js";
// components
import UserDropdown from "./UserDropdown";

const RemoveUsers = ({
	currentUser = {},
	currentFacility = {},
	removeUsers,
	dispatchToState,
}) => {
	const [usersList, setUsersList] = useState([]);
	const [usersToRemove, setUsersToRemove] = useState([]);

	const handleUserSelections = (selections) => {
		setUsersToRemove(selections);
	};

	// only need: 'first', 'last', 'email' & 'userID'
	const getUsersByFacility = useCallback(async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		if (isEmptyVal(facilityID)) return [];
		const userData = await fetchAndProcessUsers(token, facilityID);

		setUsersList(userData?.userList);
		dispatchToState({
			type: "SET_FACILITY_USERS",
			data: {
				userData: {
					users: userData?.userList,
					userMaps: userData?.userMaps,
				},
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch users based off facility
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (currentFacility?.facilityID) {
			// fetch users here
			getUsersByFacility();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility?.facilityID, getUsersByFacility]);

	return (
		<div className={styles.RemoveUsers}>
			<UserDropdown
				key={`LoadedUsers: ${usersList?.length}`}
				removeUsers={removeUsers}
				usersList={usersList}
				handleUserSelections={handleUserSelections}
			/>
		</div>
	);
};

export default RemoveUsers;

RemoveUsers.defaultProps = {};

RemoveUsers.propTypes = {};
