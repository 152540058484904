import React, { useState, useEffect } from "react";
import styles from "../css/views/VendorMapsView.module.scss";
import { PropTypes } from "prop-types";
import { getVendorsList } from "../helpers/utils_vendors";
import { isEmptyArray } from "../helpers/utils_types";
// components
import VendorMapsController from "../components/vendor/VendorMapsController";

const VendorMapsView = ({
	currentUser = {},
	currentFacility = {},
	globalState = {},
	dispatchAlert,
	dispatchToState,
}) => {
	const [vendors, setVendors] = useState([]);

	const fetchInitialResource = async () => {
		if (!isEmptyArray(vendors)) return;
		const { token } = currentUser;
		const vendorsList = await getVendorsList(token);

		setVendors(vendorsList);
	};

	// fetches vendors list
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.VendorMapsView}>
			<header className={styles.VendorMapsView_header}>
				<h2 className={styles.VendorMapsView_header_title}>Vendor Maps</h2>
				<h2 className={styles.VendorMapsView_header_desc}>
					Create, edit, update, or remove a vendors data mapping values.
				</h2>
			</header>
			<main className={styles.VendorMapsView_header}>
				<VendorMapsController
					key={`VENDORS-${vendors?.length}`}
					vendorsList={vendors}
					currentUser={currentUser}
					currentFacility={currentFacility}
					dispatchAlert={dispatchAlert}
				/>
			</main>
		</div>
	);
};

export default VendorMapsView;

VendorMapsView.defaultProps = {};

VendorMapsView.propTypes = {};
