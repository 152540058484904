import React from "react";
import styles from "../../css/vendor/CreateNewVendorRowForm.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isEmptyObj } from "../../helpers/utils_types";
import { blue, green, red } from "../../helpers/utils_styles";
// components
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	reset: {
		padding: ".8rem 2rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "auto",
	},
	clear: {
		padding: ".8rem 2rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: ".8rem 2rem",
		backgroundColor: blue[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
	input: {},
};

const CreateNewVendorRowForm = ({
	currentFacility = {},
	currentUser = {},
	selectedVendor = {},
	createNewRowEntry,
}) => {
	const { formState, handleChange, handleReset } = useForm({
		// 'Source'
		ALASourceName: "",
		VendorSourceName: "",
		// 'Field-Name'
		ALAFieldName: "",
		VendorFieldName: "",
		// 'Field-Value'
		ALAFieldValue: "",
		VendorFieldValue: "",
	});
	const { values, touched } = formState;

	return (
		<div className={styles.CreateNewVendorRowForm}>
			<div className={styles.CreateNewVendorRowForm_wrapper}>
				<div className={styles.CreateNewVendorRowForm_wrapper_label}>
					Create new row:
				</div>
				<div className={styles.CreateNewVendorRowForm_wrapper_form}>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-ALASourceName`}
							name="ALASourceName"
							id="ALASourceName"
							label="ALA Source Name:"
							val={values?.["ALASourceName"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-VendorSourceName`}
							name="VendorSourceName"
							id="VendorSourceName"
							label="Vendor Source Name:"
							val={values?.["VendorSourceName"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-ALAFieldName`}
							name="ALAFieldName"
							id="ALAFieldName"
							label="ALA Field Name:"
							val={values?.["ALAFieldName"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-VendorFieldName`}
							name="VendorFieldName"
							id="VendorFieldName"
							label="Vendor Field Name:"
							val={values?.["VendorFieldName"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-ALAFieldValue`}
							name="ALAFieldValue"
							id="ALAFieldValue"
							label="ALA Field Value:"
							val={values?.["ALAFieldValue"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
					<div className={styles.CreateNewVendorRowForm_wrapper_form_field}>
						<TextInput
							key={`NEW-ROW-VendorFieldValue`}
							name="VendorFieldValue"
							id="vendorFieldValue"
							label="Vendor Field Value:"
							val={values?.["VendorFieldValue"]}
							handleChange={handleChange}
							customStyles={customCSS.input}
						/>
					</div>
				</div>
				<div className={styles.CreateNewVendorRowForm_wrapper_actions}>
					<ButtonSM
						isDisabled={isEmptyObj(formState.touched)}
						customStyles={customCSS.clear}
					>
						Clear
					</ButtonSM>
					<ButtonSM
						isDisabled={isEmptyObj(formState.touched)}
						customStyles={customCSS.save}
					>
						Create Entry
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default CreateNewVendorRowForm;

CreateNewVendorRowForm.defaultProps = {};

CreateNewVendorRowForm.propTypes = {};
