import React, { useState } from "react";
import styles from "../../css/export/ExportFacilityData.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	getFacilityNames,
	matchFacilityByName,
} from "../../helpers/utils_facility";
// components
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";
import CustomSelect from "../shared/CustomSelect";
import TextInput from "../shared/TextInput";
import MultiSelect from "../shared/MultiSelect";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	exportFacilityData,
	getEmailRecipients,
	updateEmailTransportModel,
} from "../../helpers/utils_export";

const customCSS = {
	selector: {
		width: "40rem",
	},
	emails: {
		width: "40rem",
		backgroundColor: "#ffffff",
	},
	exportBtn: {
		padding: "1.5rem 2.3rem",
		fontSize: "1.7rem",
		fontWeight: "700",
		color: "#ffffff",
		backgroundColor: red[600],
	},
};

const emailOptions = [
	// `seth@aladvantage.com`,
	`khord@eldermark.com`, // Kellie Hord (Dir. Customer Support)
	`rjamison@eldermark.com`, // Robin Jamison (ePay Dir.)
	`brian.miller@aladvantage.com`,
	`jose@aladvantage.com`,
	`steven@aladvantage.com`,
];

const disableBtn = (emails = [], vals = {}) => {
	const { targetFacilityName: facName, exportMethod: method } = vals;
	const isEmpty = isEmptyVal(facName) || isEmptyVal(method);
	const noEmails = isEmptyArray(emails);

	return isEmpty || noEmails;
};

const ExportMsg = ({ emailRecipients = [], vals = {} }) => {
	return (
		<div className={styles.ExportMsg}>
			<div className={styles.ExportMsg_text}>
				You're about to export data for {vals?.targetFacilityName} and send to
				the following email addresses:
			</div>
		</div>
	);
};

const ExportFacilityData = ({
	globalState,
	currentFacility,
	currentUser,
	facilities = [],
	dispatchAlert,
	dispatchToState,
}) => {
	const { formState, setFormState, handleReset } = useForm({
		targetFacilityName: "",
		exportMethod: "SendToEmailAddress",
	});
	const [emailRecipients, setEmailRecipients] = useState([]);

	const { values } = formState;

	const handleFacility = (name, option) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: option,
			},
		});
	};

	const handleEmails = (options) => {
		setEmailRecipients(options);
	};

	const exportAndEmailData = async () => {
		const { token } = currentUser;
		const { targetFacilityName } = values;

		const facility = matchFacilityByName(targetFacilityName, facilities);
		const emails = getEmailRecipients(emailRecipients);
		const transportModel = updateEmailTransportModel({
			...values,
			emailRecipients: emails,
		});

		console.group(`Export & Email`);
		console.log("emails", emails);
		console.log("model", transportModel);
		console.log("facility", facility);
		console.groupEnd();

		// const wasExported = false;
		const wasExported = await exportFacilityData(
			token,
			facility.facilityID,
			transportModel
		);

		if (wasExported) {
			return dispatchAlert("SUCCESS", {
				heading: `Export Was Successful!`,
				subheading: `Data has been exported & emailed!!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Export Failed!`,
				subheading: `This action failed. Please try again!`,
			});
		}
	};

	return (
		<div className={styles.ExportFacilityData}>
			<div className={styles.ExportFacilityData_selector}>
				<CustomDropdown
					name="targetFacilityName"
					id="targetFacilityName"
					label="Select Facility"
					selection={values?.targetFacilityName}
					setSelection={handleFacility}
					options={[...getFacilityNames(facilities)]}
					customStyles={customCSS.selector}
				/>
			</div>
			<div className={styles.ExportFacilityData_selector}>
				<label
					htmlFor="emailRecipients"
					className={styles.ExportFacilityData_selector_label}
				>
					Email Recipients (one or more)
				</label>
				<MultiSelect
					name="emailRecipients"
					id="emailRecipients"
					handleMultiSelect={handleEmails}
					placeholder={`${emailRecipients?.length ?? 0} of ${
						emailOptions?.length
					} selected`}
					options={[...emailOptions]}
					customStyles={customCSS.emails}
				/>
				{/* <TextInput
					name="emailRecipients"
					id="emailRecipients"
					label="Email Recipients (separate emails with semicolon;)"
					handleChange={handleChange}
					placeholder="Enter emails to send to..."
					customStyles={customCSS.emails}
				/> */}
			</div>
			<div className={styles.ExportFacilityData_actions}>
				<ButtonSM
					key={`EXPORT-${disableBtn(emailRecipients, values)}`}
					isDisabled={disableBtn(emailRecipients, values)}
					customStyles={customCSS.exportBtn}
					handleClick={exportAndEmailData}
				>
					Export & Email Data
				</ButtonSM>
			</div>
			{/*  */}
		</div>
	);
};

export default ExportFacilityData;

ExportFacilityData.defaultProps = {};

ExportFacilityData.propTypes = {};
