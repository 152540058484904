import { currentEnv } from "./utils_env";
import { locTemplates } from "./utils_endpoints";
import { groupBy } from "./utils_processing";

/**
 * Fetches a facility's LOC templates.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility string guid
 * @returns {Object[]} - Returns an array of facility template objects.
 */
const getFacilityLOCTemplates = async (token, facilityID) => {
	let url = currentEnv.base + locTemplates.get.facilityTemplates;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// fetches and formats templates from parentID
const fetchAndProcessLOCTemplates = async (token, parentID) => {
	const rawTemplates = await getFacilityLOCTemplates(token, parentID);
	const namesAndTemplates = processFacilityTemplates(rawTemplates);

	return namesAndTemplates;
};

// PROCESSING LOC/TEMPLATE UTILS //

/**
 * Formats a template header/structure data object for the client.
 * @param {Object} facilityTemplate - An object containing headers & structures
 * @returns {Object} - Returns client-formatted object w/ levels & template info.
 */
const processFacilityTemplate = (facilityTemplate = {}) => {
	const { Template: templateInfo, TemplateStructures: templateLevels } =
		facilityTemplate;
	const {
		FacilityID: facilityID,
		Name: templateName,
		Description: templateDesc,
		TemplateID: templateID,
		TemplateCategoryTypeID: categoryID,
		TemplateTypeID: typeID,
		IsActive: isActive,
		IsDefault: isDefault,
		IsValidated: isValid,
	} = templateInfo;

	const clientTemplate = {
		facilityID,
		templateName,
		templateDesc,
		templateID,
		categoryID,
		typeID,
		isActive,
		isDefault,
		isValid,
	};
	const byUnitType = groupBy(templateLevels, (x) => x.FloorUnit);

	// probably need to group/sort the 'templateLevels' by floor unit first

	return {
		...clientTemplate,
		template: {
			...byUnitType,
		},
	};
};

const processFacilityTemplates = (templateData = []) => {
	const facilityTemplates = templateData.reduce(
		(namesAndTemplates, template) => {
			const formatted = processFacilityTemplate(template);

			namesAndTemplates = {
				names: [...namesAndTemplates.names, formatted?.templateName],
				templates: [...namesAndTemplates.templates, formatted],
			};

			return namesAndTemplates;
		},
		{ names: [], templates: [] }
	);

	return facilityTemplates;
};

const matchTemplateIDFromName = (templateName, templates = []) => {
	const id = templates.filter((record) => record?.name === templateName);

	return id;
};

export { getFacilityLOCTemplates, fetchAndProcessLOCTemplates };

export {
	processFacilityTemplate,
	processFacilityTemplates,
	matchTemplateIDFromName,
};
