import React from "react";
import styles from "../css/pages/DemoPage.module.scss";
import { purple } from "../helpers/utils_styles";
// components
import LineChartWrapper from "../components/charts/LineChartWrapper";

const mockData = {
	// X-Axis
	labels: ["Jan", "Feb", "Mar", "Apr", "May"],

	datasets: [
		{
			label: "Logins By Month",
			data: [12, 35, 14, 23, 18], // Y-Axis data points (1 line)
			fill: false,
			backgroundColor: "rgb(255, 99, 132)",
			borderColor: "rgba(255, 99, 132, 0.9)",
		},
		{
			label: "Blah",
			data: [10, 20, 30, 40, 32], // Y-Axis data points (1 line)
			fill: false,
			backgroundColor: purple[200],
			borderColor: purple[500],
		},
	],
};

const DemoPage = () => {
	return (
		<div className={styles.DemoPage}>
			<header className={styles.DemoPage_header}>
				<h2 className={styles.DemoPage_header_title}>Demo Page</h2>
			</header>
			<main className={styles.DemoPage_main}>
				<LineChartWrapper data={mockData} />
			</main>
		</div>
	);
};

export default DemoPage;

DemoPage.defaultProps = {};

DemoPage.propTypes = {};
