import React, { useState } from "react";
import styles from "../css/views/UserAdminView.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import {
	copyTextToClipboard,
	generateTempPassword,
} from "../helpers/utils_processing";
import { isEmptyVal } from "../helpers/utils_types";
import { updateUserAdminModel } from "../helpers/utils_models";
import { createAdminAccount } from "../helpers/utils_facility";
import { purple, red } from "../helpers/utils_styles";
// components
import UserAdmin from "../components/admin/UserAdmin";
import Dialog from "../components/shared/Dialog";
import ButtonSM from "../components/shared/ButtonSM";
import FacilityAdmin from "../components/facility/FacilityAdmin";
import CreateAccountStatus from "../components/facility/CreateAccountStatus";
import MakeRegionalAdmin from "../components/admin/MakeRegionalAdmin";
import Divider from "../components/forms/Divider";

const customCSS = {
	loaded: {
		color: purple[600],
	},
	notLoaded: {
		color: red[600],
	},
	divider: {
		marginBottom: "6rem",
	},
	subtitle: {
		fontSize: "1.4rem",
		fontWeight: "600",
		marginBottom: "0rem",
	},
	// confirm actions
	cancelNewAdmin: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
	},
	saveNewAdmin: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
		color: "#ffffff",
	},
	cancelSaveAs: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
	},
	saveAs: {
		backgroundColor: red[600],
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const TargetFacility = ({ facilityName = "" }) => {
	return (
		<div className={styles.UserAdminView_main_facility}>
			<span className={styles.UserAdminView_main_facility_label}>
				Target Facility:{" "}
			</span>
			<span className={styles.UserAdminView_main_facility_name}>
				{isEmptyVal(facilityName)
					? "Please select a facility above ↑"
					: facilityName}
			</span>
		</div>
	);
};

const Subheading = ({ text, facilityName }) => {
	return (
		<span className={styles.Subheading}>
			{text} <b>{facilityName}</b> facility?
		</span>
	);
};

const UserAdminView = ({
	currentFacility,
	currentUser,
	globalState,
	dispatchToState,
	dispatchAlert,
}) => {
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// admin details
		adminEmail: "",
		adminFirstName: "",
		adminLastName: "",
		adminPassword: "",
	});
	const { values } = formState;
	const [usersToMakeAdmin, setUsersToMakeAdmin] = useState([]);
	// confirmation dialogs
	const [showNewAdminDialog, setShowNewAdminDialog] = useState(false);
	const [showSaveAsAdminDialog, setShowSaveAsAdminDialog] = useState(false);

	// handles 'make-user-admin' custom dropdown
	const selectUserToMakeAdmin = (selectedUsers) => {
		setUsersToMakeAdmin(selectedUsers);
	};

	const handleCustomFields = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const handlePasswordGeneration = (fieldName) => {
		const temp = generateTempPassword(8);
		setFormState({
			...formState,
			values: {
				...values,
				[fieldName]: temp,
			},
		});
	};

	const copySelection = (fieldName) => {
		const val = values?.[fieldName] ?? "";
		copyTextToClipboard(val);
	};

	// creates new admin account
	const createNewAdmin = async (e) => {
		console.log(`✓ Creating new admin account!`);
		setShowNewAdminDialog(true);
	};
	// cancels existing user as admin
	const cancelNewAdmin = async () => {
		console.log(`❌ Cancelling new user 'ADMIN'!`);
		setShowNewAdminDialog(false);
	};

	// opens confirm dialog for 'Save As Admin'
	const saveAsAdmin = async () => {
		if (isEmptyVal(currentFacility?.facilityID)) {
			return alert(`Please select a facility!`);
		}
		console.log(`✅ Making user an 'ADMIN'!`);
		setShowSaveAsAdminDialog(true);
	};
	// cancels existing user as admin
	const cancelSaveAsAdmin = async () => {
		console.log(`❌ Cancelling making user an 'ADMIN'!`);
		setShowSaveAsAdminDialog(false);
	};

	// admin confirmation dialog handlers

	// confirms new admin account: to fire off request
	const confirmNewAdmin = async (e) => {
		const { token } = currentUser;
		const adminModel = updateUserAdminModel(values);

		console.log(`✓ Creating new admin account!`);
		console.log(`NewUserAdminModel:`, adminModel);

		const wasSuccessful = false;
		// const wasSuccessful = await createAdminAccount(
		// 	token,
		// currentFacility.facilityID,
		// 	adminModel
		// );

		if (wasSuccessful) {
			handleReset(e);
			setShowSaveAsAdminDialog(false);
			return dispatchAlert("SUCCESS", {
				heading: `Admin(s) were created!`,
			});
		} else {
			setShowSaveAsAdminDialog(false);
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred. Try again later!`,
			});
		}
	};

	// confirms save user as admin: to fire off request
	const confirmSaveAsAdmin = async (e) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const adminModel = updateUserAdminModel(values);

		console.log(`✓ Saving user as 'ADMIN' account!`);
		console.log(`Admin:`, adminModel);

		// const wasSuccessful = false;
		const wasSuccessful = await createAdminAccount(
			token,
			facilityID,
			adminModel
		);

		if (wasSuccessful) {
			handleReset(e);
			setShowSaveAsAdminDialog(false);
			return dispatchAlert("SUCCESS", {
				heading: `Admin(s) were created!`,
			});
		} else {
			setShowSaveAsAdminDialog(false);
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred. Try again later!`,
			});
		}
	};

	return (
		<>
			<div className={styles.UserAdminView}>
				<header className={styles.UserAdminView_header}>
					<h2 className={styles.UserAdminView_header_title}>
						Create or Assign User Admin
					</h2>
					<p className={styles.UserAdminView_header_desc}>
						Assign or create a new user admin for a given facility.
					</p>
				</header>
				<div className={styles.UserAdminView_main}>
					<TargetFacility
						key={`TARGET:${currentFacility?.communityName}`}
						facilityName={currentFacility?.communityName}
					/>
					{/* CREATE/ASSIGN ADMIN, ASSIGN USER TYPE */}
					<UserAdmin
						key={`USER-ADMIN-${currentFacility?.users?.length}`}
						currentUser={currentUser}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						vals={values}
						facilityUsers={currentFacility?.users}
						currentFacility={currentFacility}
						usersToMakeAdmin={usersToMakeAdmin}
						handleChange={handleChange}
						copySelection={copySelection}
						handleSelection={handleCustomFields}
						handlePasswordGeneration={handlePasswordGeneration}
						// new admin handlers
						createNewAdmin={createNewAdmin}
						cancelNewAdmin={cancelNewAdmin}
						// save as admin handlers
						selectUserToMakeAdmin={selectUserToMakeAdmin}
						saveAsAdmin={saveAsAdmin}
						cancelSaveAsAdmin={cancelSaveAsAdmin}
					/>
					<Divider />
					{/* ASSIGN REGIONAL ACCESS */}
					<MakeRegionalAdmin
						globalState={globalState}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
					/>
				</div>
			</div>

			{/* CREATE NEW ADMIN DIALOG */}
			{showNewAdminDialog && (
				<Dialog
					icon="ADMIN"
					title="Create New Admin"
					subheading={
						<Subheading
							text="Is this NEW admin account for the "
							facilityName={currentFacility?.communityName}
						/>
					}
					closeModal={() => setShowNewAdminDialog(false)}
				>
					<div className={styles.NewAdminDialog}>
						<ButtonSM
							customStyles={customCSS.cancelNewAdmin}
							handleClick={cancelNewAdmin}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							isDisabled={false}
							customStyles={customCSS.saveNewAdmin}
							handleClick={confirmNewAdmin}
						>
							Create Admin
						</ButtonSM>
					</div>
				</Dialog>
			)}

			{/* SAVE AS ADMIN DIALOG */}
			{showSaveAsAdminDialog && (
				<Dialog
					icon="USER"
					title="Make User an Admin"
					subheading={
						<Subheading
							text="Is this admin account for the "
							facilityName={currentFacility?.communityName}
						/>
					}
					closeModal={() => setShowSaveAsAdminDialog(false)}
				>
					<div className={styles.SaveAsAdminDialog}>
						<ButtonSM
							customStyles={customCSS.cancelSaveAs}
							handleClick={cancelSaveAsAdmin}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							isDisabled={false}
							customStyles={customCSS.saveAs}
							handleClick={confirmSaveAsAdmin}
						>
							Yes, Save as Admin
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default UserAdminView;

UserAdminView.defaultProps = {};

UserAdminView.propTypes = {};
