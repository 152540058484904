const featureFlags = {
	resident: {
		transferResident: true,
	},
	user: {},
	facility: {
		enableSuspend: true,
		enableSuspendAndDelete: false,
		enableLegacyAccessToggle: true,
		enableTrackerAccessToggle: true,
		enablePortalAccessToggle: false,
		enableEmarAccessToggle: true,
		enableStaxAccessToggle: true,
	},
};

// List of UserIDs that should have access to super-features
/**
 * List of 'UserIDs' (ie guids) that should have access to 'super-features' and test functionality
 * - sgore99@aladvantage.com
 * - trainingsb@aladvantage.com
 * - trainingsb2@aladvantage.com
 */
const featuresWhiteList = [
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@aladvantage.com
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@aladvantage.com - seth's
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@aladvantage.com - seth's
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@aladvantage.com - seth's super-user account
	// `30e9000c-0b38-4645-bde2-824bdbfed572`, // granitecollission@gmail.com
	// `caa7eca2-5a82-40d3-b1d3-b32319afa03c`, // Jose867
];

/**
 * Determines whether a feature should be enabled/disabled.
 * @param {String} featureName - A feature's key name.
 * @param {Object} featureFlags - An object map of feature flags
 * @returns {Boolean} - Returns true|false
 */
const enableFeature = (featureName, featureFlags = {}) => {
	const isEnabled = featureFlags[featureName];
	return isEnabled;
};

/**
 * Determines whether to enable/disable a feature, via permissions and/or user-type.
 * @param {Object} currentUser - A client-formatted user object.
 * @param {Array} permissions - An array of permissions records.
 * @returns {Boolean} - Returns true|false
 */
const enableAccess = (currentUser = {}) => {
	const { userID, isAdmin, isRegionalAdmin, isSuperUser } = currentUser;
	const whiteListOverride = featuresWhiteList.includes(userID);
	// override feature access via 'whitelist'
	if (whiteListOverride) {
		return true;
	} else {
		return isAdmin || isRegionalAdmin || isSuperUser;
	}
};

/**
 * Determines a user's access, along with the feature's state (eg 'on/off').
 * @param {String} featureName - A string feature name.
 * @param {Object} featureFlags - An object map of feature flags.
 * @param {Object} currentUser - A user for whom to check access for.
 * @returns {Boolean} - Returns true|false
 */
const isAccessEnabled = (featureName, featureFlags = {}, currentUser = {}) => {
	// feature override for whitelist

	if (!enableFeature(featureName, featureFlags)) return false;
	if (featuresWhiteList.includes(currentUser.userID)) return true;
	if (!enableAccess(currentUser)) return false;
	return true;
};

/**
 * Enables/disables a feature via whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaWhiteList = (userID) => {
	const hasOverride = featuresWhiteList.includes(userID);

	return hasOverride;
};

// feature flags/toggles
export { featureFlags, featuresWhiteList };
// feature/access utils
export {
	enableFeature,
	enableAccess,
	isAccessEnabled,
	enableFeatureViaWhiteList,
};
