import { differenceInDays, isToday } from "date-fns";
import addHours from "date-fns/add_hours/index.js";
import { isEmptyVal } from "./utils_types";
import { getMatchGroups } from "./utils_validation";

export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

// conversion utils

// convert minutes to milliseconds
const convertMinsToMs = (mins) => {
	return mins * 60000;
};
// convert seconds to milliseconds
const convertSecsToMs = (secs) => {
	return secs * 1000;
};

// hours to mins
const convertHoursToMins = (hrs) => {
	// '+' converts string to number via coercion
	return +hrs * 60;
};
// convert days to hours
const convertDaysToHours = (days) => {
	// '+' converts string to number via coercion
	return +days * 24;
};
// convert days to mins
const convertDaysToMins = (days) => {
	// '+' converts string to number via coercion
	return +days * 24 * 60;
};
// convert mins to days
const convertMinsToDays = (mins) => {
	// '+' converts string to number via coercion
	return +mins / 1440;
};

// convert mins to hours
const convertMinsToHours = (mins) => {
	// '+' converts string to number via coercion
	return +mins / 60;
};

// EXTRACTING & PARSING DATES & DATE PORTIONS (ie. 'MONTH', 'DAY', 'YEAR' etc.)

// returns an array of each date type: [year, month, day, hour, mins, secs, ms]
const extractDateTypes = (date = new Date()) => {
	return new Date(date)
		.toISOString()
		.split(/[^0-9]/)
		.slice(0, -1);
};

// calculates the number of days from <date> to <today>
const calculateDiffFromDateToNow = (date) => {
	const targetDate = new Date(date);
	if (!(targetDate instanceof Date)) return 0;

	const now = new Date();
	const diff = differenceInDays(now, targetDate);

	return diff;
};

/**
 * Converts a client-formatted date string into an instance of a Date object.
 * @param {String} dateStr - A custom-formatted date string (ie. "01/21/2021")
 * @returns {Date|Null} - Returns 'real' date object after conversion OR returns 'null' if empty or today
 */
const getRealDateFromStr = (dateStr) => {
	if (isEmptyVal(dateStr)) return null;
	const newDate = new Date(dateStr).toISOString();
	if (isToday(newDate)) return null;

	return newDate;
};

// TIMEZONES //

/**
 * 'Timezone' regex(s) expressions:
 * - 'zone': (GMT), (GMT+1:00), (GMT-8:00)
 * - 'region': 'Casablanca', 'Greenwich Mean Time: Dublin, Edinburgh' etc etc.
 * - 'tz': the above combined
 */
const tzReg = {
	zone: /^((?<zone>\(|\w|\)|\+|-|\d{1,}|:)*)/gm,
	region: /(?<region>(\w|,|\.|\s|:)*)/gm,
	tz: /^((?<zone>\(|\w|\)|\+|-|\d{1,}|:)*)\s(?<region>(\w|,|\.|\s|:)*)/gm,
};

/**
 * Timezones:
 * - Zones have been updated w/ their respective abbreviations, as best as possible.
 * - Zones have been sorted alphabetically by name (ie. 'Afg', 'Bethelhem'...)
 */
const timezones = [
	{
		name: "Afghanistan Standard Time",
		zone: "(GMT+04:30) Kabul",
	},
	{
		name: "Alaskan Standard Time",
		zone: "(GMT-09:00) Alaska",
	},
	{
		name: "Arab Standard Time",
		zone: "(GMT+03:00) Kuwait, Riyadh",
	},
	{
		name: "Arabian Standard Time",
		zone: "(GMT+04:00) Abu Dhabi, Muscat",
	},
	{
		name: "Arabic Standard Time",
		zone: "(GMT+03:00) Baghdad",
	},
	{
		name: "Argentina Standard Time",
		zone: "(GMT-03:00) Buenos Aires",
	},
	{
		name: "Atlantic Standard Time",
		zone: "(GMT-04:00) Atlantic Time (Canada)",
	},
	{
		name: "AUS Central Standard Time",
		zone: "(GMT+09:30) Darwin",
	},
	{
		name: "AUS Eastern Standard Time",
		zone: "(GMT+10:00) Canberra, Melbourne, Sydney",
	},
	{
		name: "Azerbaijan Standard Time",
		zone: "(GMT+04:00) Baku",
	},
	{
		name: "Azores Standard Time",
		zone: "(GMT-01:00) Azores",
	},
	{
		name: "Canada Central Standard Time",
		zone: "(GMT-06:00) Saskatchewan",
	},
	{
		name: "Cape Verde Standard Time",
		zone: "(GMT-01:00) Cape Verde Is.",
	},
	{
		name: "Caucasus Standard Time",
		zone: "(GMT+04:00) Yerevan",
	},
	{
		name: "Cen. Australia Standard Time",
		zone: "(GMT+09:30) Adelaide",
	},
	{
		name: "Central America Standard Time",
		zone: "(GMT-06:00) Central America",
	},
	{
		name: "Central Asia Standard Time",
		zone: "(GMT+06:00) Astana, Dhaka",
	},
	{
		name: "Central Brazilian Standard Time",
		zone: "(GMT-04:00) Manaus",
	},
	{
		name: "Central Europe Standard Time",
		zone: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
	},
	{
		name: "Central European Standard Time",
		zone: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
	},
	{
		name: "Central Pacific Standard Time",
		zone: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
	},
	{
		name: "Central Standard Time",
		zone: "(GMT-06:00) Central Time (US & Canada)",
	},
	{
		name: "Central Standard Time (Mexico)",
		zone: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
	},
	{
		name: "China Standard Time",
		zone: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
	},
	{
		name: "Dateline Standard Time",
		zone: "(GMT-12:00) International Date Line West",
	},
	{
		name: "E. Africa Standard Time",
		zone: "(GMT+03:00) Nairobi",
	},
	{
		name: "E. Australia Standard Time",
		zone: "(GMT+10:00) Brisbane",
	},
	{
		name: "E. Europe Standard Time",
		zone: "(GMT+02:00) Minsk",
	},
	{
		name: "E. South America Standard Time",
		zone: "(GMT-03:00) Brasilia",
	},
	{
		name: "Eastern Standard Time",
		zone: "(GMT-05:00) Eastern Time (US & Canada)",
	},
	{
		name: "Egypt Standard Time",
		zone: "(GMT+02:00) Cairo",
	},
	{
		name: "Ekaterinburg Standard Time",
		zone: "(GMT+05:00) Ekaterinburg",
	},
	{
		name: "Fiji Standard Time",
		zone: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
	},
	{
		name: "FLE Standard Time",
		zone: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
	},
	{
		name: "Georgian Standard Time",
		zone: "(GMT+03:00) Tbilisi",
	},
	{
		name: "GMT Standard Time",
		zone: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
	},
	{
		name: "Greenland Standard Time",
		zone: "(GMT-03:00) Greenland",
	},
	{
		name: "Greenwich Standard Time",
		zone: "(GMT) Monrovia, Reykjavik",
	},
	{
		name: "GTB Standard Time",
		zone: "(GMT+02:00) Athens, Bucharest, Istanbul",
	},
	{
		name: "Hawaiian Standard Time",
		zone: "(GMT-10:00) Hawaii",
	},
	{
		name: "India Standard Time",
		zone: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
	},
	{
		name: "Iran Standard Time",
		zone: "(GMT+03:30) Tehran",
	},
	{
		name: "Israel Standard Time",
		zone: "(GMT+02:00) Jerusalem",
	},
	{
		name: "Jordan Standard Time",
		zone: "(GMT+02:00) Amman",
	},
	{
		name: "Korea Standard Time",
		zone: "(GMT+09:00) Seoul",
	},
	{
		name: "Mauritius Standard Time",
		zone: "(GMT+04:00) Port Louis",
	},
	{
		name: "Mid-Atlantic Standard Time",
		zone: "(GMT-02:00) Mid-Atlantic",
	},
	{
		name: "Middle East Standard Time",
		zone: "(GMT+02:00) Beirut",
	},
	{
		name: "Montevideo Standard Time",
		zone: "(GMT-03:00) Montevideo",
	},
	{
		name: "Morocco Standard Time",
		zone: "(GMT) Casablanca",
	},
	{
		name: "Mountain Standard Time",
		zone: "(GMT-07:00) Mountain Time (US & Canada)",
	},
	{
		name: "Mountain Standard Time (Mexico)",
		zone: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
	},
	{
		name: "Myanmar Standard Time",
		zone: "(GMT+06:30) Yangon (Rangoon)",
	},
	{
		name: "N. Central Asia Standard Time",
		zone: "(GMT+06:00) Almaty, Novosibirsk",
	},
	{
		name: "Namibia Standard Time",
		zone: "(GMT+02:00) Windhoek",
	},
	{
		name: "Nepal Standard Time",
		zone: "(GMT+05:45) Kathmandu",
	},
	{
		name: "New Zealand Standard Time",
		zone: "(GMT+12:00) Auckland, Wellington",
	},
	{
		name: "Newfoundland Standard Time",
		zone: "(GMT-03:30) Newfoundland",
	},
	{
		name: "North Asia East Standard Time",
		zone: "(GMT+08:00) Irkutsk, Ulaan Bataar",
	},
	{
		name: "North Asia Standard Time",
		zone: "(GMT+07:00) Krasnoyarsk",
	},
	{
		name: "Pacific SA Standard Time",
		zone: "(GMT-04:00) Santiago",
	},
	{
		name: "Pacific Standard Time",
		zone: "(GMT-08:00) Pacific Time (US & Canada)",
	},
	{
		name: "Pacific Standard Time (Mexico)",
		zone: "(GMT-08:00) Tijuana, Baja California",
	},
	{
		name: "Pakistan Standard Time",
		zone: "(GMT+05:00) Islamabad, Karachi",
	},
	{
		name: "Romance Standard Time",
		zone: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
	},
	{
		name: "Russian Standard Time",
		zone: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
	},
	{
		name: "SA Eastern Standard Time",
		zone: "(GMT-03:00) Georgetown",
	},
	{
		name: "SA Pacific Standard Time",
		zone: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
	},
	{
		name: "SA Western Standard Time",
		zone: "(GMT-04:00) La Paz",
	},
	{
		name: "Samoa Standard Time",
		zone: "(GMT-11:00) Midway Island, Samoa",
	},
	{
		name: "SE Asia Standard Time",
		zone: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
	},
	{
		name: "Singapore Standard Time",
		zone: "(GMT+08:00) Kuala Lumpur, Singapore",
	},
	{
		name: "South Africa Standard Time",
		zone: "(GMT+02:00) Harare, Pretoria",
	},
	{
		name: "Sri Lanka Standard Time",
		zone: "(GMT+05:30) Sri Jayawardenepura",
	},
	{
		name: "Taipei Standard Time",
		zone: "(GMT+08:00) Taipei",
	},
	{
		name: "Tasmania Standard Time",
		zone: "(GMT+10:00) Hobart",
	},
	{
		name: "Tokyo Standard Time",
		zone: "(GMT+09:00) Osaka, Sapporo, Tokyo",
	},
	{
		name: "Tonga Standard Time",
		zone: "(GMT+13:00) Nuku'alofa",
	},
	{
		name: "US Eastern Standard Time",
		zone: "(GMT-05:00) Indiana (East)",
	},
	{
		name: "US Mountain Standard Time",
		zone: "(GMT-07:00) Arizona",
	},
	{
		name: "Venezuela Standard Time",
		zone: "(GMT-04:30) Caracas",
	},
	{
		name: "Vladivostok Standard Time",
		zone: "(GMT+10:00) Vladivostok",
	},
	{
		name: "W. Australia Standard Time",
		zone: "(GMT+08:00) Perth",
	},
	{
		name: "W. Central Africa Standard Time",
		zone: "(GMT+01:00) West Central Africa",
	},
	{
		name: "W. Europe Standard Time",
		zone: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
	},
	{
		name: "West Asia Standard Time",
		zone: "(GMT+05:00) Tashkent",
	},
	{
		name: "West Pacific Standard Time",
		zone: "(GMT+10:00) Guam, Port Moresby",
	},
	{
		name: "Yakutsk Standard Time",
		zone: "(GMT+09:00) Yakutsk",
	},
];

/**
 * Legacy's timezone formatted list:
 * - Format: "(<zone>) <area>"
 */
const legacyTimezones = [
	"(GMT) Casablanca",
	"(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
	"(GMT) Monrovia, Reykjavik",
	"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
	"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
	"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
	"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
	"(GMT+01:00) West Central Africa",
	"(GMT+02:00) Amman",
	"(GMT+02:00) Athens, Bucharest, Istanbul",
	"(GMT+02:00) Beirut",
	"(GMT+02:00) Cairo",
	"(GMT+02:00) Harare, Pretoria",
	"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
	"(GMT+02:00) Jerusalem",
	"(GMT+02:00) Minsk",
	"(GMT+02:00) Windhoek",
	"(GMT+03:00) Baghdad",
	"(GMT+03:00) Kuwait, Riyadh",
	"(GMT+03:00) Moscow, St. Petersburg, Volgograd",
	"(GMT+03:00) Nairobi",
	"(GMT+03:00) Tbilisi",
	"(GMT+03:30) Tehran",
	"(GMT+04:00) Abu Dhabi, Muscat",
	"(GMT+04:00) Baku",
	"(GMT+04:00) Port Louis",
	"(GMT+04:00) Yerevan",
	"(GMT+04:30) Kabul",
	"(GMT+05:00) Ekaterinburg",
	"(GMT+05:00) Islamabad, Karachi",
	"(GMT+05:00) Tashkent",
	"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
	"(GMT+05:30) Sri Jayawardenepura",
	"(GMT+05:45) Kathmandu",
	"(GMT+06:00) Almaty, Novosibirsk",
	"(GMT+06:00) Astana, Dhaka",
	"(GMT+06:30) Yangon (Rangoon)",
	"(GMT+07:00) Bangkok, Hanoi, Jakarta",
	"(GMT+07:00) Krasnoyarsk",
	"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
	"(GMT+08:00) Irkutsk, Ulaan Bataar",
	"(GMT+08:00) Kuala Lumpur, Singapore",
	"(GMT+08:00) Perth",
	"(GMT+08:00) Taipei",
	"(GMT+09:00) Osaka, Sapporo, Tokyo",
	"(GMT+09:00) Seoul",
	"(GMT+09:00) Yakutsk",
	"(GMT+09:30) Adelaide",
	"(GMT+09:30) Darwin",
	"(GMT+10:00) Brisbane",
	"(GMT+10:00) Canberra, Melbourne, Sydney",
	"(GMT+10:00) Guam, Port Moresby",
	"(GMT+10:00) Hobart",
	"(GMT+10:00) Vladivostok",
	"(GMT+11:00) Magadan, Solomon Is., New Caledonia",
	"(GMT+12:00) Auckland, Wellington",
	"(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
	"(GMT+13:00) Nuku'alofa",
	"(GMT-01:00) Azores",
	"(GMT-01:00) Cape Verde Is.",
	"(GMT-02:00) Mid-Atlantic",
	"(GMT-03:00) Brasilia",
	"(GMT-03:00) Buenos Aires",
	"(GMT-03:00) Georgetown",
	"(GMT-03:00) Greenland",
	"(GMT-03:00) Montevideo",
	"(GMT-03:30) Newfoundland",
	"(GMT-04:00) Atlantic Time (Canada)",
	"(GMT-04:00) La Paz",
	"(GMT-04:00) Manaus",
	"(GMT-04:00) Santiago",
	"(GMT-04:30) Caracas",
	"(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
	"(GMT-05:00) Eastern Time (US & Canada)",
	"(GMT-05:00) Indiana (East)",
	"(GMT-06:00) Central America",
	"(GMT-06:00) Central Time (US & Canada)",
	"(GMT-06:00) Guadalajara, Mexico City, Monterrey",
	"(GMT-06:00) Saskatchewan",
	"(GMT-07:00) Arizona",
	"(GMT-07:00) Chihuahua, La Paz, Mazatlan",
	"(GMT-07:00) Mountain Time (US & Canada)",
	"(GMT-08:00) Pacific Time (US & Canada)",
	"(GMT-08:00) Tijuana, Baja California",
	"(GMT-09:00) Alaska",
	"(GMT-10:00) Hawaii",
	"(GMT-11:00) Midway Island, Samoa",
	"(GMT-12:00) International Date Line West",
];

const getZoneName = (selection) => {
	const groups = getMatchGroups(selection);
	return groups.region;
};

// returns: "Mountain Standard Time\n\n (GMT+7:00)"
const formatTimezones = (timezones) => {
	return timezones.map((timezone) => {
		const { zone, name } = timezone;
		const mergedZone = `${name}\n\n - ${zone}`;
		return mergedZone;
	});
};

const getTimeZoneAbbrev = (tzString) => {
	// remove all caps and merge together
};

export {
	convertMinsToMs,
	convertSecsToMs,
	convertHoursToMins,
	convertDaysToHours,
	convertDaysToMins,
	convertMinsToDays,
	convertMinsToHours,
	// calculate difference in days
	calculateDiffFromDateToNow,
	extractDateTypes,
};

export { getRealDateFromStr };

export { tzReg };
export { timezones, legacyTimezones, formatTimezones };
