import React, { useState, useEffect } from "react";
import styles from "../../css/banners/CurrentBannersList.module.scss";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import CurrentBannerEntry from "./CurrentBannerEntry";
import {
	getAllAcknowledgements,
	getBannerIDs,
	getMatchingAcknowledgement,
} from "../../helpers/utils_banners";

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((entry) => {
		const isMatch =
			entry?.FacilityId?.toLowerCase().includes(val) ||
			entry?.FacilityId?.toLowerCase().startsWith(val) ||
			entry?.FacilityName?.toLowerCase().includes(val) ||
			entry?.FacilityName?.toLowerCase().startsWith(val);

		return isMatch;
	});
};

const CurrentBannersList = ({
	activeBanners = [],
	parentsMap = {},
	facilitiesMap = {},
	currentUser = {},
	refetchBanners,
	dispatchAlert,
}) => {
	const [acknowledgements, setAcknowledgements] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [clonedBanners, setClonedBanners] = useState([...activeBanners]);
	const [searchVal, setSearchVal] = useState("");

	const handleSearch = (e) => {
		const { value } = e.target;

		if (isEmptyVal(value)) {
			setSearchVal("");
			return setClonedBanners([...activeBanners]);
		} else {
			const results = advancedSearch(value, activeBanners);
			setSearchVal(value);
			return setClonedBanners(results);
		}
	};

	const fetchBannerAcknowledgements = async (e) => {
		const { token } = currentUser;
		const bannerIDs = getBannerIDs(activeBanners);
		const records = await getAllAcknowledgements(token, bannerIDs);

		if (!isEmptyArray(records)) {
			setIsLoading(false);
			return setAcknowledgements(records);
		} else {
			setIsLoading(false);
			return setAcknowledgements([]);
		}
	};

	// fetches ALL relevant acknowledgement records for all active banner(s)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchBannerAcknowledgements();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.CurrentBannersList}>
			<div className={styles.CurrentBannersList_header}>
				<h2 className={styles.CurrentBannersList_header_title}>
					All Active Service Banners
				</h2>
				<p className={styles.CurrentBannersList_header_desc}>
					View, edit and/or remove any existing service banners displayed in the
					User Portal (Admin Portal).
				</p>
			</div>
			<div className={styles.CurrentBannersList_search}>
				<input
					type="text"
					value={searchVal}
					id="entrySearch"
					name="entrySearch"
					onChange={handleSearch}
					placeholder="Search banners by community, facilityID..."
					className={styles.CurrentBannersList_search_input}
				/>
			</div>
			<div className={styles.CurrentBannersList_list}>
				{/* DEFAULT LIST */}
				{!isEmptyArray(activeBanners) &&
					isEmptyVal(searchVal) &&
					!isLoading &&
					activeBanners.map((entry, idx) => (
						<CurrentBannerEntry
							key={`BANNER-ENTRY-${idx}-${acknowledgements?.length}`}
							bannerEntry={entry}
							parentsMap={parentsMap}
							facilitiesMap={facilitiesMap}
							currentUser={currentUser}
							refetchBanners={refetchBanners}
							dispatchAlert={dispatchAlert}
							acknowledgement={getMatchingAcknowledgement(
								entry,
								acknowledgements
							)}
						/>
					))}
				{/* SEARCH RESULTS LIST */}
				{!isEmptyArray(clonedBanners) &&
					!isEmptyVal(searchVal) &&
					!isLoading &&
					clonedBanners.map((entry, idx) => (
						<CurrentBannerEntry
							key={`SEARCH-ENTRY-${idx}-${acknowledgements?.length}`}
							bannerEntry={entry}
							parentsMap={parentsMap}
							facilitiesMap={facilitiesMap}
							currentUser={currentUser}
							refetchBanners={refetchBanners}
							dispatchAlert={dispatchAlert}
							acknowledgement={getMatchingAcknowledgement(
								entry,
								acknowledgements
							)}
						/>
					))}
			</div>
		</div>
	);
};

export default CurrentBannersList;

CurrentBannersList.defaultProps = {};

CurrentBannersList.propTypes = {};
