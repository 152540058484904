import React from "react";
import styles from "../../css/app/LogoutButton.module.scss";
import sprite from "../../assets/icons/settings.svg";
import { PropTypes } from "prop-types";

const LogoutButton = ({ handleLogout }) => {
	return (
		<button
			type="button"
			onClick={handleLogout}
			className={styles.LogoutButton}
		>
			<svg className={styles.LogoutButton_icon}>
				<use xlinkHref={`${sprite}#icon-logout`}></use>
			</svg>
			<span className={styles.LogoutButton_text}>Logout</span>
		</button>
	);
};

export default LogoutButton;

LogoutButton.defaultProps = {};

LogoutButton.propTypes = {
	handleLogout: PropTypes.func,
};
