import React, { useRef, useEffect, useState } from "react";
import styles from "../../css/shared/CustomSelector.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { blueGrey, purple, red } from "../../helpers/utils_styles";

/**
 * Duplicate version of <CustomDropdown/> component designed for:
 * - Support for objects.
 * - Support for dense datasets.
 * - Support for providing vast info for each list item.
 * - Support for showing multiple fields from a single object.
 * - Support for searching all fields within an array of objects.
 * - Supports persisting scroll position on selection.
 */

const getIconColor = (selection) => {
	if (isEmptyVal(selection)) {
		return {
			fill: blueGrey[700],
		};
	} else {
		return {
			fill: red[600],
		};
	}
};

const requiredCss = {
	boxShadow: `0 0 0 2px hsla(352, 70%, 50%, 0.6)`,
};

const advancedSearch = (searchVal, options, keyVals = {}) => {
	const { primaryKeyName, secondaryKeyName } = keyVals;
	searchVal = searchVal.toLowerCase();

	return options.filter((option) => {
		if (
			option?.[primaryKeyName]?.toLowerCase()?.includes(searchVal) ||
			option?.[secondaryKeyName]?.toLowerCase()?.includes(searchVal)
		) {
			return option;
		} else {
			return null;
		}
	});
};

const getInputCss = (isRequired, selection, customStyles = {}) => {
	if (isRequired && isEmptyVal(selection))
		return { ...customStyles, ...requiredCss };

	return customStyles;
};

const ENTER = "Enter" || 13;
const ESCAPE = "Escape" || 27;

const OptionItem = ({
	index,
	name,
	handleSelection,
	handleSelectionByKey,
	handleFocus,
	hasFocus,
	option = {},
	primaryKeyName,
	secondaryKeyName,
}) => {
	return (
		<li
			tabIndex={0}
			key={`${option[primaryKeyName]}__${index}`}
			onClick={handleSelection}
			onFocus={handleFocus}
			onKeyDown={handleSelectionByKey}
			className={
				hasFocus === option[primaryKeyName]
					? styles.CustomSelector_options_list_item_focus
					: styles.CustomSelector_options_list_item
			}
		>
			<div
				className={styles.CustomSelector_options_list_item_primary}
				style={
					hasFocus === option[primaryKeyName] ? { color: purple[700] } : {}
				}
			>
				{option[primaryKeyName]}
			</div>
			<div className={styles.CustomSelector_options_list_item_secondary}>
				{option[secondaryKeyName]}
			</div>
		</li>
	);
};

const OptionsMenu = ({
	name,
	closeHandler,
	handleSelection,
	handleSelectionByKey,
	handleFocus,
	hasFocus,
	options = [],
	primaryKeyName,
	secondaryKeyName,
	listRef, // used for closing menu & scroll position
	scrollState,
}) => {
	const { isOutside } = useOutsideClick(listRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || wasEscaped) {
			return closeHandler(false);
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, wasEscaped, closeHandler]);

	// persist scroll position, if has a 'selection'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (listRef.current && scrollState) {
			listRef.current.scrollTop = scrollState;
		}

		return () => {
			isMounted = false;
		};
	}, [listRef, scrollState]);

	return (
		<aside className={styles.CustomSelector_options} ref={listRef}>
			<ul className={styles.CustomSelector_options_list}>
				{!isEmptyArray(options) &&
					options.map((option, index) => (
						<OptionItem
							tabIndex={0}
							index={index}
							option={option}
							key={`${option}__${index}`}
							handleSelection={() => handleSelection(name, option)}
							handleFocus={() => handleFocus(option)}
							handleSelectionByKey={handleSelectionByKey}
							primaryKeyName={primaryKeyName}
							secondaryKeyName={secondaryKeyName}
							hasFocus={hasFocus}
						/>
					))}
			</ul>
		</aside>
	);
};

const CustomSelector = ({
	name,
	id,
	label,
	options = [],
	placeholder,
	selection,
	setSelection,
	primaryKeyName,
	secondaryKeyName,
	autoFocus = false,
	isDisabled = false,
	isRequired = false,
	inputMode = "text",
	customStyles = {},
}) => {
	const inputRef = useRef();
	const listRef = useRef();
	const [showOptions, setShowOptions] = useState(false); // menu options
	const [listOptions, setListOptions] = useState(options); // for filtering/searching
	const [hasFocus, setHasFocus] = useState("");
	const [searchVal, setSearchVal] = useState("");
	// scroll state - persist scroll position
	const [scrollState, setScrollState] = useState(0);

	// sets scroll position when selections are made
	const handleScrollPos = () => {
		setScrollState(listRef?.current?.scrollTop ?? 0);
	};

	// handles "click" selection for a menu option
	const handleSelection = (name, option) => {
		setSelection(name, option[primaryKeyName]);
		handleScrollPos();
	};

	// sets active focused item
	// allows custom focus styles to be applied
	const handleFocus = (option) => {
		setHasFocus(option[primaryKeyName]);
	};

	// handles checking for a match
	// setting the selection when an option is focused and the
	// "ENTER" key is pressed
	const handleSelectionByKey = (e) => {
		const currentEl = e.target.textContent;

		const hasMatch = (val) => {
			return listOptions.includes(val);
		};
		if (hasMatch(currentEl) && e.key === ENTER) {
			return handleSelection(name, currentEl);
		}
		if (e.key === ESCAPE) {
			return setShowOptions(false);
		}
		return;
	};

	// ##TODOS:
	// - Update to support searching objects ✓
	// - Update to support searching ALL keys in objects ✓
	// - Update to be easily extendable via additional keys ✓
	// 		- TO add keys to search:
	// 				- Add new 'key' as prop
	// 				- Add new key as search criteria in 'advancedSearch'
	// 				- Pass new key as arg in 'keyVals' argument below
	const handleSearch = (e) => {
		const { value } = e.target;
		if (!isEmptyVal(selection)) {
			clearSelection();
			setSearchVal(value);
			return setListOptions([
				...advancedSearch(value, options, {
					primaryKeyName,
					secondaryKeyName,
				}),
			]);
		} else {
			setSearchVal(value);
			return setListOptions([
				...advancedSearch(value, options, {
					primaryKeyName,
					secondaryKeyName,
				}),
			]);
		}
	};

	// clear search, focus, 'selection' & reset list 'options'
	const clearSelection = () => {
		handleSelection(name, "");
		setSearchVal("");
		handleFocus("");
		setListOptions([...options]);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// close options menu when selection is made
		if (!isEmptyVal(selection)) {
			return setShowOptions(false);
		}

		return () => {
			isMounted = false;
		};
	}, [selection]);

	return (
		<div className={styles.CustomSelector} style={customStyles}>
			<label htmlFor={id} className={styles.CustomSelector_label}>
				{label}
			</label>
			<div className={styles.CustomSelector_inputWrapper} style={customStyles}>
				<input
					ref={inputRef}
					type="text"
					value={isEmptyVal(selection) ? searchVal : selection}
					name={name}
					id={id}
					placeholder={placeholder}
					onChange={handleSearch}
					onClick={isDisabled ? null : () => setShowOptions(true)}
					onFocus={isDisabled ? null : () => setShowOptions(true)}
					className={styles.CustomSelector_inputWrapper_input}
					autoComplete="off"
					autoFocus={autoFocus}
					disabled={isDisabled}
					inputMode={inputMode}
					required={isRequired}
					style={getInputCss(isRequired, selection, customStyles)}
				/>
				{showOptions && (
					<OptionsMenu
						name={name}
						handleSelection={handleSelection}
						handleSelectionByKey={handleSelectionByKey}
						handleFocus={handleFocus}
						hasFocus={hasFocus}
						closeHandler={setShowOptions}
						options={listOptions}
						primaryKeyName={primaryKeyName}
						secondaryKeyName={secondaryKeyName}
						listRef={listRef}
						scrollState={scrollState}
					/>
				)}

				<svg
					className={styles.CustomSelector_closeIcon}
					onClick={() => {
						if (isDisabled) return;
						if (isEmptyVal(selection)) {
							return setShowOptions(true);
						} else {
							return clearSelection(name);
						}
					}}
					style={getIconColor(selection)}
				>
					<use
						xlinkHref={`${sprite}#icon-${
							!isEmptyVal(selection) ? "clearclose" : "caret-down"
						}`}
					/>
				</svg>
			</div>
		</div>
	);
};

export default CustomSelector;

CustomSelector.defaultProps = {
	options: [],
};

CustomSelector.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	inputMode: PropTypes.string,
	options: PropTypes.array,
	selection: PropTypes.string.isRequired, // input state value
	setSelection: PropTypes.func.isRequired, // state setter for input
};

OptionsMenu.defaultProps = {
	options: [],
};
OptionsMenu.defaultProps = {
	name: PropTypes.string.isRequired,
	closeHandler: PropTypes.func.isRequired,
	handleSelection: PropTypes.func.isRequired, // "onClick" handler for value selection
	options: PropTypes.array.isRequired, // menu options
};
