import React, { useState, useEffect } from "react";
import styles from "../../css/admin/UserAdmin.module.scss";
import { PropTypes } from "prop-types";
import FacilityAdmin from "../facility/FacilityAdmin";
import ChangeUserType from "./ChangeUserType";

const UserAdmin = ({
	vals,
	currentUser = {},
	currentFacility,
	facilityUsers = [],
	usersToMakeAdmin,
	copySelection,
	handleChange,
	handleCustomFields,
	handleSelectFacility,
	handlePasswordGeneration,
	selectUserToMakeAdmin,
	createNewAdmin,
	cancelNewAdmin,
	saveAsAdmin,
	cancelSaveAsAdmin,
	dispatchAlert,
	dispatchToState,
	globalState,
}) => {
	const [wasCopied, setWasCopied] = useState(false);

	const handleCopy = (fieldName) => {
		setWasCopied(true);
		copySelection(fieldName);
	};

	// resets 'wasCopied' state after copying
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let copiedID;
		if (wasCopied) {
			copiedID = setTimeout(() => {
				setWasCopied(false);
			}, 2000);
		}

		return () => {
			isMounted = false;
			clearTimeout(copiedID);
		};
	}, [wasCopied]);

	return (
		<div className={styles.UserAdmin}>
			<FacilityAdmin
				vals={vals}
				facilityUsers={facilityUsers}
				currentFacility={currentFacility}
				usersToMakeAdmin={usersToMakeAdmin}
				wasCopied={wasCopied}
				handleChange={handleChange}
				handleCopy={handleCopy}
				handleSelection={handleCustomFields}
				handleSelectFacility={handleSelectFacility}
				handlePasswordGeneration={handlePasswordGeneration}
				selectUserToMakeAdmin={selectUserToMakeAdmin}
				createNewAdmin={createNewAdmin}
				cancelNewAdmin={cancelNewAdmin}
				saveAsAdmin={saveAsAdmin}
				cancelAdmin={cancelSaveAsAdmin} // cancels 'make-user-admin' feature
			/>
			<div className={styles.UserAdmin_options}>
				<ChangeUserType
					currentFacility={currentFacility}
					currentUser={currentUser}
					globalState={globalState}
					dispatchToState={dispatchToState}
					dispatchAlert={dispatchAlert}
				/>
			</div>
		</div>
	);
};

export default UserAdmin;

UserAdmin.defaultProps = {};

UserAdmin.propTypes = {};
