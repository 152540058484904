import React, { useState } from "react";
import styles from "../../css/facility/UnSuspendController.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { unsuspendFacilityAccount } from "../../helpers/utils_suspend";
import { format } from "date-fns";
import { blue, red } from "../../helpers/utils_styles";

const getDateOfSuspension = (facility = {}) => {
	const hasDate = !isEmptyVal(facility?.SuspendStartDate);

	if (hasDate) {
		const date = facility?.SuspendStartDate;
		return format(date, "MM/DD/YYYY");
	} else {
		return `Date Unknown`;
	}
};

const searchHandler = (val, list = []) => {
	val = val.toLowerCase();

	return [...list].filter((facility) => {
		if (
			facility?.CommunityName?.toLowerCase().startsWith(val) ||
			facility?.CommunityName?.toLowerCase().includes(val) ||
			facility?.City?.toLowerCase().startsWith(val) ||
			facility?.guidFacility?.toLowerCase() === val
		) {
			return facility;
		}
		return null;
	});
};

const getSelection = (currentFacility, targetFacility) => {
	if (isEmptyVal(targetFacility?.guidFacility)) {
		return currentFacility?.facilityID ?? "Choose Community";
	} else {
		return targetFacility?.CommunityName;
	}
};

const UnSuspendSummary = ({ currentFacility, targetFacility }) => {
	return (
		<div className={styles.UnSuspendSummary}>
			<div className={styles.UnSuspendSummary_text}>
				{/* You're about to un-suspend the <b>{currentFacility.communityName}</b>{" "} */}
				You're about to un-suspend the{" "}
				<b>{getSelection(currentFacility, targetFacility)}</b> community.
			</div>
		</div>
	);
};

const SuspendedEntry = ({
	facility = {},
	selectFacility,
	isSelected = false,
}) => {
	return (
		<li
			className={styles.SuspendedEntry}
			onClick={() => selectFacility(facility)}
			style={
				isSelected
					? { color: blue[600], fontWeight: "800", fontSize: "3rem" }
					: {}
			}
		>
			<div className={styles.SuspendedEntry_name}>
				{facility?.CommunityName}
			</div>
			<div className={styles.SuspendedEntry_date}>
				Suspended: {getDateOfSuspension(facility)}
			</div>
		</li>
	);
};

const SuspendedList = ({
	selectedFacility,
	selectFacility,
	suspendedFacilities = [],
}) => {
	return (
		<div className={styles.SuspendedList}>
			<ul className={styles.SuspendedList_list}>
				{!isEmptyArray(suspendedFacilities) &&
					suspendedFacilities.map((facility, idx) => (
						<SuspendedEntry
							key={`${facility?.guidFacility}--${idx}`}
							facility={facility}
							selectFacility={() => selectFacility(facility)}
							isSelected={
								selectedFacility?.guidFacility === facility?.guidFacility
							}
						/>
					))}
			</ul>
		</div>
	);
};

const UnSuspendController = ({
	currentUser,
	currentFacility = {},
	globalState = {},
	dispatchAlert,
	suspendReasons = [],
	suspendedFacilities = [],
}) => {
	const [wasUnsuspended, setWasUnsuspended] = useState(false);
	const [targetFacility, setTargetFacility] = useState({});
	const [searchVal, setSearchVal] = useState("");
	const [suspendedList, setSuspendedList] = useState([...suspendedFacilities]);

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		if (isEmptyVal(value)) {
			return setSuspendedList([...suspendedFacilities]);
		} else {
			return setSuspendedList([...searchHandler(value, suspendedFacilities)]);
		}
	};

	const selectFacility = (facility) => {
		setTargetFacility(facility);
	};

	const unsuspendFacility = async () => {
		const { token } = currentUser;
		const { guidFacility } = targetFacility;
		const wasSaved = await unsuspendFacilityAccount(token, guidFacility);

		if (wasSaved) {
			setWasUnsuspended(true);
			return dispatchAlert("SUCCESS", {
				heading: `Community was un-suspended!`,
			});
		} else {
			setWasUnsuspended(false);
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred.`,
				subheading: `Please try again.`,
			});
		}
	};

	return (
		<div className={styles.UnSuspendController}>
			<div className={styles.UnSuspendController_search}>
				<input
					type="text"
					name="search"
					id="search"
					value={searchVal}
					placeholder="Search facility..."
					onChange={handleSearch}
					className={styles.UnSuspendController_search_input}
				/>
			</div>
			{/* SUMMARY/WARNING */}
			<div className={styles.UnSuspendController_summary}>
				<SuspendedList
					selectFacility={selectFacility}
					selectedFacility={targetFacility}
					// suspendedFacilities={suspendedFacilities}
					suspendedFacilities={suspendedList}
				/>
				{!isEmptyVal(targetFacility.guidFacility) && (
					<UnSuspendSummary
						currentFacility={currentFacility}
						targetFacility={targetFacility}
					/>
				)}
			</div>
			<div className={styles.UnSuspendController_actions}>
				<button
					type="button"
					disabled={
						isEmptyVal(currentFacility.facilityID) &&
						isEmptyVal(targetFacility?.guidFacility)
					}
					onClick={unsuspendFacility}
					className={styles.UnSuspendController_actions_btn}
				>
					Un-Suspend
				</button>
			</div>
		</div>
	);
};

export default UnSuspendController;

UnSuspendController.defaultProps = {};

UnSuspendController.propTypes = {};
