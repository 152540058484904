import React from "react";
import styles from "../../css/user/UserDropdown.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
// components
import MultiSelect from "../shared/MultiSelect";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	selector: {
		backgroundColor: "#ffffff",
	},
	remove: {
		padding: ".8rem 1.6rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "500",
		marginTop: "3rem",
	},
};

const formatUsers = (users = []) => {
	if (isEmptyArray(users)) return [];
	return users.map((user) => {
		const { firstName, lastName, email } = user;
		return `${firstName} ${lastName} (${email})`;
	});
};

const UserDropdown = ({
	usersList = [],
	handleUserSelections,
	removeUsers,
	disableRemove = false,
	label = "Select users to remove:",
}) => {
	return (
		<div className={styles.UserDropdown}>
			<label htmlFor="usersToRemove">{label}</label>
			<MultiSelect
				handleMultiSelect={handleUserSelections}
				options={formatUsers(usersList)}
				customStyles={customCSS.selector}
			/>
			{!disableRemove && (
				<ButtonSM customStyles={customCSS.remove} handleClick={removeUsers}>
					Remove Users
				</ButtonSM>
			)}
		</div>
	);
};

export default UserDropdown;

UserDropdown.defaultProps = {};

UserDropdown.propTypes = {
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			email: PropTypes.string,
			userID: PropTypes.string,
		})
	),
};
