import React, { useState } from "react";
import styles from "../../css/facility/ImportDataExample.module.scss";
import { PropTypes } from "prop-types";
import { createBlob } from "../../helpers/utils_files";
import template from "../../assets/docs/Template-Sample.csv";
import sampleImg from "../../assets/docs/Template-SampleCSV.png";
// components
import FileCard from "../ui/FileCard";

/**
 * File details & formats that are available for download:
 * - Local file template download
 * - Sharepoint Excel template download
 */
const fileInfo = {
	fileName: `ResidentDataTemplate.csv`,
	link: `https://carebrandsnet.sharepoint.com/:x:/r/sites/ALADev/_layouts/15/Doc.aspx?sourcedoc=%7B84DB00E1-C534-46CD-A113-47F5EEDE06AA%7D&file=ALA%20Import%20Resident%20File%20Template.xlsx&action=default&mobileredirect=true&DefaultItemOpen=1`,
	types: {
		excel: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;`,
		csv: `text/csv`,
	},
};

// const fileName = `ResidentDataTemplate.xlsx`;
const fileName = `ResidentDataTemplate.csv`;
const excelType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;`;

const CustomLink = ({ href, children }) => {
	return (
		<a
			href={href}
			target="_blank"
			rel="noopener noreferrer"
			className={styles.CustomLink}
		>
			{children}
		</a>
	);
};

const ImportDataExample = () => {
	const [templateFiles, setTemplateFiles] = useState({
		sample: createBlob(sampleImg, excelType),
		file: template,
		link: fileInfo?.link,
	});

	return (
		<div className={styles.ImportDataExample}>
			<div className={styles.ImportDataExample_text}>
				Click to download template
			</div>
			<div className={styles.ImportDataExample_card}>
				<FileCard
					key="IMPORT-TEMPLATE"
					// preview img details
					fileName={fileInfo?.fileName}
					previewSrc={sampleImg}
					previewAlt="Downloadable template file"
					// download file details
					fileSrc={template}
					// fileHref={fileInfo.link}
					size="18 KB"
				/>
			</div>
			<div className={styles.ImportDataExample_link}>
				<div className={styles.ImportDataExample_link_or}>OR</div>
				<CustomLink href={fileInfo.link}>
					Open File in Sharepoint Repo
				</CustomLink>
			</div>
		</div>
	);
};

export default ImportDataExample;

ImportDataExample.defaultProps = {};

ImportDataExample.propTypes = {};
