import { currentEnv } from "./utils_env";
import {
	facility,
	facilityTypes,
	generic,
	getFacilities,
	security,
} from "./utils_endpoints";
import { isEmptyArray, isEmptyObj, isEmptyVal } from "./utils_types";
import { params, genericGet } from "./utils_params";
import { isEmailDeleted } from "./utils_user";
import { groupBy, sortAlphaAscByKey } from "./utils_processing";
import { getAppNameFromID } from "./utils_apps";
import { format } from "date-fns";

///////////////////////////////////////////////////////////////////////////
/////////////////////////// DISASSOCIATE UTILS ///////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Disassociates a list of target facilities from their parent community(s)
 * @param {String} token - Auth token
 * @param {Array} facilityIds - An array of facility IDs to be effected
 * @returns {Boolean} - Returns whether update was successful or not
 */
const disassociateFacilitiesFromParent = async (token, facilityIds = []) => {
	let url = currentEnv.base + facility.disassociate.facilityFromParent;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIds),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Disassociates a list of target facilities from their users. (ie. removes users from a list of facilities)
 * @param {String} token - Auth token
 * @param {Array} facilityIds - An array of facility IDs to be effected
 * @returns {Boolean} - Returns whether update was successful or not
 */
const disassociateFacilitiesFromUsers = async (token, facilityIds = []) => {
	let url = currentEnv.base + facility.disassociate.facilityFromUsers;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityIds),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

///////////////////////////////////////////////////////////////////////////
///////////////////////////// SCHEDULER UTILS /////////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Requests that the task scheduler/generator runs tonight at 1AM for a given facility.
 * @param {String} token - Auth token.
 * @param {String} facilityId - Facility guid string.
 * @returns {Boolean} - Returns whether scheduler was succesfully scheduled.
 */
const scheduleTaskGenerator = async (token, facilityId) => {
	let url = currentEnv.base + facility.scheduler.runTonight;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

///////////////////////////////////////////////////////////////////////////
/////////////////////// DISABLE-FACILITY-APP UTILS ///////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Toggle a facility's global app access state for a given application.
 * @param {String} token - Auth token
 * @param {Object} params - App access values.
 * @property {Number} params.appID - Target applicationId to update for.
 * @property {String} params.facilityID - Target facilityId to update for.
 * @property {Boolean} params.isAccessible - Determines whether to enable/disable app acces.
 * @returns {Boolean} - Returns success/failure state.
 */
const setFacilityAppAccess = async (token, params = {}) => {
	let url = currentEnv.base + facility.update.appAccess;
	url += "?" + new URLSearchParams({ applicationId: params.appID });
	url += "&" + new URLSearchParams({ facilityId: params.facilityID });
	url += "&" + new URLSearchParams({ accessible: params.isAccessible });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

///////////////////////////////////////////////////////////////////////////
/////////////////////// CHANGE-FACILITY-NAME UTILS ///////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Changes a given facility's community name.
 * @param {String} token - Auth token.
 * @param {Object} model - Custom/limited data structure for field updates.
 * @returns {Boolean} - Returns successful/failure state.
 */
const changeFacilityName = async (token, model) => {
	let url = currentEnv.base + facility.update.facilityName;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(model),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

///////////////////////////////////////////////////////////////////////////
/////////////////////// NEW FACILITY ACCOUNT UTILS ///////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Creates a new facility account
 * @param {String} token - Auth token
 * @param {Number} templateId - Template id for default LOC template to use for facility.
 * @param {Object} facilityRecord - 'FACILITY' table model
 * @returns {Object} - Returns object w/ success/failure state included.
 */
const createFacilityAccount = async (
	token,
	templateId = null,
	facilityRecord = {}
) => {
	let url = currentEnv.base + facility.create.newAccount;
	url += "?" + new URLSearchParams({ templateId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(facilityRecord),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Creates a new user admin account for a given facility.
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facilityID.
 * @param {Object} adminModel - 'NewUserAdmin' model data structure
 * @returns {Object} - Returns object w/ success/failure state
 */
const createAdminAccount = async (token, facilityId, adminModel = {}) => {
	let url = currentEnv.base + facility.create.newAdmin;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(adminModel),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

///////////////////////////////////////////////////////////////////////////
///////////////////////// FACILITY-REQUEST UTILS /////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * @description - Fetches a list of facilities a user has access to based off the user's email.
 * @param {String} token - Base64 encoded auth token.
 * @param {String} userEmail - A string user email.
 * @returns {Array} - Returns an array of custom facility records containing:
 * - "CommunityName": facility's community name as a string.
 * - "FacilityId": uid for facility
 * - "ParentFacilityId": uid for parent facility, if applicable.
 * - "Shifts": a list of 'AssessmentFacilityShift' records, IF AVAILABLE; IF NOT AVAILABLE, then returns default 'AssessmentShift' records.
 * - "Address":
 * 		- "Address.Street"
 * 		- "Address.State"
 * 		- "Address.City"
 * 		- "Address.Zip"
 */
const getFacilitiesByUserEmail = async (token, userEmail) => {
	let url = currentEnv.base + getFacilities.byUserEmail;
	url += "?" + new URLSearchParams({ userEmail: userEmail });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a facility's users (ADVUSER), logins, profiles, application access by app. (uses new user-infra)
 * @param {String} token - Security token.
 * @param {String} facilityId - A facility id.
 */
const getFacilityUserInfo = async (token, facilityId) => {
	let url = currentEnv.base + security.info.getFacilitySecurityInfo;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

const getFacilityRecord = async (token, facilityID) => {
	let url = currentEnv.base + facility.getFacilityData;
	url += "?" + new URLSearchParams({ guidFacility: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const getFacilityRelationRecord = async (token, facilityID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...genericGet.facilityWH });
	url += "&" + new URLSearchParams({ guidFacility: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// uses sql operators via client params
const getAllParentFacilities = async (token) => {
	let url = currentEnv.base + facility.getFacilityData;
	url += "?guidParentFacility=%7C5%7C10%7C0%7C9%7C%7Cfalse%7Cfalse";

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// fetches a child and parent record from 'ALADVWarehouse' db
const getFacilityAndParent = async (token, facilityID, parentID) => {
	const [childFacility, parentFacility] = await Promise.all([
		getFacilityRelationRecord(token, facilityID),
		getFacilityRelationRecord(token, parentID),
	]);

	return {
		childFacility,
		parentFacility,
	};
};

/**
 * Fetches all child facilities for a given parent.
 * @param {Strin} token - Auth token
 * @param {String} parentID - Parent facility guid string
 * @returns {Array} - Returns array of child facilities.
 */
const getChildCommunitiesFromParent = async (token, parentID) => {
	// let url = currentEnv.base + facility.getFacilityData;
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.facilityParams });
	url += "&" + new URLSearchParams({ guidParentFacility: parentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Check for user logins for a given facility within a certain range (ie last 30 days)
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid.
 * @param {Number} daysBack - Number of days in the past to check for user logins at this facility.
 * @returns {Object} - Returns object
 */
const getFacilityLoginsInRange = async (token, facilityId, daysBack = 30) => {
	let url = currentEnv.base + facility.activity.lastLogins;
	url += "?" + new URLSearchParams({ facilityId });
	url += "&" + new URLSearchParams({ daysBack });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// REASSIGN FACILITY UTILS //

///////////////////////////////////////////////////////////////////////////
///////////////////////// REASSIGN FACILITY UTILS /////////////////////////
///////////////////////////////////////////////////////////////////////////

/**
 * Reassign/move an existing child facility underneath a new parent facility.
 * @param {String} token - Auth token
 * @param {String} childFacilityId - Child Facility guid
 * @param {String} parentFacilityId - Target Parent facility guid
 * @param {Date} scheduleFor - A target date to execute the facility reassignment.
 * @returns {Boolean} - Returns whether the reassignment was successful.
 */
const moveChildToParentFacility = async (
	token,
	childFacilityId,
	parentFacilityId,
	scheduleFor = null
) => {
	let url = currentEnv.base + facility.moveFacility.childToParent;
	url +=
		"?" +
		new URLSearchParams({ childFacilityId, parentFacilityId, scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Reassign/move an independent facility underneath a parent facility.
 * @param {String} token - Auth token
 * @param {String} childFacilityId - Target independent facility guid (ie facility to be moved/reassigned)
 * @param {String} parentFacilityId - Target Parent facility guid
 * @param {Date} scheduleFor - A target date to execute the facility reassignment.
 * @returns {Boolean} - Returns whether the reassignment was successful.
 */
const moveIndependentToParentFacility = async (
	token,
	childFacilityId, // target independent
	parentFacilityId,
	scheduleFor = null
) => {
	let url = currentEnv.base + facility.moveFacility.independentToParent;
	url +=
		"?" +
		new URLSearchParams({ childFacilityId, parentFacilityId, scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Reassign/move a child facility to be an independent facility.
 * @param {String} token - Auth token
 * @param {String} childFacilityId - Target independent facility guid (ie facility to be moved/reassigned)
 * @param {Date} scheduleFor - A target date to execute the facility reassignment.
 * @returns {Boolean} - Returns whether the reassignment was successful.
 */
const moveChildToIndependentFacility = async (
	token,
	childFacilityId, // target independent
	scheduleFor = null
) => {
	let url = currentEnv.base + facility.moveFacility.childToIndependent;
	url += "?" + new URLSearchParams({ childFacilityId, scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Wrapper around all community association request utils with different action types.
 * @param {String} token - Auth token
 * @param {Object} vals - Object of target values
 * @param {String} vals.actionType - Defines which action to perform (ie. 'Move Independent Under Parent' etc)
 * @param {String} vals.childFacilityID - Target child facility guid.
 * @param {String} vals.parentFacilityID - Target parent facility guid.
 * @param {Date} vals.scheduleFor - Date to execute "facility move" (if null, then execute immediately).
 * @returns {Boolean} - Returns whether request was successful.
 */
const moveFacilityTo = async (token, vals = {}) => {
	const { actionType } = vals;
	switch (actionType) {
		case "Move Independent Under Parent": {
			const { childFacilityID, parentFacilityID, scheduleFor } = vals;
			const wasMoved = await moveIndependentToParentFacility(
				token,
				childFacilityID,
				parentFacilityID,
				scheduleFor
			);
			return wasMoved;
		}
		case "Move Community to New Parent": {
			const { childFacilityID, parentFacilityID, scheduleFor } = vals;
			const wasMoved = await moveChildToParentFacility(
				token,
				childFacilityID,
				parentFacilityID,
				scheduleFor
			);
			return wasMoved;
		}
		case "Make Community Independent": {
			const { childFacilityID, scheduleFor } = vals;
			const wasMoved = await moveChildToIndependentFacility(
				token,
				childFacilityID,
				scheduleFor
			);
			return wasMoved;
		}

		default:
			throw new Error(`❌ Ooops! Unrecognized action type:`, actionType);
	}
};

/**
 * Fetches ALL facilities that are established 'PARENT' communities.
 * @param {String} token - Auth token
 * @returns {Object[]} - Fetches ALL parent 'FACILITY' records
 */
const getParents = async (token) => {
	let url = currentEnv.base + facilityTypes.getParents;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// FIND PARENT FACILITIES

const processAndFormatParentsMap = (rawParents = []) => {
	const clientParents = processFacilityList(rawParents);
	const parentsMap = getParentsMap(clientParents);

	return parentsMap;
};
const processAndFormatCommunities = (allFacilities = []) => {
	const all = processFacilityList(allFacilities);
	const byID = groupBy(all, (x) => x.facilityID);
	const byName = groupBy(all, (x) => x.communityName);

	const facilitiesMap = {
		raw: [...all],
		byID: { ...byID },
		byName: { ...byName },
	};

	return facilitiesMap;
};

const getAndProcessParents = async (token) => {
	const rawParents = await getAllParentFacilities(token);
	const allParents = processFacilityList(rawParents);
	return allParents;
};
/**
 * Fetches & processes all parent facility records into a lists separated by 'id', 'name' and raw 'FACILITY' record.
 * @param {Object[]} allParents - AN array of all parent 'FACILITY' records
 * @returns {Object} - Returns an object/map containing a list of all parentIDs, all parent community names & their raw 'FACILITY' records.
 */
const getParentsMap = (allParents = []) => {
	const parentsMap = allParents.reduce(
		(map, parentRecord) => {
			const { facilityID, communityName: name } = parentRecord;
			// create object w/ 'ids', 'names' and 'raw' keys
			map = {
				ids: [...map.ids, facilityID],
				names: [...map.names, name],
				raw: [...map.raw, parentRecord],
			};

			return map;
		},
		{ ids: [], names: [], raw: [] }
	);

	return parentsMap;
};

const fetchAndProcessAllParents = async (token) => {
	// fetch & sort all parent records
	const rawParents = getParents(token);
	console.log("rawParents", rawParents);
	const sorted = sortAlphaAscByKey("CommunityName", rawParents);

	if (!isEmptyArray(rawParents)) {
		// create 'parents' map: ids, names, & raw records.
		const parentsMap = getParentsMap(sorted);
		return parentsMap;
	} else {
		return {
			ids: [],
			names: [],
			raw: [],
		};
	}
};

// FACILITY USER UTILS //

// consider including 'user maps' in return (ie 'loginMap', 'profileMap', 'advMap' etc.)
const fetchAndProcessUsers = async (token, facilityID) => {
	const userInfo = await getFacilityUserInfo(token, facilityID);

	const userData = processFacilityUsers(userInfo);

	return {
		userList: userData.users,
		userMaps: userData.maps,
	};
};

// creates map: 'LastName, FirstName': {...userData}
// ##TODOS:
// - Inject UserId and Email into each 'profile'
const createUserNamesMap = (userLogins = []) => {
	return userLogins.reduce((loginsMap, userLogin) => {
		const { LoginName: username, LoginNameByEmail: email } = userLogin;
		const name = isEmptyVal(username) ? email : username;

		if (!loginsMap[name]) {
			loginsMap[name] = { ...userLogin };
			return loginsMap;
		}
		return loginsMap;
	}, {});
};

const createLoginMap = (userLogins) => {
	return userLogins.reduce((loginsMap, login) => {
		const { UserLoginID } = login;
		if (!loginsMap[UserLoginID]) {
			loginsMap[UserLoginID] = { ...login };
			return loginsMap;
		}
		return loginsMap;
	}, {});
};
// creates 'FacilityUserProfile' map by profileID
const createProfileMap = (userProfiles = []) => {
	return userProfiles.reduce((profilesMap, profile) => {
		const { UserProfileID } = profile;
		if (!profilesMap[UserProfileID]) {
			profilesMap[UserProfileID] = { ...profile };
			return profilesMap;
		}
		return profilesMap;
	}, {});
};

const createAdvUserMap = (advUsers = []) => {
	return advUsers.reduce((advMap, user) => {
		const { guidUser } = user;
		if (!advMap[guidUser]) {
			advMap[guidUser] = { ...user };
			return advMap;
		}
		return advMap;
	}, {});
};

const getUserMaps = (securityInfo = {}) => {
	const {
		FacilityAdvUsers: advUsers,
		FacilityUserLogins: userLogins,
		FacilityUserProfiles: userProfiles,
	} = securityInfo;

	const advMap = createAdvUserMap(advUsers);
	const loginMap = createLoginMap(userLogins);
	const profileMap = createProfileMap(userProfiles);
	const namesMap = createUserNamesMap(userLogins);

	return {
		advMap,
		advUsers,
		loginMap,
		profileMap,
		namesMap,
	};
};

// includes data merged in from user security profile
const createUserMgmtObj = (user) => {
	const {
		guidUser,
		guidFacility,
		strFirstName,
		strLastName,
		strEmail,
		strPassword,
		strTitle,
		MedTechRestrictedAccess,
		bitFacilityAdministrator,
		alaAdmin,
		superUser,
		NoLongerAnEmployee,
		LastLoginDate,
	} = user;

	// client-formatted user object
	const newUser = {
		firstName: strFirstName,
		lastName: strLastName,
		email: strEmail,
		password: strPassword,
		title: strTitle,
		userID: guidUser,
		facilityID: guidFacility,
		isMedTechRestricted: MedTechRestrictedAccess,
		isRegionalAdmin: alaAdmin,
		isFacilityAdmin: bitFacilityAdministrator,
		isSuperUser: superUser,
		isFormerEmployee: NoLongerAnEmployee,
		phoneNumber: "",
		lockoutDate: user?.LockOutDate ?? null,
		isLockedOut: user?.IsLockOut ?? false,
		isSuspended: user?.IsSuspended ?? false,
		suspendDate: user?.SuspendedDate ?? null,
		isPwdResetByEmail: user?.IsPwdResetByEmail ?? false,
		isPwdResetByAdmin: user?.IsPwdResetByAdmin ?? true,
		isPwdResetByQuestions:
			user?.IsPwdResetByQuestions ?? !alaAdmin ? true : false,
		avatarID: user?.UserAvatarID ?? null,
		lastLogin: LastLoginDate,
	};
	return newUser;
};
const createAdvUserObj = (user) => {
	const {
		guidUser,
		guidFacility,
		strFirstName,
		strLastName,
		strEmail,
		strPassword,
		strTitle,
		MedTechRestrictedAccess,
		bitFacilityAdministrator,
		alaAdmin,
		superUser,
		NoLongerAnEmployee,
		LastLoginDate,
	} = user;
	const newUser = {
		firstName: strFirstName,
		lastName: strLastName,
		email: strEmail,
		password: strPassword,
		title: strTitle,
		userID: guidUser,
		facilityID: guidFacility,
		isMedTechRestricted: MedTechRestrictedAccess,
		isRegionalAdmin: alaAdmin,
		isFacilityAdmin: bitFacilityAdministrator,
		isSuperUser: superUser,
		isFormerEmployee: NoLongerAnEmployee,
		// no data - set to default
		phoneNumber: null,
		lockoutDate: null,
		isLockedOut: false,
		isSuspended: false,
		suspendDate: null,
		isPwdResetByEmail: false,
		isPwdResetByAdmin: true,
		isPwdResetByQuestions: false,
		avatarID: null,
		lastLogin: LastLoginDate,
	};
	return newUser;
};

const generateUser = (advUser, profileMap, loginMap) => {
	// if email is 'deleted' then remove 'profile' & 'login' data
	if (isEmailDeleted(advUser.strEmail)) {
		const newUser = createAdvUserObj(advUser);
		return newUser;
	} else {
		const { guidUser } = advUser;
		const login = loginMap?.[guidUser] ?? {};
		const profile = profileMap?.[login?.UserProfileID] ?? {};
		const newUser = createUserMgmtObj({
			...advUser,
			...profile,
			...login,
			isLockedOut: login?.IsLockOut ?? false,
		});
		return newUser;
	}
};

const processFacilityUsers = (data = {}) => {
	const maps = getUserMaps(data);
	const { advUsers, loginMap, profileMap } = maps;
	const users = advUsers.reduce((userList, advUser) => {
		const newUser = generateUser(advUser, profileMap, loginMap);
		userList = [...userList, newUser];
		return userList;
	}, []);

	return {
		users,
		maps,
	};
};

// FACILITY UTILS  //
// inits a new custom facility state object from an ALA object
const createFacilityObj = (record = {}) => {
	const {
		CommunityName,
		Address,
		FacilityId,
		guidFacility,
		guidParentFacility,
		ParentFacilityId,
		LocalTimeZoneID,
		LicenseNumber,
		ExecDirector,
		ALDirector,
		Shifts,
	} = record;

	const facilityID = isEmptyVal(FacilityId) ? guidFacility : FacilityId;
	const parentID = isEmptyVal(ParentFacilityId)
		? guidParentFacility
		: ParentFacilityId;

	const newRecord = {
		communityName: CommunityName,
		facilityID: facilityID,
		parentID: parentID,
		licenseNumber: LicenseNumber,
		execDirector: ExecDirector,
		alaDirector: ALDirector,
		timeZone: LocalTimeZoneID,
		address: {
			street: Address?.Street ?? "",
			city: Address?.City ?? "",
			state: Address?.State ?? "",
			zip: Address?.Zip ?? "",
			suiteNumber: Address?.Street2 ?? Address?.Address2 ?? "",
		},
		shifts: Shifts,
	};
	return { ...newRecord };
};
// processes raw facility record list from 'FACILITY' table
const createRawFacilityObj = (record = {}) => {
	const {
		CommunityName,
		guidFacility,
		guidParentFacility,
		Address,
		Address2,
		City,
		State,
		Zip,
		LocalTimeZoneID,
		ALDirector,
		ExecDirector,
		LicenseNumber,
	} = record;

	const newRecord = {
		communityName: CommunityName,
		facilityID: guidFacility,
		parentID: guidParentFacility,
		licenseNumber: LicenseNumber,
		execDirector: ExecDirector,
		alaDirector: ALDirector,
		timeZone: LocalTimeZoneID,
		address: {
			street: Address ?? "",
			city: City ?? "",
			state: State ?? "",
			zip: Zip ?? "",
			suiteNumber: Address2 ?? "",
		},
		shifts: [],
	};
	return { ...newRecord };
};

// formats/normalizes user's facility access list for client-formatting
const processFacilityList = (facilityList = []) => {
	if (isEmptyArray(facilityList)) return [];

	return facilityList.reduce((newList, facility) => {
		const newRecord = createFacilityObj(facility);
		newList = [...newList, newRecord];
		return newList;
	}, []);
};

// processes raw facility record list from 'FACILITY' table
const processRawFacilityList = (facilityList = []) => {
	if (isEmptyArray(facilityList)) return [];

	return facilityList.reduce((newList, facility) => {
		const newRecord = createRawFacilityObj(facility);
		newList = [...newList, newRecord];
		return newList;
	}, []);
};

/**
 * @description - Finds matching facility record from a 'facilityID'.
 * @param {String} id - String-form facility ID.
 * @param {Array} facilities - An array of facilities from the 'currentUser' object.
 * @returns {Object} - Returns the matching facility record (ie 'FACILITY' record).
 */
const matchFacilityByID = (id, facilities = []) => {
	return facilities.reduce((match, cur) => {
		const curID = cur?.facilityID ?? cur?.FacilityId ?? cur?.FacilityID;
		if (curID === id) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};

const matchFacilityByName = (name, facilities = []) => {
	return facilities.reduce((match, cur) => {
		if (cur?.communityName === name) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};

const sortParents = (parents) => {
	if (isEmptyArray(parents) || !parents) return [];

	const sorted = parents.sort((a, b) =>
		a.communityName.localeCompare(b.communityName)
	);
	return sorted.map((x) => x.communityName);
};

// ACTIVITY LOGIN FORMATTERS AND UTILS //
// processes/formats an 'Activity record' for a client-formats
const processActivityLogin = (login) => {
	const {
		ApplicationId: appID,
		FacilityId: facilityID,
		FirstName: firstName,
		LastName: lastName,
		IsLegacyUser: isLegacyUser,
		LastActivityDate: lastActivityDate,
		LoginName: username,
		LoginNameByEmail: email,
		UserLoginID: userLoginID,
		IsActiveUser: isActiveUser,
		LoginDate: lastLoginDate,
	} = login;

	return {
		appID,
		facilityID,
		firstName,
		lastName,
		isLegacyUser,
		isActiveUser,
		lastActivityDate,
		username,
		email,
		userLoginID,
		lastLoginDate,
	};
};
// formats a list of activity login records (ie last logins)
const formatActivityLogins = (logins = []) => {
	return logins.map((login) => processActivityLogin(login));
};
// text description of user activity per app
const getAppActivitySummary = (loginsByApp = {}) => {
	if (isEmptyObj(loginsByApp)) return {};
	const keys = Object.keys(loginsByApp);
	const lengths = keys.reduce((loginsMap, key) => {
		const entries = loginsByApp[key];
		const length = entries?.length ?? 0;
		if (!loginsMap[key]) {
			loginsMap[key] = `There were ${length} logins in the ${key} app.`;
			return loginsMap;
		}
		return loginsMap;
	}, {});
	return lengths;
};
// text description of user activity per date/day
const getDateActivitySummary = (loginsByDate = {}) => {
	if (isEmptyObj(loginsByDate)) return {};
	const keys = Object.keys(loginsByDate);
	const lengths = keys.reduce((loginsMap, key) => {
		const entries = loginsByDate[key];
		loginsMap = `There were logins on ${keys?.length} different dates.`;
		return loginsMap;
	}, {});
	return lengths;
};
// text description of the number of users who've logged in recently
const getUserActivitySummary = (loginsByUser = {}) => {
	if (isEmptyObj(loginsByUser)) return {};
	const length = loginsByUser?.length ?? 0;
	return `There were ${length} different users who logged in recently.`;
};
const getLoginSummary = (loginsByUser = []) => {
	let summary = `Recent user logins (${loginsByUser?.length}): `;
	const length = loginsByUser?.length;
	loginsByUser.map((user, idx) => {
		if (idx + 1 === length) {
			summary += `${user}.`;
			return user;
		}
		summary += `${user}, `;
		return user;
	});
	return summary;
};
// human-readable text descriptions of login activity for the past XX days
const getActivitySummary = (loginGroups = {}) => {
	const { loginsByApp, loginsByDate, loginsByUser } = loginGroups;
	// get keys from each object group
	const loginSummary = getAppActivitySummary(loginsByApp);
	const dateSummary = getDateActivitySummary(loginsByDate);
	const userSummary = getUserActivitySummary(loginsByUser);
	const userList = getLoginSummary(loginsByUser);

	return {
		loginSummary,
		dateSummary,
		userSummary,
		userList,
	};
};

// extracts 'LoginName' or "LoginNameByEmail" from login records
const getLoginUserList = (logins = []) => {
	return logins.map((login) => {
		const username = isEmptyVal(login.username)
			? login?.email
			: login?.username;

		return username;
	});
};

// should return:
// - List of users logged in
// - List of apps logged into
// - List of dates of logins
// returns object
const processLastLogins = (logins = []) => {
	if (isEmptyArray(logins)) return `No activity found.`;
	const clientRecords = formatActivityLogins(logins);

	const loginsByApp = groupBy(clientRecords, (x) => {
		const appName = getAppNameFromID(x?.appID);
		return appName;
	});
	const loginsByDate = groupBy(clientRecords, (x) => {
		return format(x?.lastActivityDate, "YYYY-MM-DD");
	});
	const loginsByUser = getLoginUserList(clientRecords);
	// new obj
	const groupsObj = { loginsByApp, loginsByDate, loginsByUser };
	const activitySummary = getActivitySummary(groupsObj);

	return { ...groupsObj, activitySummary };
};

const getFacilityNames = (facilities = []) => {
	return sortAlphaAscByKey("communityName", facilities).map(
		({ communityName }) => communityName
	);
};

// Extracts the matching list of facility ids from a list of facility names
const getFacilityIDsFromNames = (selectedNames = [], facilities = []) => {
	// create facility records map by 'communityName'
	const namesMap = facilities.reduce((mapByName, facility) => {
		const name = facility?.communityName ?? facility?.CommunityName;

		if (!mapByName[name]) {
			mapByName[name] = { ...facility };
			return mapByName;
		}
		return mapByName;
	}, {});

	// Iterate thru names and get matching facilityID
	const listOfIDs = selectedNames.reduce((ids, name) => {
		const facilityRecord = namesMap?.[name] ?? {};
		const facilityID = facilityRecord?.facilityID ?? facilityRecord?.FacilityId;
		ids = [...ids, facilityID];

		return ids;
	}, []);

	return listOfIDs;
};

// facility disassociate utils
export { disassociateFacilitiesFromParent, disassociateFacilitiesFromUsers };

export {
	getFacilityUserInfo,
	getFacilitiesByUserEmail,
	getChildCommunitiesFromParent,
	getFacilityRelationRecord,
	getFacilityAndParent,
	getFacilityRecord,
	getAllParentFacilities,
	getAndProcessParents,
	createFacilityAccount,
	createAdminAccount,
	// user activity for facility
	getFacilityLoginsInRange,
	// change facility name
	changeFacilityName,
	// toggle app access for entire facility for an app
	setFacilityAppAccess,
	// task scheduler/generator
	scheduleTaskGenerator,
};
// moving facilitys assignment(s)
export {
	moveChildToParentFacility,
	moveIndependentToParentFacility,
	moveChildToIndependentFacility,
	// wrapper around above utils
	moveFacilityTo,
};

export {
	createFacilityObj,
	createRawFacilityObj,
	processRawFacilityList,
	processFacilityList,
	processFacilityUsers,
	// prceosses
	fetchAndProcessUsers,
};
export { matchFacilityByID, matchFacilityByName };

// parents, child & indy utils
export {
	sortParents,
	fetchAndProcessAllParents,
	getParents,
	getParentsMap,
	processAndFormatParentsMap,
	processAndFormatCommunities,
	// process & extract ONLY facility names
	getFacilityNames,
	getFacilityIDsFromNames,
};

// formatters for Activity records
export { processActivityLogin, formatActivityLogins, processLastLogins };
