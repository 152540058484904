import React, { useState } from "react";
import styles from "../css/views/FlushCacheView.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import FlushCache from "../components/sso/FlushCache";
import MaintenanceMode from "../components/app/MaintenanceMode";
// maintenance
import { PAGE_OUTAGES as outages } from "../helpers/utils_maintenanceMode";
import { clearCache } from "../helpers/utils_sso";
import { isEmptyVal } from "../helpers/utils_types";

const FlushCacheView = ({ currentUser, dispatchAlert }) => {
	const [cacheToFlush, setCacheToFlush] = useState("");
	// const [cacheNames, setCacheNames] = useState([])

	const handleSelection = (name, val) => {
		setCacheToFlush(val);
	};

	// executes 'flush' & clears 'cacheToFlush' state
	const flushCache = async () => {
		if (isEmptyVal(cacheToFlush)) return;

		const { token } = currentUser;
		const wasFlushed = await clearCache(token, cacheToFlush);
		console.log("wasFlushed", wasFlushed);

		if (wasFlushed) {
			setCacheToFlush("");
			return dispatchAlert("SUCCESS", {
				heading: `Cache was flushed!`,
				subheading: `"${cacheToFlush}" was flushed successfully.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!!`,
				subheading: `Cache failed to flush!!!`,
			});
		}
	};

	return (
		<div className={styles.FlushCacheView}>
			<header className={styles.FlushCacheView_header}>
				<h2 className={styles.FlushCacheView_header_title}>
					Flush a Specific Cache
				</h2>
			</header>
			<div className={styles.FlushCacheView_main}>
				<MaintenanceMode
					disableMaintenanceMode={true}
					disableNav={false}
					title={outages.flushCache.title}
					desc={outages.flushCache.desc}
				/>
				<FlushCache
					key={`FLUSH-CACHE`}
					cacheToFlush={cacheToFlush}
					handleSelection={handleSelection}
					flushCache={flushCache}
				/>
			</div>
		</div>
	);
};

export default FlushCacheView;

FlushCacheView.defaultProps = {};

FlushCacheView.propTypes = {};
