import React from "react";
import styles from "../css/Main.module.scss";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { envLogger } from "../helpers/utils_processing";
import { currentEnvName } from "../helpers/utils_env";
// components

// logs environment
envLogger(currentEnvName, process.env.NODE_ENV, false);

const Main = ({ children }) => {
	return <div className={styles.Main}>{children}</div>;
};

export default withRouter(Main);

Main.defaultProps = {};

Main.propTypes = {
	children: PropTypes.any,
};
