import React from "react";
import styles from "../css/views/ToggleSSO.module.scss";
import { PropTypes } from "prop-types";
import SSOController from "../components/sso/SSOController";

const ToggleSSO = ({ currentUser, dispatchAlert }) => {
	return (
		<div className={styles.ToggleSSO}>
			<div className={styles.ToggleSSO_header}>
				<h1 className={styles.ToggleSSO_header_title}>
					Change ALA's SSO Redirect Logic
				</h1>
				<h2 className={styles.ToggleSSO_header_desc}>
					Warning!! Updating this setting will change whether the Login page of
					the Legacy application is available or not.
				</h2>
				<p className={styles.ToggleSSO_header_altDesc}>
					NOTE: After changes are made please logout and back in to confirm
					results.
				</p>
			</div>
			<div className={styles.ToggleSSO_main}>
				<SSOController
					currentUser={currentUser}
					dispatchAlert={dispatchAlert}
				/>
			</div>
		</div>
	);
};

export default ToggleSSO;

ToggleSSO.defaultProps = {};

ToggleSSO.propTypes = {};
