import React from "react";
import styles from "../../css/facility/ErrorIndicator.module.scss";
import sprite from "../../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";

const ErrorIndicator = ({ children }) => {
	return (
		<div className={styles.ErrorIndicator}>
			<div className={styles.ErrorIndicator_top}>
				<div className={styles.ErrorIndicator_top_iconWrapper}>
					<svg className={styles.ErrorIndicator_top_iconWrapper_icon}>
						<use xlinkHref={`${sprite}#icon-warning1`}></use>
					</svg>
				</div>
				<div className={styles.ErrorIndicator_top_title}>Error!</div>
			</div>
			<div className={styles.ErrorIndicator_top}>{children}</div>
		</div>
	);
};

export default ErrorIndicator;

ErrorIndicator.defaultProps = {};

ErrorIndicator.propTypes = {
	children: PropTypes.any,
};
