import React, { useState, useEffect } from "react";
import styles from "../../css/facility/FacilityInfo.module.scss";
import sprite from "../../assets/icons/us-states.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { timezones } from "../../helpers/utils_dates";
import { phoneReg } from "../../helpers/utils_validation";
// components
import TextInput from "../shared/TextInput";
import PhoneInput from "../shared/PhoneInput";
import ButtonSM from "../shared/ButtonSM";
import EmailValidator from "../forms/EmailValidator";
import Divider from "../forms/Divider";
import CustomSelector from "../shared/CustomSelector";
import CustomCheckbox from "../shared/CustomCheckbox";
import FacilityAdmin from "./FacilityAdmin";
import LOCTemplateSelector from "../loc/LOCTemplateSelector";

// ##TODOS:
// - Add 'New Community' fields ✓
//    - Community name ✓
//    - Address ✓
//    - Contact(s) ✓
//    - Admin info ✓
// 		- Update 'Parent FacilityID' to be custom dropdown selector
// - Add 'New Community' settings: ✓
//    - Meds, contacts, assessments, ala admin etc ✓
// - Add 'Make User an Admin' UI
// - Add 'Add User to Regional Facility' UI

const customCSS = {
	name: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	address: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	city: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	state: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	zip: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	phone: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	fax: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	email: {
		marginBottom: "2rem",
	},
	save: {
		padding: ".7rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		marginLeft: ".5rem",
	},
	cancel: {
		backgroundColor: "transparent",
		color: red[600],
		padding: ".7rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
	adminPwd: {
		backgroundColor: "#ffffff",
	},
	timezone: {
		width: "35rem",
	},
	parentFacilityID: {
		width: "35rem",
	},
	selector: {
		width: "35rem",
	},
};

// checks if a 'touched' field was also filled-in
const wasTouchedAndFilled = (keyName, formState = {}) => {
	const { touched, values } = formState;
	const wasTouched = touched[keyName] ?? false;
	const wasFilled = !isEmptyVal(values[keyName]) ?? false;

	if (keyName === "phone" || keyName === "fax") {
		// runs validation for 'phone number'
		const isValidPhone = phoneReg.test(values?.[keyName] ?? "");
		return wasTouched && wasFilled && isValidPhone;
	} else {
		// handles fields besides 'phoneNumber'
		return wasTouched && wasFilled;
	}
};

const enableAccountBtn = (formState = {}) => {
	const { values, touched } = formState;
	const {
		communityName,
		street,
		suiteNumber,
		city,
		state,
		zip,
		phoneNumber,
		email,
		faxNumber,
		execDirector,
		alaDirector,
		licenseNumber,
		localTimeZone,
		yardiNumber,
		parentFacilityID,
		// admin details
		adminEmail,
		adminFirstName,
		adminLastName,
		adminPassword,
		// community settings
		enableMeds,
		enableContacts,
		enableAssessments,
		enableALAAdmin,
	} = values;

	const isFilled =
		!isEmptyVal(street) &&
		!isEmptyVal(city) &&
		!isEmptyVal(state) &&
		!isEmptyVal(zip) &&
		!isEmptyVal(localTimeZone);
	// !isEmptyVal(phoneNumber) && // (optional)
	// !isEmptyVal(faxNumber) && // (optional)

	return isFilled;
};

const FacilityInfo = ({
	formState,
	vals,
	currentFacility,
	handleChange,
	handleCheckbox,
	handleCustomFields,
	handleParentSelection,
	saveChanges,
	cancelChanges,
	copySelection,
	allFacilities = [],
	parentsMap = {},
	// template handlers & state
	selectTemplate,
	selectedTemplate,
	parentTemplates,
}) => {
	const [wasCopied, setWasCopied] = useState(false);

	const handleCopy = (fieldName) => {
		setWasCopied(true);
		copySelection(fieldName);
	};

	// resets 'wasCopied' state after copying
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let copiedID;
		if (wasCopied) {
			copiedID = setTimeout(() => {
				setWasCopied(false);
			}, 2000);
		}

		return () => {
			isMounted = false;
			clearTimeout(copiedID);
		};
	}, [wasCopied]);

	return (
		<div className={styles.EditFacilityInfo}>
			<header className={styles.EditFacilityInfo_header}>
				<div className={styles.EditFacilityInfo_header_badge}>
					<svg className={styles.EditFacilityInfo_header_badge_icon}>
						{/* <use
							xlinkHref={`${sprite}#icon-state-${
								currentFacility?.state ?? "AZ"
							}`}
						></use> */}
						<use xlinkHref={`${sprite2}#icon-location_city`}></use>
					</svg>
				</div>
			</header>

			<section className={styles.EditFacilityInfo_form}>
				{/* HIDDEN INPUT FOR DISABLING AUTO-COMPLETE */}
				<input
					type="text"
					name="hidden"
					id="hidden"
					style={{ display: "none" }}
					autoComplete="off"
				/>

				{/* COMMUNITY NAME */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						key="communityName"
						label="Community Name"
						name="communityName"
						id="communityName"
						val={vals?.communityName}
						handleChange={handleChange}
						customStyles={customCSS.name}
						required={true}
						addRequiredFlag={true}
					/>
				</div>
				{/* ADDRESS */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						key="streetAddress"
						label="Address"
						name="street"
						id="street"
						val={vals?.street}
						handleChange={handleChange}
						customStyles={customCSS.address}
						required={true}
						addRequiredFlag={true}
					/>
					<TextInput
						label="Suite/Bldg. #"
						name="suiteNumber"
						id="suiteNumber"
						placeholder="Address2..."
						val={vals?.suiteNumber}
						handleChange={handleChange}
						customStyles={customCSS.address}
					/>
					{/* CITY => STATE => ZIP */}
					<div className={styles.EditFacilityInfo_form_section_multiInput}>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_city}
						>
							<TextInput
								label="City"
								name="city"
								id="city"
								val={vals?.city}
								handleChange={handleChange}
								customStyles={customCSS.city}
								required={true}
								addRequiredFlag={true}
							/>
						</div>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_state}
						>
							<TextInput
								label="State"
								name="state"
								id="state"
								val={vals?.state}
								handleChange={handleChange}
								customStyles={customCSS.state}
								required={true}
								addRequiredFlag={true}
							/>
						</div>
						<div
							className={styles.EditFacilityInfo_form_section_multiInput_zip}
						>
							<TextInput
								label="Zip"
								name="zip"
								id="zip"
								val={vals?.zip}
								handleChange={handleChange}
								customStyles={customCSS.zip}
								required={true}
								addRequiredFlag={true}
							/>
						</div>
					</div>
				</div>
				{/* PHONE NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<PhoneInput
						label="Phone Number"
						name="phoneNumber"
						id="phoneNumber"
						val={vals?.phoneNumber}
						handlePhone={handleCustomFields}
						placeholder="XXX-XXX-XXXX"
						customStyles={customCSS.phone}
					/>
				</div>
				{/* FAX NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<PhoneInput
						label="Fax"
						name="faxNumber"
						id="faxNumber"
						val={vals?.faxNumber}
						handlePhone={handleCustomFields}
						placeholder="XXX-XXX-XXXX"
						customStyles={customCSS.fax}
					/>
				</div>
				{/* EMAIL ADDRESS */}
				<div className={styles.EditFacilityInfo_form_section}>
					<EmailValidator
						label="Email"
						name="email"
						id="email"
						initialVal={vals?.email}
						handleChange={handleChange}
						placeholder="Enter email..."
						customStyles={customCSS.email}
					/>
				</div>
				<Divider />
				{/* EXECUTIVE DIRECTOR */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						key="execDirector"
						label="Executive Director"
						name="execDirector"
						id="execDirector"
						val={vals?.execDirector}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="Exec director's name..."
					/>
				</div>
				{/* AL DIRECTOR */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						key="alaDirector"
						label="AL Director"
						name="alaDirector"
						id="alaDirector"
						val={vals?.alaDirector}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="ALA director's name..."
					/>
				</div>
				<Divider />
				{/* LICENSE NUMBER */}
				<div className={styles.EditFacilityInfo_form_section}>
					<TextInput
						key="licenseNumber"
						label="License"
						name="licenseNumber"
						id="licenseNumber"
						val={vals?.licenseNumber}
						handleChange={handleChange}
						customStyles={customCSS.name}
						placeholder="Community license..."
					/>
				</div>

				{/* FACILITY TIMEZONE  */}
				<div
					className={styles.EditFacilityInfo_form_section}
					style={{ marginBottom: "2rem" }}
				>
					<CustomSelector
						name="localTimeZone"
						id="localTimeZone"
						label="Local Time Zone"
						selection={vals.localTimeZone}
						setSelection={handleCustomFields}
						placeholder="Select local timezone..."
						options={timezones}
						customStyles={customCSS.timezone}
						primaryKeyName="name"
						secondaryKeyName="zone"
						isRequired={true}
					/>
				</div>

				{/* PARENT FACILITY ID */}
				<div className={styles.EditFacilityInfo_form_section}>
					<CustomSelector
						key="parentFacility"
						label="Parent Facility"
						name="parentFacility"
						id="parentFacility"
						selection={vals?.parentFacility}
						// setSelection={handleCustomFields}
						setSelection={handleParentSelection}
						customStyles={customCSS.parentFacilityID}
						placeholder="Select parent..."
						primaryKeyName="communityName"
						secondaryKeyName="facilityID"
						// options={allFacilities}
						options={parentsMap.raw}
						isRequired={false}
					/>
				</div>

				<Divider />

				{/* PARENT LOC TEMPLATES SELECTOR */}
				<div className={styles.EditFacilityInfo_form_section}>
					<LOCTemplateSelector
						key={`LOC-TEMPLATES-${parentTemplates?.names?.length}`}
						label="Select Care Level Template"
						selectedTemplate={selectedTemplate}
						handleTemplate={selectTemplate}
						templateNames={parentTemplates?.names}
						currentFacility={currentFacility}
						customStyles={customCSS.selector}
					/>
				</div>

				{/* <Divider /> */}

				{/* COMMUNITY SETTINGS (HEADER) */}
				<div
					className={styles.EditFacilityInfo_form_header}
					style={{ marginTop: "4rem" }}
				>
					<div className={styles.EditFacilityInfo_form_header_title}>
						Community Settings
					</div>
				</div>
				{/* COMMUNITY SETTINGS */}
				<div className={styles.EditFacilityInfo_form_section}>
					<div
						className={styles.EditFacilityInfo_form_section_options}
						style={{ marginBottom: "2rem" }}
					>
						<CustomCheckbox
							name="enableMeds"
							id="enableMeds"
							label="Medications"
							val={vals.enableMeds}
							handleCheckbox={handleCheckbox}
						/>
						<CustomCheckbox
							name="enableContacts"
							id="enableContacts"
							label="Contacts"
							val={vals.enableContacts}
							handleCheckbox={handleCheckbox}
						/>
						<CustomCheckbox
							name="enableAssessments"
							id="enableAssessments"
							label="Assessments"
							val={vals.enableAssessments}
							handleCheckbox={handleCheckbox}
						/>
						<CustomCheckbox
							name="enableALAAdmin"
							id="enableALAAdmin"
							label="ALA Admin"
							val={vals.enableALAAdmin}
							handleCheckbox={handleCheckbox}
						/>
					</div>
					{/* ACTIONS */}
					<div className={styles.EditFacilityInfo_form_actions}>
						<ButtonSM
							isDisabled={false}
							handleClick={cancelChanges}
							customStyles={customCSS.cancel}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							isDisabled={!enableAccountBtn(formState)}
							handleClick={saveChanges}
							customStyles={customCSS.save}
						>
							Create Facility Account
						</ButtonSM>
					</div>
				</div>

				<Divider />
			</section>
		</div>
	);
};

export default FacilityInfo;

FacilityInfo.defaultProps = {};

FacilityInfo.propTypes = {
	formState: PropTypes.object,
	vals: PropTypes.object.isRequired,
	currentFacility: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleCheckbox: PropTypes.func.isRequired,
	handleCustomFields: PropTypes.func.isRequired,
	saveChanges: PropTypes.func,
	cancelChanges: PropTypes.func,
	copySelection: PropTypes.func,
};
