import React, { useState } from "react";
import styles from "../../css/facility/MakeIndependentFacility.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import CreateAdmin from "../admin/CreateAdmin";
import RemoveUsers from "../user/RemoveUsers";
import {
	matchFacilityByName,
	moveFacilityTo,
} from "../../helpers/utils_facility";
import CustomSelect from "../shared/CustomSelect";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - Option #3: Make a facility an independent

const customCSS = {
	target: {
		backgroundColor: "#ffffff",
		width: "35rem",
	},
	cancel: {
		padding: "1rem 1.8rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const MakeIndependentFacility = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const [usersToRemove, setUsersToRemove] = useState([]);
	const { formState, handleChange, handleReset } = useForm({
		newAdminEmail: "",
		newAdminFirstName: "",
		newAdminLastName: "",
		newAdminPassword: "",
	});
	const { values } = formState;

	const saveFacilityChanges = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		// fire off request (move child to independent API)
		const wasSaved = await moveFacilityTo(token, {
			actionType: "Make Community Independent",
			childFacilityID: facilityID,
			scheduleFor: null,
		});

		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `Child was moved to new parent!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred`,
				subheading: `Please try again later.`,
			});
		}
	};

	const cancelChanges = (e) => {
		handleReset(e);
	};

	const removeUsers = async () => {
		console.log(`Removing ${usersToRemove?.length} users!`);
	};

	return (
		<div className={styles.MakeIndependentFacility}>
			<section className={styles.MakeIndependentFacility_actions}>
				<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={isEmptyVal(currentFacility.facilityID)}
					handleClick={saveFacilityChanges}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</section>
		</div>
	);
};

export default MakeIndependentFacility;

MakeIndependentFacility.defaultProps = {};

MakeIndependentFacility.propTypes = {};
