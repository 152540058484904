import { residentData, generic, facility, uploads } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { matchFacilityByID } from "./utils_facility";
import { getFormData } from "./utils_files";
import { genericGet as genericGetParams } from "./utils_params";
import { replaceNullWithMsg } from "./utils_processing";
import { isEmptyVal, isEmptyObj } from "./utils_types";

const getResidentsByFacility = async (token, facilityID) => {
	let url = currentEnv.base + facility.getResidents;
	url += "?facilityId=" + facilityID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		return response;
	} catch (err) {
		return err.message;
	}
};

// RESIDENT REQUEST UTILS //
const getResidentProfile = async (token, residentID) => {
	let url = currentEnv.base + residentData.getProfile;
	url += "?" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches a resident record from the 'RESIDENTS' table
const getResidentRecord = async (token, residentID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...genericGetParams.resident });
	url += "&" + new URLSearchParams({ ID: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Requests transfer of a resident to a target facility on a specific date.
 * @param {String} token - Auth token
 * @param {Number} residentId - Numeric resident ID
 * @param {String} targetFacilityId - Target facility guid for resident to be transferred to.
 * @param {Date} targetDate - Date that resident should be transferred. If 'null' transfer immediately.
 * @returns {Object} - Returns whether transfer was successful/failed or pending for future date.
 */
const transferResidentToFacility = async (
	token,
	residentId,
	targetFacilityId,
	scheduleFor = null
) => {
	let url = currentEnv.base + residentData.transferResident.toFacility;
	url += "?" + new URLSearchParams({ residentId }); // resident ID to transfer
	url += "&" + new URLSearchParams({ facilityId: targetFacilityId }); // target facility ID to be transferred to
	url += "&" + new URLSearchParams({ scheduleFor }); // target facility ID to be transferred to

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Converts a client-uploaded file to FormData to uploaded to the server.
 * NOTE: You MUST NOT declare a 'Content-Type'. The browser will auto-set one for you!!!
 * @param {String} token - Auth token
 * @param {String} fileName - String-form filename
 * @param {File} fileUpload - File to be uploaded server-side
 * @returns {Boolean} - Returns boolean of successful status between: 200-299
 */
const uploadResidentFile = async (token, fileName, fileUpload) => {
	let url = currentEnv.base + uploads.uploadResidentFile;
	url += "?" + new URLSearchParams({ id: fileName });

	// generate 'FormData()' instance w/ file name appended
	const fileData = new FormData();
	fileData.append("file", fileUpload);

	try {
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				// "Content-Type": "multipart/form-data",
			},
			body: fileData,
		});
		return response?.ok;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const uploadResidentFileXHR = (token, fileName, fileUpload) => {
	let url = currentEnv.base + uploads.uploadResidentFile;
	url += "?" + new URLSearchParams({ id: fileName });

	// generate 'FormData()' instance w/ file name appended
	const fileData = new FormData();
	fileData.append("file", fileUpload);

	const xhr = new XMLHttpRequest();
	xhr.open("PUT", url);
	// set security token
	// xhr.setRequestHeader("Content-Type", "multipart/form-data");
	xhr.setRequestHeader(
		"Authorization",
		"Basic " + btoa(currentEnv.user + ":" + currentEnv.password)
	);
	xhr.setRequestHeader("SecurityToken", token);
	xhr.timeout = 2000;

	xhr.send(fileData);
};

// PROCESSING RESIDENT DATA UTILS //

const processResidentProfile = (profile = {}) => {
	const resident = profile?.Residents?.[0];
	const name = getResidentName(resident);

	return {
		residentName: name,
		residentID: resident?.ID,
		facilityID: resident?.FacilityID,
		unitNumber: resident?.RoomNum,
		unitType: resident?.FloorUnit,
		status: resident?.ResidentType,
		yardiNumber: resident?.YardiNumber,
		phoneNumber: resident?.AptPhone,
		admissionDate: resident?.DateAdmission,
		dob: resident?.DateOfBirth,
		insuranceNumber: resident?.InsuranceNumber,
		insuranceProvider: resident?.InsuranceProvider,
		sex: resident?.Sex,
	};
};

// RESIDENT DATA UTILS //

const getResidentName = (currentResident = {}) => {
	if (isEmptyObj(currentResident)) return `NA`;

	const first = currentResident?.firstName ?? currentResident?.FirstName;
	const last = currentResident?.lastName ?? currentResident?.LastName;

	return `${last}, ${first}`;
};

// formats floor unit
const getResidentUnit = (currentResident = {}) => {
	if (isEmptyVal(currentResident?.ResidentID)) return "Load Resident";
	const { FloorUnit } = currentResident;
	return replaceNullWithMsg(FloorUnit, "NA");
};
const getResidentRoomNum = (currentResident = {}) => {
	if (isEmptyVal(currentResident?.ResidentID)) return "Load Resident";
	const { RoomNum } = currentResident;
	return replaceNullWithMsg(RoomNum, "NA");
};

const getResidentsFacilityFromID = (id, allFacilities = []) => {
	const match = matchFacilityByID(id, allFacilities);
	return match;
};

// request utils
export {
	getResidentProfile,
	getResidentRecord,
	getResidentsByFacility,
	uploadResidentFile,
	uploadResidentFileXHR,
};

// processing utils
export { processResidentProfile };

export {
	getResidentName,
	getResidentRoomNum,
	getResidentUnit,
	getResidentsFacilityFromID,
	// new API(s)
	transferResidentToFacility,
};
