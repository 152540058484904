import React from "react";
import styles from "../css/views/TransferResidentView.module.scss";
import { PropTypes } from "prop-types";
import ResidentSummary from "../components/resident/ResidentSummary";
import TransferResident from "../components/resident/TransferResident";

// REQUIREMENTS:
// 	- Transfer Resident to Community:
// 			- Dropdown for resident selection
// 			- UI for displaying selected resident's information (residentID, name, current parent, unit etc.)
// 			- Dropdown for parent facility selection:
// 					- Once a parent facility is selected then all it's children should be shown available for transferring to

const TransferResidentView = ({
	currentFacility = {},
	currentUser = {},
	globalState = {},
	dispatchAlert,
}) => {
	return (
		<div className={styles.TransferResidentView}>
			<TransferResident
				currentUser={currentUser}
				dispatchAlert={dispatchAlert}
			/>
		</div>
	);
};

export default TransferResidentView;

TransferResidentView.defaultProps = {};

TransferResidentView.propTypes = {};
