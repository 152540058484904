import React, { useState, useEffect } from "react";
import styles from "../../css/resident/TransferResident.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getResidentProfile,
	getResidentsFacilityFromID,
	processResidentProfile,
	transferResidentToFacility,
} from "../../helpers/utils_resident";
import {
	matchFacilityByID,
	matchFacilityByName,
} from "../../helpers/utils_facility";
import { red } from "../../helpers/utils_styles";
import { format, isToday } from "date-fns";
// components
import ResidentSummary from "./ResidentSummary";
import NumberInput from "../shared/NumberInput";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";
import DatePickerSM from "../shared/DatePickerSM";
import ResidentTarget from "./ResidentTarget";

// REQUIREMENTS:
// - Add UI for selecting a target date:
// 		- This date indicates *when* the resident should be transferred.

const customCSS = {
	resident: {
		width: "35rem",
		backgroundColor: "#ffffff",
	},
	facility: {
		width: "35rem",
		backgroundColor: "#ffffff",
		// marginBottom: "2rem",
	},
	load: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
	},
	move: {
		width: "max-content",
		minWidth: "max-content",
		padding: ".9rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: "1rem",
		backgroundColor: red[600],
	},
	target: {
		width: "35rem",
		// marginTop: "2rem",
	},
};

const getResidentName = (currentResident) => {
	const name = currentResident?.residentName;
	const split = name?.split(", ");
	const last = split?.[0] ?? "";
	const first = split?.[1] ?? "";
	return `${first} ${last} (${currentResident?.residentID})`;
};

const TransferResident = ({
	currentFacility = {},
	currentUser = {},
	globalState = {},
	dispatchAlert,
}) => {
	// stores resident request state
	const [checkForResident, setCheckingForResident] = useState({
		isLoading: false,
		notFound: false,
	});
	// selected resident
	const [currentResident, setCurrentResident] = useState(null);
	// target facility & it's parent, if applicable
	const [target, setTarget] = useState({
		child: {},
		parent: {},
	});
	const { formState, setFormState, handleChange, handleReset } = useForm({
		residentID: "",
		targetFacilityID: "",
		targetFacilityName: "",
		parentFacilityName: "",
		targetDate: format(new Date(), "MM/DD/YYYY"),
	});
	const { values } = formState;
	const { facilities } = currentUser;

	const handleSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// handles 'targetFacility' selection & extracts facilityID
	const handleFacilitySelect = (name, val) => {
		if (isEmptyVal(val)) {
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: "",
				},
			});
		} else {
			const record = matchFacilityByName(val, currentUser.facilities);
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: record?.facilityID,
				},
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			return fetchResident();
		} else {
			return;
		}
	};

	// inits 'resident-transfer' to new facility
	const moveResidentToFacility = async () => {
		const { token } = currentUser;
		const { residentID, targetFacilityID, targetDate } = values;
		const date =
			isToday(targetDate) || isEmptyVal(targetDate)
				? null
				: new Date(targetDate).toISOString();

		const wasTransferred = await transferResidentToFacility(
			token,
			residentID,
			targetFacilityID,
			date
		);

		if (wasTransferred) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Resident was transferred!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an error. Try again later, please.`,
			});
		}
	};

	// ##TODOS:
	// - Fix state for 'facility':
	//    - Target facility shouldn't be used currently
	//    - Update state to be an object:
	//        - Child facility
	//        - Parent facility
	const fetchResident = async () => {
		if (isEmptyVal(values.residentID)) return;
		setCheckingForResident({ notFound: false, isLoading: true });

		const { token } = currentUser;
		const { residentID } = values;
		const { facilities } = currentUser;
		const data = await getResidentProfile(token, residentID);

		if (!isEmptyArray(data.Residents)) {
			const profile = processResidentProfile(data);
			const matchFacility = matchFacilityByID(profile.facilityID, facilities);
			const parent = matchFacilityByID(matchFacility.parentID, facilities);
			setCheckingForResident({ isLoading: false, notFound: false });
			setTarget({
				child: matchFacility,
				parent: parent,
			});
			setFormState({
				...formState,
				values: {
					...values,
					targetFacilityID: profile.facilityID,
					targetFacilityName: matchFacility.communityName,
					parentFacilityName: parent.communityName,
				},
			});

			return setCurrentResident(profile);
		} else {
			console.log("failure");
			setCheckingForResident({ isLoading: false, notFound: true });
			return setCurrentResident(null);
		}
	};

	return (
		<div className={styles.TransferResident}>
			<div className={styles.TransferResident_left}>
				<div className={styles.TransferResident_left_search}>
					<NumberInput
						name="residentID"
						id="residentID"
						label="Enter Resident ID"
						val={values.residentID}
						handleChange={handleChange}
						handleKeyDown={handleKeyDown}
						customStyles={customCSS.resident}
					/>
					<ButtonSM
						type="button"
						customStyles={customCSS.load}
						handleClick={fetchResident}
					>
						Load Resident
					</ButtonSM>
				</div>
				<div className={styles.TransferResident_left_summary}>
					<ResidentSummary
						key={`LoadingState:${checkForResident.isLoading}`}
						isLoading={checkForResident.isLoading}
						childFacility={target?.child}
						parentFacility={target?.parent}
						currentUser={currentUser}
						currentResident={currentResident}
						residentNotFound={checkForResident.notFound}
					/>
				</div>
			</div>
			<div className={styles.TransferResident_right}>
				<ResidentTarget
					vals={values}
					facilities={facilities}
					currentResident={currentResident}
					currentFacility={currentFacility}
					moveResidentToFacility={moveResidentToFacility}
					handleSelection={handleSelection}
					handleFacilitySelect={handleFacilitySelect}
				/>
			</div>
		</div>
	);
};

export default TransferResident;

TransferResident.defaultProps = {};

TransferResident.propTypes = {};
