import React from "react";
import styles from "../css/views/StaxControllerView.module.scss";
import { PropTypes } from "prop-types";

const StaxControllerView = ({
	currentUser = {},
	currentFacility = {},
	parentsMap = {},
	globalState = {},
	dispatchToState,
}) => {
	return (
		<div className={styles.StaxControllerView}>
			<header className={styles.StaxControllerView_header}>
				<h2 className={styles.StaxControllerView_header_title}>Stax Manager</h2>
				<p className={styles.StaxControllerView_header_desc}>
					Manage, edit, add customer payment accounts and details via the Stax™
					platform.
				</p>
			</header>
			<div className={styles.StaxControllerView_main}>
				{/*  */}
				{/*  */}
				{/*  */}
			</div>
		</div>
	);
};

export default StaxControllerView;

StaxControllerView.defaultProps = {};

StaxControllerView.propTypes = {};
