const APP_OUTAGE = {
	title: `Maintenance Mode`,
	desc: `AL Advantage's Internal Portal is currently undergoing maintenance.`,
	extra: `Sorry for the inconvenience. We'll be back shortly!`,
};
const PAGE_OUTAGES = {
	flushCache: {
		title: `Maintenance Mode`,
		desc: `Flush Cache is NOT wired up and working yet.`,
		extra: `Do NOT use this feature yet!!`,
	},
};

export { APP_OUTAGE, PAGE_OUTAGES };
