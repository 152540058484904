import React, { useState, useEffect } from "react";
import styles from "../css/views/ToggleUIView.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import {
	saveUserMaintenanceSettings,
	saveCommunityMaintenanceSettings,
	getCurrentMaintenanceSettings,
} from "../helpers/utils_sso";
// components
import UIController from "../components/sso/UIController";

const ToggleUIView = ({ currentUser, dispatchAlert }) => {
	const { formState, setFormState, handleCheckbox, handleReset } = useForm({
		isUserMgmtEnabled: false,
		isCommunityMgmtEnabled: false,
		// cache field(s)
		cacheToFlush: "",
	});
	const { values } = formState;

	// current settings
	const [currentSettings, setCurrentSettings] = useState({
		userMtc: {},
		communityMtc: {},
	});

	const saveUserMgmt = async () => {
		const { token } = currentUser;
		const { isUserMgmtEnabled } = values;
		const wasSaved = await saveUserMaintenanceSettings(
			token,
			isUserMgmtEnabled
		);

		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `User Maintenance in Portal was Enabled!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops. Something went wrong.`,
			});
		}
	};
	const saveCommunityMgmt = async () => {
		const { token } = currentUser;
		const { isCommunityMgmtEnabled } = values;
		const wasSaved = await saveCommunityMaintenanceSettings(
			token,
			isCommunityMgmtEnabled
		);

		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `Community Maintenance in Portal was Enabled!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops. Something went wrong.`,
			});
		}
	};

	const fetchSettings = async () => {
		const { token } = currentUser;
		const settings = await getCurrentMaintenanceSettings(token);
		setFormState({
			...formState,
			values: {
				isUserMgmtEnabled: settings?.userMtc?.Display,
				isCommunityMgmtEnabled: settings?.communityMtc?.Display,
			},
		});
		setCurrentSettings({ ...settings });
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchSettings();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ToggleUIView}>
			<div className={styles.ToggleUIView_label}>
				This checkbox will disable the <b>Legacy's User Maintenance</b> UI and
				require redirecting to the Portal's User Maintenance for changes.
			</div>
			<UIController
				key={`USER_MAINT_PORTAL`}
				label="Enable User Maintenance (Portal)"
				id="isUserMgmtEnabled"
				name="isUserMgmtEnabled"
				isEnabled={values.isUserMgmtEnabled}
				handleCheckbox={handleCheckbox}
				saveChanges={saveUserMgmt}
				btnLabel="Save User MTC Changes"
			/>
			<div className={styles.ToggleUIView_label}>
				This checkbox will disable the <b>Legacy's Community Maintenance</b> UI
				and require redirecting to the Portal's Community Maintenance for
				changes.
			</div>
			<UIController
				key={`COMMUNITY_MAINT_PORTAL`}
				label="Enable Community Maintenance (Portal)"
				id="isCommunityMgmtEnabled"
				name="isCommunityMgmtEnabled"
				isEnabled={values.isCommunityMgmtEnabled}
				handleCheckbox={handleCheckbox}
				saveChanges={saveCommunityMgmt}
				btnLabel="Save Community MTC Changes"
			/>

			<div className={styles.ToggleUIView_warning}>
				<div>Don't forget to clear the cache from Legacy's website:</div>
				<a
					href="https://app.aladvantage.com/cacheadmin.aspx"
					target="_blank"
					rel="noreferrer noopener"
					className={styles.ToggleUIView_warning_link}
				>
					Go to Clear Cache
				</a>
			</div>
			<p className={styles.ToggleUIView_warning_text}>
				NOTE: After changes are made please logout and back in to confirm
				results.
			</p>
		</div>
	);
};

export default ToggleUIView;

ToggleUIView.defaultProps = {};

ToggleUIView.propTypes = {};
