import React from "react";
import styles from "../../css/banners/ServiceBanner.module.scss";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";

// REQUIREMENTS:
// - A SINGLE service banner notice UI

const customCSS = {
	confirm: {
		padding: "1.5rem 2.5rem",
		fontSize: "2rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: red[600],
	},
};

const defaultMsg = `Our records show that you have a past due account balance with AL Advantage. Please have your accounting department contact aladvantage-billing@ltpayments.com immediately to assure staff access to AL Advantage systems and Resident safety. Your access will be suspended on MM/DD/YYYY`;

const ServiceBanner = ({ bannerDetails = {}, confirmAcknowledgement }) => {
	const msg = defaultMsg;
	// const msg = bannerDetails?.ExpiredMessage ?? bannerDetails?.message;
	const expiry = format(bannerDetails?.ExpiredDate, "MM/DD/YYYY");
	const community = bannerDetails?.CommunityName;

	return (
		<div className={styles.ServiceBanner}>
			<div className={styles.ServiceBanner_community}>
				Effected Community: <b>{community}</b>
			</div>
			<div className={styles.ServiceBanner_message}>{msg}</div>

			<div className={styles.ServiceBanner_expiry}>
				Service Expiration: {expiry}
			</div>
		</div>
	);
};

export default ServiceBanner;

ServiceBanner.defaultProps = {};

ServiceBanner.propTypes = {};
