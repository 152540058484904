import React, { useState, useEffect } from "react";
import styles from "../css/views/SuspendView.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import { red, yellow } from "../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../helpers/utils_types";
import {
	getSuspendedFacilities,
	getSuspendReasons,
	isLeavingALA,
	matchReasonIDFromDesc,
	matchSuspendReasonByClientDesc,
	matchSuspendReasonByDesc,
	processSuspendReasons,
	suspendFacilityAccount,
	suspendFacilityAndEmar,
} from "../helpers/utils_suspend";
import { featureFlags } from "../helpers/utils_permissions";
import { getRealDateFromStr } from "../helpers/utils_dates";
import { format } from "date-fns";
// components
import SuspendStatus from "../components/facility/SuspendStatus";
import ReasonForSuspension from "../components/facility/ReasonForSuspension";
import ButtonSM from "../components/shared/ButtonSM";
import Dialog from "../components/shared/Dialog";
import UnSuspendController from "../components/facility/UnSuspendController";

// REQUIREMENTS:
// - Create UI for:
// 		- Show a facility's current suspend status
// 		- Suspend account:
// 				- Checkbox for 'Suspend'
// 				- Checkbox for 'Suspend & Delete'
// 				- Custom dropdown for reason for 'suspend'

const getSuspendedStatus = (currentFacility = {}) => {
	const isSuspended =
		currentFacility?.isSuspended ??
		currentFacility?.bitALASuspendSubscription ??
		false;

	return isSuspended;
};

const customCSS = {
	cancel: {
		padding: "1rem 1.6rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	suspendAlt: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	suspend: {
		padding: "1rem 1.6rem",
		backgroundColor: yellow[500],
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	suspendAndDelete: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

// Reasons that require additional info (ie. notes)
// - these reasons should also mean DisableAllUsers for respective application (eg. 'Tracker' or 'Emar')
const altReasons = [`Left Parent`, `Switched Software`, `Other`];

const hasAltReason = (vals) => {
	const { reasonForSuspend } = vals;
	return altReasons.includes(reasonForSuspend);
};
const getReasonInfo = (vals) => {
	const { reasonForSuspend, suspendComment } = vals;

	if (altReasons.includes(reasonForSuspend)) {
		return suspendComment;
	} else {
		return reasonForSuspend;
	}
};

// handles enabling the 'Suspend and Delete Account' button
const enableSuspendDeleteBtn = (currentFacility = {}, vals = {}) => {
	const { facilityID } = currentFacility;
	if (isEmptyVal(facilityID)) return false;
	const { markForSuspendAndDelete, reasonForSuspend, suspendComment } = vals;
	const requiresComment = altReasons.includes(reasonForSuspend);

	// if requires comment, then check it's filled in
	if (requiresComment) {
		const notEmpty =
			!isEmptyVal(facilityID) &&
			!isEmptyVal(markForSuspendAndDelete) &&
			!isEmptyVal(reasonForSuspend) &&
			!isEmptyVal(suspendComment);

		return notEmpty && markForSuspendAndDelete;
	} else {
		// handles suspend types, that do NOT require comment
		const notEmpty =
			!isEmptyVal(facilityID) &&
			!isEmptyVal(markForSuspendAndDelete) &&
			!isEmptyVal(reasonForSuspend);
		return notEmpty && markForSuspendAndDelete;
	}
};

// handles enabling the 'Suspend Account' button
const enableSuspendBtn = (currentFacility = {}, vals = {}) => {
	const { facilityID } = currentFacility;
	if (isEmptyVal(facilityID)) return false;
	const { markForSuspend, reasonForSuspend, suspendComment } = vals;
	const requiresComment = altReasons.includes(reasonForSuspend);

	// if requires comment, then check it's filled in
	if (requiresComment) {
		const notEmpty =
			!isEmptyVal(facilityID) &&
			!isEmptyVal(markForSuspend) &&
			!isEmptyVal(reasonForSuspend) &&
			!isEmptyVal(suspendComment);

		return notEmpty && markForSuspend;
	} else {
		// handles suspend types, that do NOT require comment
		const notEmpty =
			!isEmptyVal(facilityID) &&
			!isEmptyVal(markForSuspend) &&
			!isEmptyVal(reasonForSuspend);
		return notEmpty && markForSuspend;
	}
};

const WarningMessage = ({ vals, currentFacility }) => {
	const { markForSuspend, markForSuspendAndDelete: addDelete } = vals;
	if (!markForSuspend && !addDelete) {
		return null;
	}
	return (
		<div className={styles.WarningMessage}>
			<p className={styles.WarningMessage_facility}>
				You are about to {addDelete ? "suspend and delete" : "suspend"}{" "}
				<b>{currentFacility?.communityName}</b>
			</p>
			<p className={styles.WarningMessage_reason}>
				Reason: <b>{vals?.reasonForSuspend}</b>
			</p>
			{hasAltReason(vals) && (
				<p className={styles.WarningMessage_reason}>
					Info: <b>{getReasonInfo(vals)}</b>
				</p>
			)}
			<p className={styles.WarningMessage_directions}>
				Click 'Suspend Account' below to confirm.
			</p>
		</div>
	);
};

const unSuspendMsg = `👇 Please select a suspended facility from the list below 👇`;
const notLoaded = `☝️ Please select a facility from facility selector above ☝️`;
const loaded = `You are about to suspend `;

const getFacMessage = (currentFacility = {}, showingUnsuspendUI = false) => {
	const { facilityID, communityName } = currentFacility;
	const noFac = isEmptyVal(facilityID);
	if (noFac) {
		const msg = showingUnsuspendUI ? unSuspendMsg : notLoaded;
		return msg;
	} else {
		const suspendMsg = loaded + communityName;
		const msg = showingUnsuspendUI ? unSuspendMsg : suspendMsg;
		return msg;
	}
};

const SuspendView = ({
	currentUser,
	dispatchAlert,
	currentFacility = {},
	globalState = {},
}) => {
	const [isCurrentlySuspended, setIsCurrentlySuspended] = useState(
		getSuspendedStatus(currentFacility)
	);
	const [showConfirmSuspend, setShowConfirmSuspend] = useState(false);
	const [showConfirmSuspendAndDelete, setShowConfirmSuspendAndDelete] =
		useState(false);
	const { formState, setFormState, handleChange, handleCheckbox } = useForm({
		targetDate: format(new Date(), "MM/DD/YYYY"),
		markForSuspend: false,
		markForSuspendAndDelete: false,
		reasonForSuspend: "", // main reason (converted to SuspendReasonId for HTTP request)
		suspendComment: "No comment", // additional reason info
	});
	const { values } = formState;
	// available suspend reasons - client-formatted
	const [suspendReasons, setSuspendReasons] = useState([]);
	// unsuspend handlers
	const [showUnsuspendUI, setShowUnsuspendUI] = useState(false);
	// currently suspended facilities
	const [suspendedFacilities, setSuspendedFacilities] = useState([]);

	const handleSelections = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// inits account suspension
	const suspendAccount = async () => {
		setShowConfirmSuspend(true);
		console.log(`Preparing to suspend: ${currentFacility?.communityName}...`);
	};
	// inits account suspension & deletion
	const suspendAndDelete = async () => {
		setShowConfirmSuspendAndDelete(true);
		console.log(
			`Preparing to suspend & delete: ${currentFacility?.communityName}...`
		);
	};
	// fires off suspend request
	const confirmSuspend = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const { reasonForSuspend, targetDate, suspendComment } = values;
		// converts 'targetDate' to real date
		const realDate = getRealDateFromStr(targetDate);

		if (featureFlags?.facility?.enableSuspend) {
			const suspendRecord = matchSuspendReasonByClientDesc(
				reasonForSuspend,
				suspendReasons
			);
			const suspendModel = {
				suspendReasonId: suspendRecord?.suspendReasonID,
				suspendComment: suspendComment,
				scheduleFor: realDate,
			};

			// return suspendModel;

			// fire off request
			const wasSuspended = await suspendFacilityAccount(
				token,
				facilityID,
				suspendModel
			);
			setShowConfirmSuspend(false);
			return handleAlert(wasSuspended);
		} else {
			setShowConfirmSuspend(false);
			return handleAlert(false);
		}
	};
	// fires off suspend & delete request
	// ##TODOS:
	// - Add request to disable ALL user's application access if:
	// 		- Selected reason is: [`Cancelled`, `Left Parent`, `Switched Software`, `Went to Paper`]
	// 		- Request should be made to new API: 'UnAssignAppToFacilityUsers'
	const confirmSuspendAndDelete = async () => {
		console.log(
			`Suspending & marking for deletion: ${currentFacility?.communityName} facility account...`
		);
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const { reasonForSuspend, targetDate = "", suspendComment = "" } = values;
		const realDate = getRealDateFromStr(targetDate);

		if (featureFlags?.facility?.enableSuspendAndDelete) {
			const reasonID = matchReasonIDFromDesc(reasonForSuspend);
			const suspendRecord = matchSuspendReasonByClientDesc(
				reasonForSuspend,
				suspendReasons
			);

			// create required suspend model
			const suspendModel = {
				suspendReasonId: suspendRecord?.suspendReasonID,
				suspendComment: suspendComment,
				scheduleFor: realDate,
			};

			// if 'isLeavingALA' then disable emar access as well
			if (isLeavingALA(reasonID)) {
				const { wasSuspended, wasEmarRemoved } = await suspendFacilityAndEmar(
					token,
					facilityID,
					suspendModel
				);

				setShowConfirmSuspend(false);
				return handleAlert(wasSuspended);
			} else {
				const wasSuspended = await suspendFacilityAccount(
					token,
					facilityID,
					suspendModel
				);
				setShowConfirmSuspend(false);
				return handleAlert(wasSuspended);
			}
		} else {
			setShowConfirmSuspend(false);
			return dispatchAlert("ERROR", {
				heading: `The 'mark-for-delete' feature is disabled.`,
			});
		}
	};

	// checks response & dispatches alert accordingly
	const handleAlert = (wasSuccessful) => {
		if (wasSuccessful) {
			return dispatchAlert("SUCCESS", {
				heading: `Account was suspended!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! There was an error. Try again!`,
			});
		}
	};

	const cancelSuspend = (e) => {
		setShowConfirmSuspend(false);
	};
	const cancelSuspendAndDelete = (e) => {
		setShowConfirmSuspendAndDelete(false);
	};

	const fetchSuspendReasons = async () => {
		const { token } = currentUser;
		const reasons = await getSuspendReasons(token, {
			index: 0,
			rows: 100,
		});

		if (!isEmptyArray(reasons)) {
			const cleaned = processSuspendReasons(reasons);
			return setSuspendReasons(cleaned);
		} else {
			return;
		}
	};

	const fetchResources = async () => {
		const { token } = currentUser;
		const [reasons, suspended] = await Promise.all([
			getSuspendReasons(token, {
				index: 0,
				rows: 100,
			}),
			getSuspendedFacilities(token),
		]);

		setSuspendReasons(() => processSuspendReasons(reasons));
		setSuspendedFacilities(suspended);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		// fetchSuspendReasons();
		fetchResources();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.SuspendView}>
				<div className={styles.SuspendView_header}>
					<div className={styles.SuspendView_header_title}>
						{showUnsuspendUI ? "Un-suspend" : "Suspend"} Facility Account
					</div>
					<p className={styles.SuspendView_header_message}>
						{getFacMessage(currentFacility, showUnsuspendUI)}
					</p>
				</div>
				<div className={styles.SuspendView_unsuspend}>
					<button
						type="button"
						onClick={() => setShowUnsuspendUI(!showUnsuspendUI)}
						className={styles.SuspendView_unsuspend_btn}
					>
						Need to {showUnsuspendUI ? "Suspend" : "Unsuspend"} a Community?
					</button>
				</div>

				{/* 'SUSPEND UI' */}
				{!showUnsuspendUI && (
					<>
						<SuspendStatus isSuspended={isCurrentlySuspended} />
						<div className={styles.SuspendView_main}>
							<ReasonForSuspension
								vals={values}
								handleChange={handleChange}
								handleCheckbox={handleCheckbox}
								handleSelections={handleSelections}
								isCurrentlySuspended={isCurrentlySuspended}
							/>
							<div className={styles.SuspendView_main_warning}>
								<WarningMessage
									vals={values}
									currentFacility={currentFacility}
								/>
							</div>
						</div>
						{/* ACTION BUTTONS */}
						<div className={styles.SuspendView_actions}>
							{values?.markForSuspend && (
								<ButtonSM
									isDisabled={!enableSuspendBtn(currentFacility, values)}
									customStyles={customCSS.suspend}
									handleClick={suspendAccount}
								>
									Suspend Account
								</ButtonSM>
							)}
							{values?.markForSuspendAndDelete && (
								<ButtonSM
									isDisabled={!enableSuspendDeleteBtn(currentFacility, values)}
									customStyles={customCSS.suspendAndDelete}
									handleClick={suspendAndDelete}
								>
									Suspend and Delete Account
								</ButtonSM>
							)}
						</div>
					</>
				)}

				{/* 'UNSUSPEND UI' */}
				{showUnsuspendUI && (
					<UnSuspendController
						currentFacility={currentFacility}
						currentUser={currentUser}
						dispatchAlert={dispatchAlert}
						globalState={globalState}
						suspendReasons={suspendReasons}
						suspendedFacilities={suspendedFacilities}
					/>
				)}
			</div>

			{showConfirmSuspend && (
				<Dialog
					icon="WARN3"
					title="Suspend this Account?"
					subheading="You are about to suspend this facility account. Are you sure you want to continue?"
					closeModal={() => setShowConfirmSuspendAndDelete(false)}
				>
					<ButtonSM customStyles={customCSS.cancel} handleClick={cancelSuspend}>
						Cancel
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.suspendAndDelete}
						handleClick={confirmSuspend}
					>
						Confirm Suspend
					</ButtonSM>
				</Dialog>
			)}
			{showConfirmSuspendAndDelete && (
				<Dialog
					icon="DELETE"
					title="Suspend this Account?"
					subheading="You are about to suspend and delete this facility account. This is NOT reversible!!"
					closeModal={() => setShowConfirmSuspendAndDelete(false)}
				>
					<ButtonSM
						customStyles={customCSS.cancel}
						handleClick={cancelSuspendAndDelete}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.suspendAndDelete}
						handleClick={confirmSuspendAndDelete}
					>
						Confirm Suspend & Delete
					</ButtonSM>
				</Dialog>
			)}
		</>
	);
};

export default SuspendView;

SuspendView.defaultProps = {
	currentUser: {},
	currentFacility: {},
	globalState: {},
};

SuspendView.propTypes = {
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	globalState: PropTypes.object,
};
