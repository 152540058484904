import React from "react";
import styles from "../../css/facility/SuspendStatus.module.scss";
import { PropTypes } from "prop-types";

const SuspendStatus = ({ isSuspended = false }) => {
	return (
		<div className={styles.SuspendStatus}>
			<div className={styles.SuspendStatus_status}>
				Currently Suspended: <b>{isSuspended ? "Yes" : "No"}</b>
			</div>
		</div>
	);
};

export default SuspendStatus;

SuspendStatus.defaultProps = {};

SuspendStatus.propTypes = {};
