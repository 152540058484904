import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/facility/CommunityActivity.module.scss";
import { PropTypes } from "prop-types";
import { getFacilityLoginsInRange } from "../../helpers/utils_facility";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { calculateDiffFromDateToNow, months } from "../../helpers/utils_dates";
import { format, getMonth } from "date-fns";
// components
import CommunityActivitySummary from "./CommunityActivitySummary";
import DatePickerSM from "../shared/DatePickerSM";
import Spinner from "../shared/Spinner";

const customCSS = {
	date: {
		width: "35rem",
	},
};

const getMonthName = (date) => {
	const month = getMonth(date);
	const monthName = months[month];
	return monthName;
};

const groupLoginsByMonth = (logins = []) => {
	if (isEmptyArray(logins)) return {};
	const grouped = logins.reduce((monthMap, record) => {
		const { LoginDate } = record;
		const monthIdx = getMonth(LoginDate); // 0-11 month index
		const name = getMonthName(LoginDate); // January-December
		// if month key doesn't exist create it & set to empty array
		// then add any matching items to the array
		if (!monthMap[`${monthIdx}-${name}`]) {
			monthMap[`${monthIdx}-${name}`] = [];
		}
		monthMap[`${monthIdx}-${name}`].push(record);
		return monthMap;
	}, {});

	return grouped;
};

const CommunityActivity = ({
	globalState,
	currentFacility,
	currentUser,
	dispatchAlert,
	dispatchToState,
}) => {
	const [activity, setActivity] = useState([]);
	const [startDate, setStartDate] = useState(format(new Date(), "MM/DD/YYYY"));
	const [isLoading, setIsLoading] = useState(false);

	const handleDate = (name, date) => {
		setStartDate(date);
	};

	const getActivity = useCallback(async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		setIsLoading(true);
		const diffInDays = calculateDiffFromDateToNow(startDate);

		const logins = await getFacilityLoginsInRange(
			token,
			facilityID,
			diffInDays
		);

		if (!isEmptyArray(logins)) {
			setIsLoading(false);
			return setActivity(logins);
		}
		setIsLoading(false);
		setActivity([]);
	}, [currentFacility, currentUser, startDate]);

	// fetch activity when 'startDate' or 'currentFacility' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (currentFacility.facilityID) {
			getActivity();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility.facilityID, getActivity]);

	return (
		<div className={styles.CommunityActivity}>
			<div className={styles.CommunityActivity_date}>
				<DatePickerSM
					label="Select Date"
					val={startDate}
					handleDate={handleDate}
					focusMode={true}
					placeholder="From date until today..."
					customStyles={customCSS.date}
				/>
				{!isEmptyVal(startDate) && (
					<div className={styles.CommunityActivity_date_label}>
						Showing activity from <b>{startDate}</b> until <b>today</b>.
					</div>
				)}
			</div>

			{isLoading && <Spinner />}
			{!isLoading && (
				<CommunityActivitySummary
					currentFacility={currentFacility}
					activity={activity}
					startDate={startDate}
				/>
			)}
		</div>
	);
};

export default CommunityActivity;

CommunityActivity.defaultProps = {};

CommunityActivity.propTypes = {};
