import React from "react";
import styles from "../../css/vendor/VendorMapsTableRow.module.scss";
import { PropTypes } from "prop-types";
import { blue } from "../../helpers/utils_styles";

const customCSS = {
	selected: {
		backgroundColor: "#ffffff",
		border: `1px solid ${blue[600]}`,
	},
};

const VendorMapsTableRow = ({ row, selectRow, isSelected = false }) => {
	const {
		// ID(s)
		VendorID,
		VendorDataMapID,
		// SOURCE-NAME
		ALASourceName,
		VendorSourceName,
		// FIELD-NAME
		ALAFieldName,
		VendorFieldName,
		// FIELD-VALUE
		ALAFieldValue,
		VendorFieldValue,
	} = row;
	return (
		<div
			className={styles.VendorMapsTableRow}
			onClick={selectRow}
			style={isSelected ? customCSS.selected : {}}
		>
			{/* SOURCE-NAME */}
			<div className={styles.VendorMapsTableRow_cell}>{ALASourceName}</div>
			<div className={styles.VendorMapsTableRow_cell}>{VendorSourceName}</div>
			{/* FIELD-NAME */}
			<div className={styles.VendorMapsTableRow_cell}>{ALAFieldName}</div>
			<div className={styles.VendorMapsTableRow_cell}>{VendorFieldName}</div>
			{/* FIELD-VALUE */}
			<div className={styles.VendorMapsTableRow_cell}>{ALAFieldValue}</div>
			<div className={styles.VendorMapsTableRow_cell}>{VendorFieldValue}</div>
		</div>
	);
};

export default VendorMapsTableRow;

VendorMapsTableRow.defaultProps = {};

VendorMapsTableRow.propTypes = {};
