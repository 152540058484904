import React from "react";
import styles from "../../css/facility/LoginsByUser.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const LoginEntry = ({ userLogin }) => {
	return (
		<li className={styles.LoginEntry}>
			User: <b>{userLogin}</b>
		</li>
	);
};

const LoginsByUser = ({ loginsByUser = [] }) => {
	return (
		<div className={styles.LoginsByUser}>
			<div className={styles.LoginsByUser_title}>
				Users with Activity <b>({loginsByUser?.length ?? 0})</b>:
			</div>
			<ul className={styles.LoginsByUser_list}>
				{!isEmptyArray(loginsByUser) &&
					loginsByUser.map((userLogin, idx) => (
						<LoginEntry key={`Login-Entry-${idx}`} userLogin={userLogin} />
					))}
			</ul>
		</div>
	);
};

export default LoginsByUser;

LoginsByUser.defaultProps = {};

LoginsByUser.propTypes = {};
