import React from "react";
import styles from "../../css/facility/LoginsByDateViz.module.scss";
import { PropTypes } from "prop-types";
import { getMonth } from "date-fns";
import { months } from "../../helpers/utils_dates";
import { isEmptyArray } from "../../helpers/utils_types";
import { purple } from "../../helpers/utils_styles";
// components
import BarChartWrapper from "../charts/BarChartWrapper";

const processDateEntries = (logins = []) => {
	// get dates as 'labels'
	// set data sets by each date???
	const grouped = groupLoginsByMonth(logins);

	const {
		January,
		February,
		March,
		April,
		May,
		June,
		July,
		August,
		September,
		October,
		November,
		December,
	} = grouped;

	const data = {
		labels: months,
		datasets: [
			{
				label: "Logins By Month",
				fill: false,
				backgroundColor: purple[700],
				borderColor: purple[700],
				data: [
					January?.length ?? 0,
					February?.length ?? 0,
					March?.length ?? 0,
					April?.length ?? 0,
					May?.length ?? 0,
					June?.length ?? 0,
					July?.length ?? 0,
					August?.length ?? 0,
					September?.length ?? 0,
					October?.length ?? 0,
					November?.length ?? 0,
					December?.length ?? 0,
				],
			},
		],
	};

	return data;
};

const getMonthName = (date) => {
	const month = getMonth(date);
	const monthName = months[month];
	return monthName;
};

const groupLoginsByMonth = (logins = []) => {
	if (isEmptyArray(logins)) return {};
	const grouped = logins.reduce((monthMap, record) => {
		const { LoginDate } = record;
		const name = getMonthName(LoginDate); // January-December
		// if month key doesn't exist create it & set to empty array
		// then add any matching items to the array
		if (!monthMap[name]) {
			monthMap[name] = [];
		}
		monthMap[name].push(record);
		return monthMap;
	}, {});

	return grouped;
};

const LoginsByDateViz = ({ logins = [] }) => {
	const data = processDateEntries(logins);

	return (
		<div className={styles.LoginsByDateViz}>
			<div className={styles.LoginsByDateViz_header}>
				<div className={styles.LoginsByDateViz_header_title}>
					Logins By Date
				</div>
			</div>
			<div className={styles.LoginsByDateViz_viz}>
				<BarChartWrapper data={data} title="Logins By Date" />
			</div>
		</div>
	);
};

export default LoginsByDateViz;

LoginsByDateViz.defaultProps = {};

LoginsByDateViz.propTypes = {};
