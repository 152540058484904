import React, { useState, useEffect } from "react";
import styles from "../../css/banners/NewBannerForm.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { blue, blueGrey, red } from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
import { matchFacilityByName } from "../../helpers/utils_facility";
// components
import CustomDropdown from "../shared/CustomDropdown";
import Textarea from "../shared/Textarea";
import CustomCheckbox from "../shared/CustomCheckbox";
import DatePickerSM from "../shared/DatePickerSM";
import CopyText from "../forms/CopyText";
import ButtonSM from "../shared/ButtonSM";
import {
	createServiceBannerModel,
	saveNewServiceBanner,
} from "../../helpers/utils_banners";

const customCSS = {
	copy: {
		width: "50rem",
		fontSize: "1.5rem",
		fontWeight: "400",
		color: blueGrey[500],
	},
	clear: {
		padding: ".7rem 1.4rem",
		fontSize: "1.6rem",
		color: "#ffffff",
		backgroundColor: red[600],
		marginRight: "1rem",
	},
	save: {
		padding: ".7rem 1.4rem",
		fontSize: "1.6rem",
		color: "#ffffff",
		backgroundColor: blue[600],
	},
	msg: {
		backgroundColor: "#ffffff",
	},
	selector: {
		width: "40rem",
	},
	expiry: {
		width: "40rem",
	},
};

const disableSave = (values = {}) => {
	const { selectedFacility, expiry, message, isEnabled } = values;
	const isMissing =
		isEmptyVal(selectedFacility) ||
		isEmptyVal(expiry) ||
		isEmptyVal(message) ||
		!isEnabled;

	return isMissing;
};

const defaultMsg = `Our records show that you have a past due account balance with AL Advantage. Please have your accounting department contact aladvantage-billing@ltpayments.com immediately to assure staff access to AL Advantage systems and Resident safety. Your access will be suspended on `;

const EmailUI = () => {
	return (
		<a
			href="emailto:aladvantage-billing@ltpayments.com"
			className={styles.EmailUI}
		>
			aladvantage-billing@ltpayments.com
		</a>
	);
};

const ExampleMessage = ({ expiry = "MM/DD/YYYY" }) => {
	return (
		<div className={styles.ExampleMessage}>
			<div className={styles.ExampleMessage_title}>
				Sample Text (click to copy)
			</div>
			<div className={styles.ExampleMessage_text}>
				<CopyText text={defaultMsg + expiry} customStyles={customCSS.copy} />
			</div>
		</div>
	);
};

const getOptions = (facilities = []) => {
	const list = facilities.map((x) => x?.communityName ?? x?.CommunityName);

	return list;
};

const NewBannerForm = ({
	currentUser = {},
	currentFacility = {},
	refetchBanners,
	dispatchAlert,
}) => {
	const { facilities } = currentUser;
	const [selectedFacility, setSelectedFacility] = useState("");
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			expiry: "",
			message: "",
			isEnabled: true,
		});
	const { values, touched } = formState;
	// was created
	const [wasCreated, setWasCreated] = useState(false);

	const handleFacility = (name, val) => {
		if (isEmptyVal(val)) {
			return setSelectedFacility("");
		} else {
			return setSelectedFacility(val);
		}
	};
	const handleDate = (name, val) => {
		if (isEmptyVal(val)) {
			return setFormState({
				...formState,
				values: {
					...values,
					expiry: "",
				},
			});
		} else {
			return setFormState({
				...formState,
				values: {
					...values,
					expiry: val,
				},
			});
		}
	};

	// clears form & selected facility
	const handleClear = (e) => {
		setFormState({
			...formState,
			values: {
				expiry: "",
				message: "",
				isEnabled: false,
			},
			touched: {},
		});
		setSelectedFacility("");
	};

	const saveBanner = async (e) => {
		const { token } = currentUser;
		// get facilityID from 'selectedFacility'
		const expiry = new Date(values?.expiry).toISOString();
		const target = matchFacilityByName(selectedFacility, facilities);
		const facilityID =
			target?.facilityID ?? target?.FacilityId ?? target?.FacilityID;

		const newVals = {
			...values,
			expiry: expiry,
			facilityID: facilityID,
		};

		const bannerModel = createServiceBannerModel(newVals);
		const wasSaved = await saveNewServiceBanner(token, bannerModel);
		// const wasSaved = true;

		if (wasSaved) {
			dispatchAlert("SUCCESS", {
				heading: "Banner was saved.",
			});
			handleReset();
			setWasCreated(true);
			return refetchBanners();
		} else {
			setWasCreated(false);
			dispatchAlert("ERROR", {
				heading: "Whoops! Something went wrong",
			});
			return refetchBanners();
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		let timerID;
		if (wasCreated) {
			timerID = setTimeout(() => {
				setWasCreated(false);
			}, 2000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [wasCreated]);

	return (
		<div className={styles.NewBannerForm}>
			<div className={styles.NewBannerForm_header}>
				<h2 className={styles.NewBannerForm_header_title}>
					Create New Service Banner
				</h2>
				<p className={styles.NewBannerForm_header_desc}>
					Create and enable a banner notification for a given facility regarding
					their service expiration to be displayed upon login to the User Portal
					(Admin Portal).
				</p>
			</div>
			<div className={styles.NewBannerForm_selector}>
				<CustomDropdown
					name="targetFacility"
					id="targetFacility"
					label="Select target facility"
					selection={selectedFacility}
					setSelection={handleFacility}
					options={getOptions(facilities)}
					customStyles={customCSS.selector}
				/>
			</div>
			<fieldset className={styles.NewBannerForm_form}>
				<div className={styles.NewBannerForm_form_item}>
					<Textarea
						name="message"
						id="message"
						label="Enter banner message"
						val={values.message}
						handleChange={handleChange}
						maxChar={500}
						enableCharCount={true}
						placeholder={defaultMsg}
						customStyles={customCSS.msg}
					/>
				</div>
				<div className={styles.NewBannerForm_form_item}>
					<ExampleMessage
						expiry={isEmptyVal(values?.expiry) ? "MM/DD/YYYY" : values.expiry}
					/>
				</div>
				<div className={styles.NewBannerForm_form_item}>
					<DatePickerSM
						label="Set Expiration Date"
						id="expiry"
						name="expiry"
						val={values.expiry}
						handleDate={handleDate}
						focusMode={true}
						placeholder="Expiry date MM/DD/YYYY..."
						customStyles={customCSS.expiry}
					/>
				</div>
				<div className={styles.NewBannerForm_form_item}>
					<CustomCheckbox
						name="isEnabled"
						id="isEnabled"
						label="Enable Banner"
						val={values.isEnabled}
						handleCheckbox={handleCheckbox}
					/>
				</div>
			</fieldset>
			<div className={styles.NewBannerForm_actions}>
				<ButtonSM handleClick={handleClear} customStyles={customCSS.clear}>
					Clear Form
				</ButtonSM>
				<ButtonSM
					isDisabled={
						disableSave({ ...values, selectedFacility }) || wasCreated
					}
					handleClick={saveBanner}
					customStyles={customCSS.save}
				>
					{wasCreated ? "Banner was saved!" : "Save New Banner"}
				</ButtonSM>
			</div>
		</div>
	);
};

export default NewBannerForm;

NewBannerForm.defaultProps = {};

NewBannerForm.propTypes = {};
