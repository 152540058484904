import React, { useCallback, useEffect, useState } from "react";
import styles from "../css/views/CommunityAssociationView.module.scss";
import sprite from "../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";
import {
	getChildCommunitiesFromParent,
	processRawFacilityList,
} from "../helpers/utils_facility";
import { isEmptyArray } from "../helpers/utils_types";
import { red } from "../helpers/utils_styles";
// components
import CustomSelect from "../components/shared/CustomSelect";
// custom components
import FacilitySummary from "../components/facility/FacilitySummary";
import MoveToParentFacility from "../components/facility/MoveToParentFacility";
import MoveIndependentToParent from "../components/facility/MoveIndependentToParent";
import MakeIndependentFacility from "../components/facility/MakeIndependentFacility";

const communityActionTypes = [
	`Move Independent Under Parent`, // #1: ind to existing parent (buy-out)
	`Move Child Under New Parent`, // #2: child to diff parent
	`Make Community Independent`, // #3: make facility an ind
];

const customCSS = {
	selector: {
		backgroundColor: "#ffffff",
	},
	remove: {
		padding: ".8rem 1.6rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "500",
	},
	actions: {
		width: "40rem",
		backgroundColor: "#ffffff",
	},
};

// REQUIREMENTS:
// - Create UI for:
// 		- 1. Move community to under parent (independents to some parent)
// 				- Show community selector
// 		- 2. Move community to new parent (child from one parent to another parent)
// 				- Show community selector
// 		- 3. Make community independent (makes child/parent an independent)
// 				- Show user removal UI selector
//		 		- Remove users from community list if making community independent
//						- ONLY appears for option 3.

const ActionIndicator = ({ actionType }) => {
	const renderType = (actionType) => {
		switch (actionType) {
			// Option #1: independent => new parent
			case "Move Independent Under Parent": {
				return (
					<div className={styles.ActionIndicator}>
						<svg className={styles.ActionIndicator_icon}>
							<use xlinkHref={`${sprite}#icon-transfer`}></use>
						</svg>
						<span className={styles.ActionIndicator_msg}>
							#1: Move Independent Under Parent
						</span>
					</div>
				);
			}
			// Option #2: child => new parent
			case "Move Child Under New Parent": {
				return (
					<div className={styles.ActionIndicator}>
						<svg className={styles.ActionIndicator_icon}>
							<use xlinkHref={`${sprite}#icon-exchange`}></use>
						</svg>
						<span className={styles.ActionIndicator_msg}>
							#2: Move Child Under New Parent
						</span>
					</div>
				);
			}
			// Option #3: child/parent => independent
			case "Make Community Independent": {
				return (
					<div className={styles.ActionIndicator}>
						<svg className={styles.ActionIndicator_icon}>
							<use xlinkHref={`${sprite}#icon-fiber_new`}></use>
						</svg>
						<span className={styles.ActionIndicator_msg}>
							#3: Make Community Independent
						</span>
					</div>
				);
			}

			default:
				return null;
		}
	};

	return <>{renderType(actionType)}</>;
};

const CommunityAssociationView = ({
	currentFacility = {},
	currentUser = {},
	globalState = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const [actionType, setActionType] = useState("");
	const [childCommunities, setChildCommunities] = useState([]);
	const [selections, setSelections] = useState([]);

	const handleCustomSelect = (selections) => {
		if (!isEmptyArray(selections)) {
			return setActionType(selections?.[0]);
		}
		return setActionType("");
	};

	const getChildren = useCallback(async () => {
		const { token } = currentUser;
		const { parentID } = currentFacility;
		const children = await getChildCommunitiesFromParent(token, parentID);
		const childs = processRawFacilityList(children);

		if (!isEmptyArray(childs)) {
			return setChildCommunities(childs);
		} else {
			return setChildCommunities([]);
		}
	}, [currentFacility, currentUser]);

	// fetches children from 'currentFacility's 'parentID', if applicable
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (currentFacility.facilityID) {
			getChildren();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility.facilityID, getChildren]);

	return (
		<div className={styles.CommunityAssociationView}>
			<div className={styles.CommunityAssociationView_actionSelector}>
				<label htmlFor="actionType">What would you like to do?</label>
				<CustomSelect
					name="actionType"
					id="actionType"
					selection={actionType}
					handleCustomSelect={handleCustomSelect}
					options={communityActionTypes}
					customStyles={customCSS.actions}
					disableMultiSelect={true}
				/>
			</div>
			<section className={styles.CommunityAssociationView_type}>
				<ActionIndicator actionType={actionType} />
			</section>

			<section className={styles.CommunityAssociationView_main}>
				<div className={styles.CommunityAssociationView_main_summary}>
					<FacilitySummary
						key={currentFacility?.parentID}
						currentFacility={currentFacility}
						currentUser={currentUser}
					/>
				</div>

				<div className={styles.CommunityAssociationView_main_options}>
					{/* OPTION #1: Ind. to parent (ie result of a buy-out) */}
					{actionType === "Move Independent Under Parent" && (
						<MoveIndependentToParent
							currentFacility={currentFacility}
							currentUser={currentUser}
							globalState={globalState}
							dispatchAlert={dispatchAlert}
							dispatchToState={dispatchToState}
						/>
					)}
					{/* OPTION #2: Child to another parent */}
					{actionType === "Move Child Under New Parent" && (
						<MoveToParentFacility
							currentFacility={currentFacility}
							currentUser={currentUser}
							globalState={globalState}
							dispatchAlert={dispatchAlert}
							dispatchToState={dispatchToState}
						/>
					)}
					{/* OPTION #3: Make facility independent */}
					{actionType === "Make Community Independent" && (
						<MakeIndependentFacility
							currentFacility={currentFacility}
							currentUser={currentUser}
							globalState={globalState}
							dispatchAlert={dispatchAlert}
							dispatchToState={dispatchToState}
						/>
					)}
				</div>
			</section>
		</div>
	);
};

export default CommunityAssociationView;

CommunityAssociationView.defaultProps = {};

CommunityAssociationView.propTypes = {};
