import React, { useState } from "react";
import styles from "../css/views/AccountView.module.scss";
import { PropTypes } from "prop-types";
import { purple, red } from "../helpers/utils_styles";
import { isEmptyVal } from "../helpers/utils_types";
import { useForm } from "../utils/useForm";
import { copyTextToClipboard } from "../helpers/utils_processing";
import { updateNewFacilityModel } from "../helpers/utils_models";
import {
	createFacilityAccount,
	matchFacilityByName,
} from "../helpers/utils_facility";
// components
import Divider from "../components/forms/Divider";
import FacilityInfo from "../components/facility/FacilityInfo";
import CreateAccountStatus from "../components/facility/CreateAccountStatus";
import {
	fetchAndProcessLOCTemplates,
	matchTemplateIDFromName,
} from "../helpers/utils_loc";

// REQUIREMENTS:
// - Create new 'Facility/Community' Account UI ✓
// - Make a user an 'ADMIN' account
// - Add a user to a regional facility
// - Move 'child' facility to:
// 		- Another parent facility
// 		- Another regional facility
// 		- An independent facility
// - IF 'Parent' is selected for new facility:
// 		- Load that parent's LOC Templates to be selected as default LOC.

const customCSS = {
	loaded: {
		color: purple[600],
	},
	notLoaded: {
		color: red[600],
	},
	divider: {
		marginBottom: "6rem",
	},
	subtitle: {
		fontSize: "1.4rem",
		fontWeight: "600",
		marginBottom: "0rem",
	},
	// confirm actions
	cancelNewAdmin: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
	},
	saveNewAdmin: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
		color: "#ffffff",
	},
	cancelSaveAs: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
	},
	saveAs: {
		padding: "1.2rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const AccountView = ({
	currentFacility,
	currentUser,
	globalState,
	dispatchAlert,
}) => {
	const { parentsMap } = globalState;
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			communityName: "",
			street: "",
			suiteNumber: "",
			city: "",
			state: "",
			zip: "",
			phoneNumber: "",
			email: "",
			faxNumber: "",
			execDirector: "",
			alaDirector: "",
			licenseNumber: "",
			localTimeZone: "",
			parentFacility: "",
			// yardiNumber: "", // NOT NEEDED!!
			// community settings
			enableMeds: false,
			enableContacts: false,
			enableAssessments: false,
			enableALAAdmin: false,
		});
	const { values } = formState;
	// template selection(s) (if parent is selected)
	const [selectedTemplate, setSelectedTemplate] = useState("");
	// parent LOC template(s)
	const [parentTemplates, setParentTemplates] = useState({
		names: [],
		templates: [],
	});

	const [accountWasCreated, setAccountWasCreated] = useState(false);
	// new facility account stored in state
	const [newAccountData, setNewAccountData] = useState({});

	// select template name from list
	const selectTemplate = (name, val) => {
		if (isEmptyVal(val)) return setSelectedTemplate("");
		setSelectedTemplate(val);
	};

	// selects parent community, then fires off request for templates
	const handleParentSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});

		if (!isEmptyVal(val)) {
			return fetchParentTemplates(val);
		} else {
			return;
		}
	};

	const copySelection = (fieldName) => {
		const val = values?.[fieldName] ?? "";
		copyTextToClipboard(val);
	};

	const handleCustomFields = (name, val) => {
		if (isEmptyVal(val)) {
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: "",
				},
			});
		} else {
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
				},
			});
		}
	};

	const getParentID = (values) => {
		if (isEmptyVal(values.parentFacility)) return "";
		// get 'parentID' if applicable
		const parent = matchFacilityByName(
			values.parentFacility,
			currentUser.facilities
		);
		const { facilityID: parentID } = parent;

		return parentID;
	};

	// create new facility account
	// ##TODOS:
	// - Add support for selecting LOC template
	// - Add 'templateId' to 'NewFacilityModel' data structure
	const saveNewAccount = async () => {
		const { token } = currentUser;
		const { templates = [] } = parentTemplates;
		// get 'templateID' if applicable
		// get 'parentID' if applicable, otherwise set it to: ''
		const parentID = getParentID(values);
		const templateID = !isEmptyVal(parentID)
			? matchTemplateIDFromName(selectedTemplate, templates)
			: 0;
		const model = updateNewFacilityModel({
			...values,
			parentFacilityID: parentID,
		});
		// createFacilityAccount(token, templateId, newFacilityModel)
		const dataResp = await createFacilityAccount(token, 0, model);
		const { Messages, Status, Data: newID } = dataResp;

		if (Status === "Created") {
			console.log(`✅ Created new ALA Community Account!`);

			dispatchAlert("SUCCESS", {
				heading: `New Account Was Created!`,
			});
			setNewAccountData({
				...values,
				facilityID: newID, // facilityID in 'Message' array
			});
			return setAccountWasCreated(true);
		} else {
			console.log(`❌ Ooops! Failed to create new account!`);

			dispatchAlert("ERROR", {
				heading: `Ooops! There was an error!`,
				subheading: Messages?.[0] ?? "Community was not created!",
			});
			setNewAccountData({
				errors: Messages,
			});
			return setAccountWasCreated(false);
		}
	};
	// cancel new facility account
	const cancelNewAccount = (e) => {
		console.log(`❌ Cancelling new account!`);
		handleReset(e); // reset all input fields
	};

	// closes 'Success' UI popup after facility account creation
	const createAnotherAccount = (e) => {
		handleReset(e);
		setAccountWasCreated(false);
	};

	// fetches parent LOC templates, if applicable
	const fetchParentTemplates = async (parentName) => {
		const { token } = currentUser;
		const parentID = getParentID({ ...values, parentFacility: parentName });

		if (isEmptyVal(parentID)) return;

		const namesAndTemplates = await fetchAndProcessLOCTemplates(
			token,
			parentID
		);

		setParentTemplates({ ...namesAndTemplates });
	};

	return (
		<div className={styles.AccountView}>
			<header className={styles.AccountView_header}>
				<h2 className={styles.AccountView_header_main}>
					Create New Facility Account
				</h2>
				<p className={styles.AccountView_header_desc}>
					Enter details for a new community account including: address, contact
					info, timezone and ALA settings.
				</p>
			</header>
			<div className={styles.AccountView_main}>
				{accountWasCreated && (
					<CreateAccountStatus
						newAccountID={newAccountData?.facilityID}
						errorMsgs={newAccountData?.errors}
						wasSuccessful={
							accountWasCreated && !isEmptyVal(newAccountData?.facilityID)
						}
						handleReset={createAnotherAccount}
						closeModal={() => setAccountWasCreated(false)}
					/>
				)}
				<FacilityInfo
					key={currentUser?.facilities?.length}
					vals={values}
					formState={formState}
					handleCustomFields={handleCustomFields}
					handleChange={handleChange}
					handleCheckbox={handleCheckbox}
					copySelection={copySelection}
					saveChanges={saveNewAccount}
					cancelChanges={cancelNewAccount}
					currentUser={currentUser}
					currentFacility={currentFacility}
					allFacilities={currentUser?.facilities ?? []}
					parentsMap={parentsMap}
					// template info
					handleParentSelection={handleParentSelection}
					selectTemplate={selectTemplate}
					selectedTemplate={selectedTemplate}
					parentTemplates={parentTemplates}
				/>
			</div>
			<Divider />

			<div className={styles.AccountView_main}>
				{/* ADD USER TO REGIONAL FACILITY */}
			</div>
		</div>
	);
};

export default AccountView;

AccountView.defaultProps = {};

AccountView.propTypes = {
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	globalState: PropTypes.object,
	dispatchAlert: PropTypes.func,
};
