import React from "react";
import styles from "../../css/vendor/VendorMapsActions.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	delete: {
		padding: ".8rem 2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
};

const VendorMapsActions = ({ selectedRow = {}, deleteRow }) => {
	const { data: selectedRecord } = selectedRow;
	return (
		<div className={styles.VendorMapsActions}>
			<ButtonSM
				isDisabled={isEmptyVal(selectedRecord?.VendorDataMapID)}
				customStyles={customCSS.delete}
				handleClick={deleteRow}
			>
				Delete Row
			</ButtonSM>
		</div>
	);
};

export default VendorMapsActions;

VendorMapsActions.defaultProps = {};

VendorMapsActions.propTypes = {};
