import React, { useContext, useState, useEffect } from "react";
import styles from "../css/pages/AdminPage.module.scss";
import sprite3 from "../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";
import { purple } from "../helpers/utils_styles";
import { AuthContext } from "../state/AuthContext";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { logout } from "../helpers/utils_auth";
import { useAlerts, initialSettings } from "../utils/useAlerts";
import {
	getInitialResource,
	populateState,
} from "../helpers/utils_initialResource";
import { sortAlphaAscByKey } from "../helpers/utils_processing";
import { matchFacilityByName } from "../helpers/utils_facility";
import { isEmptyVal } from "../helpers/utils_types";
// new utils
import { fetchAndProcessUsers } from "../helpers/utils_facility.js";

// components
import Spinner from "../components/shared/Spinner";
import CustomDropdown from "../components/shared/CustomDropdown";
import FullPageTabsController from "../components/tabs/FullPageTabsController";
import FullPageTabButtons from "../components/tabs/FullPageTabButtons";
import FullPageTabButton from "../components/tabs/FullPageTabButton";
import FullPageTabPanels from "../components/tabs/FullPageTabPanels";
import FullPageTabPanel from "../components/tabs/FullPageTabPanel";
// tab-views
import AccountView from "../views/AccountView";
import ImportView from "../views/ImportView";
import SuspendView from "../views/SuspendView";
import CommunityAssociationView from "../views/CommunityAssociationView";
import RenameCommunityView from "../views/RenameCommunityView";
import TransferResidentView from "../views/TransferResidentView";
import LogoutButton from "../components/app/LogoutButton";
import DisableFacilityAppAccessView from "../views/DisableFacilityAppAccessView";
import CommunityActivityView from "../views/CommunityActivityView";
import UserAdminView from "../views/UserAdminView";
import ToggleSSOView from "../views/ToggleSSOView";
import ToggleUIView from "../views/ToggleUIView";
import FlushCacheView from "../views/FlushCacheView";
import ExportView from "../views/ExportView";
import VendorMapsView from "../views/VendorMapsView";
import DisassociateView from "../views/DisassociateView";
import ReportsView from "../views/ReportsView";
import BannerControlsView from "../views/BannerControlsView";
import StaxControllerView from "../views/StaxControllerView";

const customCSS = {
	selector: {
		minWidth: "30rem",
	},
};

const tabIcons = {
	"Account Setup": "add",
	"Import Data": "file_upload",
	"Export Data": "file_download",
	"Suspend Account": "domain_disabled",
	"Transfer Resident": "exchange",
	"Community Association": "published_with_changes",
	"Rename Community": "drive_file_rename_outline",
	"Disable Facility App Access": "sync_disabled", // sync_disabled
	"Community Activity": "wifi_protected_setup",
	"Create User Admin": "supervisor_account",
	"Toggle SSO": "check_circle",
	"Toggle UI": "check_circle",
	"Flush Cache": "update",
	"Vendor Data Mapping": "view_agenda",
	"Disassociate Facility/Users": "view_agenda",
	"Internal Reports": "account_tree",
	"Service Banners": "notifications",
	"Stax (Payment Manager)": "fact_check",
};

// Disabled: 'CommunityAssociation' & 'DisableFacilityAppAccess'
// const disabledTabs = [4, 6];
// const disabledTabs = [4];
const disabledTabs = [];

// gets active, disabled or hidden tab button styles
const getTabStyles = (isActive) => {
	if (isActive) {
		return {
			fill: purple[600],
			color: purple[600],
		};
	} else {
		return null;
	}
};

// determine which sprite to use based off 'available keys'
const getSprite = (icon) => {
	return sprite3;
};

const IconButton = ({
	icon,
	name,
	isActive = false,
	isDisabled = false,
	hoverTitle,
	children,
}) => {
	return (
		<div
			className={isDisabled ? styles.IconButton_isDisabled : styles.IconButton}
			title={hoverTitle}
		>
			<svg className={styles.IconButton_icon} style={getTabStyles(isActive)}>
				<use xlinkHref={`${getSprite(name)}#icon-${icon}`}></use>
			</svg>
			<span className={styles.IconButton_name} style={getTabStyles(isActive)}>
				{name}
			</span>

			{children}
		</div>
	);
};

const AdminPage = ({ history }) => {
	const { authData } = useContext(AuthContext);
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentFacility, currentUser, facilitiesMap } = globalState;
	const { facilities } = currentUser;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);
	const [selectedFacility, setSelectedFacility] = useState("");
	const [initialMount, setInitialMount] = useState({
		isLoading: false,
		hasLoaded: false,
	});

	const handleLogout = async (e) => {
		e.preventDefault();
		const { token } = currentUser;
		const wasLoggedOut = await logout(token);

		console.log(`Was Logged out? `, wasLoggedOut);
		if (wasLoggedOut) {
			dispatchToState({ type: "MANUAL_LOGOUT" });
			return history.push({
				pathname: "/",
				state: {
					from: history?.location?.pathname,
					wasLoggedOut: true,
				},
			});
		} else {
			return alert(`❌ Oops! Logout failed. Please try again!`);
		}
	};

	const changeFacility = async (name, val) => {
		if (isEmptyVal(val)) {
			setSelectedFacility("");
			return dispatchToState({
				type: "CLEAR_FACILITY",
			});
		}
		const { token } = currentUser;
		const selected = matchFacilityByName(val, facilities);
		const users = await fetchAndProcessUsers(token, selected?.facilityID);

		setSelectedFacility(val);
		return dispatchToState({
			type: "SET_FACILITY",
			data: {
				facility: selected,
				userData: users,
			},
		});
	};

	const fetchInitialResource = async () => {
		setInitialMount({ ...initialMount, isLoading: true });
		const data = await getInitialResource(authData);
		const newState = populateState(data, globalState);

		if (newState) {
			setInitialMount({
				hasLoaded: true,
				isLoading: false,
			});

			return dispatchToState({
				type: "INITIAL_RESOURCE",
				data: {
					newState,
				},
			});
		} else {
			return setInitialMount({
				...initialMount,
				isLoading: false,
			});
		}
	};

	// fetch facility & user security info
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.AdminPage}>
				<header className={styles.AdminPage_header}>
					<h1 className={styles.AdminPage_header_title}>
						Portal <b>(INTERNAL ONLY)</b>
					</h1>
				</header>
				{initialMount.isLoading && <Spinner />}
				{initialMount.hasLoaded && (
					<div className={styles.AdminPage_selector}>
						<CustomDropdown
							key={facilities?.length}
							name="selectedFacility"
							id="selectedFacility"
							selection={selectedFacility}
							setSelection={changeFacility}
							label="Choose community"
							customStyles={customCSS.selector}
							options={sortAlphaAscByKey("communityName", facilities).map(
								({ communityName }) => communityName
							)}
						/>
					</div>
				)}
				<div className={styles.AdminPage_main}>
					<FullPageTabsController
						defaultIndex={0}
						disabledIndices={[...disabledTabs]}
						hiddenIndices={[]}
					>
						{/* TAB BUTTONS */}
						<FullPageTabButtons>
							<FullPageTabButton>
								<IconButton
									name="Account Setup"
									icon={tabIcons["Account Setup"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton name="Import Data" icon={tabIcons["Import Data"]} />
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton name="Export Data" icon={tabIcons["Export Data"]} />
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Suspend Account"
									icon={tabIcons["Suspend Account"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Transfer Resident"
									icon={tabIcons["Transfer Resident"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Community Association"
									icon={tabIcons["Community Association"]}
									hoverTitle="Coming Soon!"
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Rename Community"
									icon={tabIcons["Rename Community"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Disable Facility App Access"
									icon={tabIcons["Disable Facility App Access"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Community Activity"
									icon={tabIcons["Community Activity"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Create User Admin"
									icon={tabIcons["Create User Admin"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton name="Toggle SSO" icon={tabIcons["Toggle SSO"]} />
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton name="Toggle UI" icon={tabIcons["Toggle UI"]} />
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton name="Flush Cache" icon={tabIcons["Flush Cache"]} />
							</FullPageTabButton>
							{/* DATA MAPPING TAB */}
							<FullPageTabButton>
								<IconButton
									name="Vendor Data Mapping"
									icon={tabIcons["Vendor Data Mapping"]}
								/>
							</FullPageTabButton>
							<FullPageTabButton>
								<IconButton
									name="Disassociate Facility/Users"
									icon={tabIcons["Disassociate Facility/Users"]}
								/>
							</FullPageTabButton>
							{/* REPORT(S) TAB: ACTIVE USERS  */}
							<FullPageTabButton>
								<IconButton
									name="Internal Reports"
									icon={tabIcons["Internal Reports"]}
								/>
							</FullPageTabButton>
							{/* BANNERS: DELIQUENT BANNERS ETC.  */}
							<FullPageTabButton>
								<IconButton
									name="Service Banners"
									icon={tabIcons["Service Banners"]}
								/>
							</FullPageTabButton>
							{/* STAX TAB  */}
							<FullPageTabButton>
								<IconButton
									name="Stax (Payment Manager)"
									icon={tabIcons["Stax (Payment Manager)"]}
								/>
							</FullPageTabButton>
						</FullPageTabButtons>

						{/* TAB PANELS */}
						<FullPageTabPanels>
							{/* Account Setup VIEW */}
							<FullPageTabPanel>
								<AccountView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* Import Data VIEW */}
							<FullPageTabPanel>
								<ImportView
									key={`IMPORT-DATA-VIEW`}
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchAlert={dispatchAlert}
									dispatchToState={dispatchToState}
								/>
							</FullPageTabPanel>
							{/* Export Data VIEW */}
							<FullPageTabPanel>
								<ExportView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchAlert={dispatchAlert}
									dispatchToState={dispatchToState}
								/>
							</FullPageTabPanel>
							{/* SUSPEND ACCOUNT VIEW */}
							<FullPageTabPanel>
								<SuspendView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchAlert={dispatchAlert}
									dispatchToState={dispatchToState}
								/>
							</FullPageTabPanel>
							{/* TRANSFER RESIDENT VIEW */}
							<FullPageTabPanel>
								<TransferResidentView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* COMMUNITY ASSOCIATION VIEW */}
							<FullPageTabPanel>
								<CommunityAssociationView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* RENAME COMMUNITY VIEW */}
							<FullPageTabPanel>
								<RenameCommunityView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* DISABLE FACILITY APP ACCESS */}
							<FullPageTabPanel>
								<DisableFacilityAppAccessView
									key={`ACCESS-${currentFacility?.facilityID}`}
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* COMMUNITY ACTIVITY (RECENT LOGINS ETC.) */}
							<FullPageTabPanel>
								<CommunityActivityView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* CREATE OR ASSIGN USER AS ADMIN */}
							<FullPageTabPanel>
								<UserAdminView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							<FullPageTabPanel>
								<ToggleSSOView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							<FullPageTabPanel>
								<ToggleUIView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							<FullPageTabPanel>
								<FlushCacheView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							<FullPageTabPanel>
								<VendorMapsView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* DISASSOCIATE FACILITY/USERS */}
							<FullPageTabPanel>
								<DisassociateView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* REPORTS TAB: ACTIVE USERS */}
							<FullPageTabPanel>
								<ReportsView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* BANNERS: DELINQUENT ACCOUNT BANNERS ETC. */}
							<FullPageTabPanel>
								<BannerControlsView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
							{/* STAX CONTROLLER & MANAGEMENT VIEW */}
							<FullPageTabPanel>
								<StaxControllerView
									globalState={globalState}
									currentFacility={currentFacility}
									currentUser={currentUser}
									dispatchToState={dispatchToState}
									dispatchAlert={dispatchAlert}
								/>
							</FullPageTabPanel>
						</FullPageTabPanels>
					</FullPageTabsController>
				</div>
			</div>

			{/* FLOATING LOGOUT BUTTON */}
			<LogoutButton handleLogout={handleLogout} />

			{AlertsHandler}
		</>
	);
};

export default AdminPage;

AdminPage.defaultProps = {};

AdminPage.propTypes = {
	history: PropTypes.object,
};
