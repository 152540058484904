import React from "react";
import styles from "../../css/vendor/VendorTableActions.module.scss";
import { PropTypes } from "prop-types";

const VendorTableActions = ({ initNewRowEntry }) => {
	return (
		<div className={styles.VendorTableActions}>
			<button
				onClick={initNewRowEntry}
				className={styles.VendorTableActions_new}
			>
				New Entry
			</button>
		</div>
	);
};

export default VendorTableActions;

VendorTableActions.defaultProps = {};

VendorTableActions.propTypes = {};
