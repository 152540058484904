import React from "react";
import styles from "../../css/resident/ResidentSummary.module.scss";
import { PropTypes } from "prop-types";
import { getResidentName } from "../../helpers/utils_resident";
import Spinner from "../shared/Spinner";

const ResidentSummary = ({
	currentResident = {},
	targetFacility = {},
	childFacility = {},
	parentFacility = {},
	isLoading = false,
	residentNotFound = false,
}) => {
	console.log("currentResident", currentResident);

	if (residentNotFound) {
		return (
			<div className={styles.ResidentSummaryLoader}>
				<div className={styles.ResidentNotFound}>Resident Not Found</div>
			</div>
		);
	}
	if (isLoading) {
		return (
			<div className={styles.ResidentSummaryLoader}>
				<Spinner />
			</div>
		);
	}
	return (
		<div className={styles.ResidentSummary}>
			<div className={styles.ResidentSummary_info}>
				<div className={styles.ResidentSummary_info_item}>
					Resident Name: <b>{currentResident?.residentName}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Resident Status: <b>{currentResident?.status ?? ""}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Current Community: <b>{childFacility?.communityName ?? ""}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Current Parent: <b>{parentFacility?.communityName ?? ""}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Current Unit: <b>{currentResident?.unitNumber ?? ""}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Current Unit Type: <b>{currentResident?.unitType ?? ""}</b>
				</div>
				<div className={styles.ResidentSummary_info_item}>
					Current State: <b>{childFacility?.address?.state ?? ""}</b>
				</div>
			</div>
		</div>
	);
};

export default ResidentSummary;

ResidentSummary.defaultProps = {};

ResidentSummary.propTypes = {};
