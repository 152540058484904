import React, { useState } from "react";
import styles from "../../css/facility/FacilityAdmin.module.scss";
import { PropTypes } from "prop-types";
import MakeUserAdmin from "./MakeUserAdmin";
import NewFacilityAdmin from "./NewFacilityAdmin";

// MAKEUSERADMIN DEPS:
// selectedUser,
// 	facilityUsers = [],
// 	handleSelection,
// 	cancelAdmin,
// 	saveAsAdmin,
//
// NEWFACILITYADMIN DEPS:
// vals,
// 	handleChange,
// 	handlePasswordGeneration,
// 	handleCopy,
// 	wasCopied,

const AdminOption = ({ optionText, handleOption, isDisabled = false }) => {
	return (
		<button
			type="button"
			className={styles.AdminOption}
			onClick={handleOption}
			disabled={isDisabled}
		>
			{optionText}
		</button>
	);
};

const FacilityAdmin = ({
	vals = {},
	usersToMakeAdmin,
	facilityUsers = [],
	handleCopy,
	handleChange,
	selectUserToMakeAdmin,
	handleSelectFacility,
	handlePasswordGeneration,
	wasCopied,
	cancelAdmin,
	saveAsAdmin,
	createNewAdmin,
	cancelNewAdmin,
	currentFacility = {},
}) => {
	const [adminMethod, setAdminMethod] = useState(null);

	const handleAdminMethod = (method) => {
		if (adminMethod === method) return setAdminMethod(null);
		setAdminMethod(method);
	};

	return (
		<div className={styles.FacilityAdmin}>
			<div className={styles.FacilityAdmin_header}>
				<div className={styles.FacilityAdmin_header_title}>
					Create New Admin (other user types below)
				</div>
			</div>
			<div className={styles.FacilityAdmin_choices}>
				<AdminOption
					optionText="Create New Admin"
					isDisabled={adminMethod === "EXISTING_USER"}
					handleOption={() => handleAdminMethod("NEW_USER")}
				/>
				<AdminOption
					optionText="Assign Existing User as Admin"
					isDisabled={adminMethod === "NEW_USER"}
					handleOption={() => handleAdminMethod("EXISTING_USER")}
				/>
			</div>
			<div className={styles.FacilityAdmin_options}>
				{/* MAKE EXISTING USER FACILITY ADMIN */}
				{adminMethod === "EXISTING_USER" && (
					<div className={styles.FacilityAdmin_options_option}>
						<MakeUserAdmin
							selectedUsers={usersToMakeAdmin}
							facilityUsers={facilityUsers}
							handleSelectFacility={handleSelectFacility}
							handleSelection={selectUserToMakeAdmin}
							cancelAdmin={cancelAdmin}
							saveAsAdmin={saveAsAdmin}
						/>
					</div>
				)}
				{/* CREATE NEW FACILITY ADMIN */}
				{adminMethod === "NEW_USER" && (
					<div className={styles.FacilityAdmin_options_option}>
						<NewFacilityAdmin
							vals={vals}
							wasCopied={wasCopied}
							cancelNewAdmin={cancelNewAdmin}
							createNewAdmin={createNewAdmin}
							handleCopy={handleCopy}
							handleChange={handleChange}
							handlePasswordGeneration={handlePasswordGeneration}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default FacilityAdmin;

FacilityAdmin.defaultProps = {};

FacilityAdmin.propTypes = {};
