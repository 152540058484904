import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/facility/MoveIndependentToParent.module.scss";
import { PropTypes } from "prop-types";
import CustomSelect from "../shared/CustomSelect";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getAllParentFacilities,
	getAndProcessParents,
	moveFacilityTo,
	matchFacilityByName,
} from "../../helpers/utils_facility";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";

// REQUIREMENTS:
// - Option #1: Moves an existing facility to a new parent (eg. parentA => parentB)

// UI DESIGN IDEAS:
// - Show dropdown of parent facilities ONLY!!!

const customCSS = {
	target: {
		backgroundColor: "#ffffff",
		width: "35rem",
	},
	cancel: {
		padding: "1rem 1.8rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const sortParents = (parents) => {
	if (isEmptyArray(parents) || !parents) return [];

	const sorted = parents.sort((a, b) =>
		a.communityName.localeCompare(b.communityName)
	);
	return sorted.map((x) => x.communityName);
};

const MoveIndependentToParent = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
}) => {
	const [allParents, setAllParents] = useState([]);
	const [targetFacility, setTargetFacility] = useState(null);

	const handleTargetSelect = (selection) => {
		const target = selection?.[0] ?? "";
		setTargetFacility(target);
	};

	const saveIndependentToParent = async () => {
		const { token, facilities } = currentUser;
		const { facilityID } = currentFacility;
		const match = matchFacilityByName(targetFacility, facilities);
		const { facilityID: targetParentID } = match;
		// 'currentFacility' => to new parent (ie 'targetFacility')
		const wasMoved = await moveFacilityTo(token, {
			actionType: "Move Independent Under Parent",
			childFacilityID: facilityID,
			parentFacilityID: targetParentID,
			scheduleFor: null,
		});

		if (wasMoved) {
			return dispatchAlert("SUCCESS", {
				heading: `Independent was moved under parent!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred`,
				subheading: `Please try again later.`,
			});
		}
	};

	const cancelMove = () => {
		setTargetFacility("");
		setAllParents([]);
	};

	// fetches & processes all parents
	const getParents = useCallback(async () => {
		const { token } = currentUser;
		const parents = await getAndProcessParents(token);

		if (!isEmptyArray(parents)) {
			return setAllParents(parents);
		} else {
			return setAllParents([]);
		}
	}, [currentUser]);

	// fetches children from 'currentFacility's 'parentID', if applicable
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getParents();

		return () => {
			isMounted = false;
		};
	}, [currentFacility.facilityID, getParents]);

	return (
		<div className={styles.MoveIndependentToParent}>
			<section className={styles.MoveIndependentToParent_section}>
				<label htmlFor="targetParentFacility">
					Select Parent to Move Facility to:
				</label>
				<CustomSelect
					key={`Parents: ${allParents?.length}`}
					name="targetParentFacility"
					id="targetParentFacility"
					placeholder="Select target facility..."
					disableMultiSelect={true}
					options={sortParents(allParents)}
					handleCustomSelect={handleTargetSelect}
					customStyles={customCSS.target}
				/>
			</section>
			<section className={styles.MoveIndependentToParent_actions}>
				<ButtonSM handleClick={cancelMove} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={
						isEmptyVal(currentFacility.facilityID) || isEmptyVal(targetFacility)
					}
					handleClick={saveIndependentToParent}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</section>
		</div>
	);
};

export default MoveIndependentToParent;

MoveIndependentToParent.defaultProps = {};

MoveIndependentToParent.propTypes = {};
