import React, { useState, useEffect } from "react";
import styles from "../../css/sso/SSOController.module.scss";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";
import {
	getSSOSettings,
	saveSSOAndClearCache,
	saveSSOSettings,
} from "../../helpers/utils_sso";

const customCSS = {
	save: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: "1.5rem 3rem",
		borderRadius: "5rem",
		fontSize: "1.7rem",
		fontWeight: "600",
	},
};

const SSOController = ({ currentUser, dispatchAlert }) => {
	const [isSSOEnabled, setIsSSOEnabled] = useState(false);
	const [rawSSO, setRawSSO] = useState({});

	const handleCheckbox = (e) => {
		const { checked } = e.target;
		setIsSSOEnabled(checked);
	};

	// saves changes to SSO
	const saveSSOChanges = async () => {
		const { token } = currentUser;
		const wasUpdated = await saveSSOSettings(token, isSSOEnabled);

		console.group("SSO Update");
		console.log("SSO Updated:", wasUpdated);
		console.groupEnd();

		if (wasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `SSO was ${isSSOEnabled ? "ENABLED!" : "DISABLED!"}`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! Something didn't work. Please try again.`,
			});
		}
	};

	const getSSOStatus = async () => {
		const { token } = currentUser;
		const settings = await getSSOSettings(token);
		setRawSSO(settings);
		setIsSSOEnabled(settings?.Display);

		console.log("settings", settings);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getSSOStatus();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.SSOController}>
			<CustomCheckbox
				name="enableSSO"
				id="enableSSO"
				label="Enable SSO"
				val={isSSOEnabled}
				handleCheckbox={handleCheckbox}
			/>
			<div className={styles.SSOController_actions}>
				<ButtonSM customStyles={customCSS.save} handleClick={saveSSOChanges}>
					Save SSO Changes
				</ButtonSM>
			</div>
		</div>
	);
};

export default SSOController;

SSOController.defaultProps = {};

SSOController.propTypes = {};
