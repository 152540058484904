import {
	disableAllFacilityUsersToEmar,
	disableFacilityToEmar,
	enableFacilityToEmar,
	removeFacilityToEmar,
} from "./utils_emar";
import { facility } from "./utils_endpoints";
import { currentEnv } from "./utils_env";

// REASON TYPES:
const SUSPEND_REASONS = {
	Cancelled: 1,
	"Non-Payment": 2,
	"No Contact": 3,
	"Left Parent": 4,
	"Switched Software": 5,
	"Went to Paper": 6,
	Other: 7,
};

/**
 * Any of the below reasons for suspending an account means we should also disable/remove their EMAR access, as well.
 * - 'Cancelled': 1
 * - 'Left Parent': 4
 * - 'Switched Software': 5,
 * - 'Went to Paper': 6,
 */
const DISABLE_EMAR_REASONS = {
	nameMap: {
		Cancelled: 1,
		"Left Parent": 4,
		"Switched Software": 5,
		"Went to Paper": 6,
	},
	idMap: {
		1: "Cancelled",
		4: "Left Parent",
		5: "Switched Software",
		6: "Went to Paper",
	},
	ids: [1, 4, 5, 6],
	labels: ["Cancelled", "Left Parent", "Switched Software", "Went to Paper"],
};

/**
 * Fetches list of currently suspended facilities.
 * @param {String} token - Auth token
 * @returns {Object[]} - Returns array of suspended facility objects
 */
const getSuspendedFacilities = async (token) => {
	let url = currentEnv.base + facility.suspend.getSuspendedFacilities;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// fetches 'SuspendReasonTypes'
const getSuspendReasons = async (token, params = {}) => {
	let url = currentEnv.base + facility.suspend.getReasons;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Suspends a facility's account.
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facility guid to be suspended.
 * @param {Object} suspendVals - An object of suspend data values: 'suspendReasonId', 'suspendComment', 'scheduleFor'
 * @property {Number} suspendVals.suspendReasonId - ID that represents a specific suspension reason record.
 * @property {String} suspendVals.suspendComment - Additional info regarding the suspension, such as which software they switched to, why they left etc.
 * @property {Date} suspendVals.scheduleFor - Date that the suspension should be executed. If null, then run immediately.
 * @returns {Boolean} - Returns whether suspension was successful.
 */
const suspendFacilityAccount = async (token, facilityId, suspendVals = {}) => {
	const { suspendReasonId, suspendComment, scheduleFor } = suspendVals;
	let url = currentEnv.base + facility.suspend.facilityAccount;
	url += "?" + new URLSearchParams({ facilityId });
	url += "&" + new URLSearchParams({ suspendReasonId });
	url += "&" + new URLSearchParams({ suspendComment });
	url += "&" + new URLSearchParams({ scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Unsuspends a facility account (ie re-activates).
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid string
 * @returns {Boolean} - Return whether 'unsuspend' was true.
 */
const unsuspendFacilityAccount = async (token, facilityId) => {
	let url = currentEnv.base + facility.unsuspend.facilityAccount;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Handles suspending and/or removing a facility's EMAR access.
 * @param {String} token - Auth token
 * @param {String} facilityId - guid Facility string
 * @param {Object} suspendVals - Object of suspension values such as: 'reason', 'comment', 'suspension date' etc.
 * @returns {Object} - Returns object of booleans. Returns whether facility and emar were disabled/removed
 */
const suspendFacilityAndEmar = async (token, facilityId, suspendVals = {}) => {
	const emarID = 19;
	const { suspendReasonId } = suspendVals;
	const isLeaving = isLeavingALA(suspendReasonId);

	// if 'isLeaving' then remove EMAR access
	if (isLeaving) {
		const [wasSuspended, wasEmarRemoved, wasUsersAccessRemoved] =
			await Promise.all([
				suspendFacilityAccount(token, facilityId, suspendVals),
				removeFacilityToEmar(token, facilityId, emarID),
				disableAllFacilityUsersToEmar(token, facilityId, emarID),
			]);

		return {
			wasSuspended: wasSuspended,
			wasEmarRemoved: wasEmarRemoved,
			wasUsersAccessRemoved: wasUsersAccessRemoved,
		};
	} else {
		const wasSuspended = await suspendFacilityAccount(
			token,
			facilityId,
			suspendVals
		);

		return {
			wasSuspended: wasSuspended,
			wasEmarRemoved: null,
			wasUsersAccessRemoved: null,
		};
	}
};
/**
 * Handles un-suspending a facility & re-enabling their facility's EMAR access. (still requires turning users on tho)
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object} - Returns object w/ boolean values
 */
const unsuspendFacilityAndEmar = async (token, facilityId) => {
	const emarID = 19;

	const [wasUnsuspended, wasEmarEnabled] = await Promise.all([
		unsuspendFacilityAccount(token, facilityId),
		enableFacilityToEmar(token, facilityId),
	]);

	return {
		wasUnsuspended: wasUnsuspended,
		wasEmarEnabled: wasEmarEnabled,
	};
};

// formatts 'suspend' reasons for the client
const processSuspendReasons = (reasons = []) => {
	const formatted = reasons.map((reason) => {
		const {
			Name: name,
			Description: desc,
			SuspendReasonTypeId: suspendReasonID,
			IsActive: isActive,
			CreatedDate: dateCreated,
		} = reason;

		const clientReason = {
			name,
			desc,
			suspendReasonID,
			isActive,
			dateCreated,
		};
		return clientReason;
	});
	return formatted;
};

// find matching 'suspend' type from a text description/name value
const matchSuspendReasonByDesc = (desc, reasons = []) => {
	const match = reasons.filter((type) => {
		const { Name, Description } = type;
		if (desc === Name || desc === Description) {
			return type;
		} else {
			return null;
		}
	});

	return match?.[0];
};
// find matching 'suspend' type from a text description/name value
// uses client-formatted suspend reasons
const matchSuspendReasonByClientDesc = (desc, reasons = []) => {
	const match = reasons.filter((type) => {
		const { name, desc: typeDesc } = type;
		if (desc === typeDesc || desc === name) {
			return type;
		} else {
			return null;
		}
	});
	return match?.[0];
};

const matchReasonIDFromDesc = (desc) => {
	const { labels, nameMap } = DISABLE_EMAR_REASONS;

	// if 'desc' matches one of the 'cancellation' reasons
	// - then get the matching reason id
	// - otherwise just return
	if (labels.includes(desc)) {
		const reasonID = nameMap[desc] ?? null;

		return reasonID;
	} else {
		const reasonID = nameMap[desc] ?? null;

		return reasonID;
	}
};

// check if suspension reason qualifies for removing EMAR access
/**
 * Checks if an account's reason for suspension means they're leaving ALA's service.
 * @param {Number} suspendReasonID - 'SuspendReasonID' value. ID for suspension reason.
 * @returns {Boolean} - Returns whether the given 'suspendReasonID' means the facility is leaving the ALA service.
 */
const isLeavingALA = (suspendReasonID) => {
	const { ids: reasonIDs } = DISABLE_EMAR_REASONS;
	const isLeaving = reasonIDs.includes(suspendReasonID);

	return isLeaving;
};

export {
	getSuspendedFacilities,
	getSuspendReasons,
	suspendFacilityAccount,
	unsuspendFacilityAccount,
	// un/suspend facility & emar
	suspendFacilityAndEmar,
	unsuspendFacilityAndEmar,
};

export {
	SUSPEND_REASONS,
	DISABLE_EMAR_REASONS,
	isLeavingALA,
	processSuspendReasons,
	matchSuspendReasonByDesc,
	matchSuspendReasonByClientDesc,
	matchReasonIDFromDesc,
};
