import { emar, chartmeds, generic } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { genericGet } from "./utils_params";

////////////////////////////////////////////////////////////////
/////////////////////// EMAR OPTIONS //////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Fetches a list of available EMAR Interfaces
 * @param {String} token - Auth token
 * @returns {Array} - Returns list of available EMAR Interfaces
 */
const getEmarsList = async (token) => {
	const params = {
		...genericGet?.application,
		ApplicationTypeID: 8,
	};
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
///////////////////////// AUTH EMAR ////////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Single-sign-on for ChartMeds for a given user, facility & resident
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @param {Numer} residentId - Resident id
 * @returns {Object}
 */
const emarSSO = async (token, facilityId, userId, residentId) => {
	let url = currentEnv.base + emar.sso;
	url += "?" + new URLSearchParams({ facilityId, userId, residentId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
/////////////////////// FACILITY EMAR //////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Adds a facility to an EMAR and auto-enables that facility for EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {Number} applicationId - Application number for specific EMAR
 * @returns {Object}
 */
const addFacilityToEmar = async (token, facilityId, applicationId) => {
	let url = currentEnv.base + emar.facility.addFacility;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Removes a facility's access to an EMAR and disables all user's at that community's access
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {Number} applicationId - Application number for specific EMAR
 * @returns {Object}
 */
const removeFacilityToEmar = async (token, facilityId, applicationId) => {
	let url = currentEnv.base + emar.facility.removeFacility;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Enables a disabled facility's EMAR access EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const enableFacilityToEmar = async (token, facilityId) => {
	let url = currentEnv.base + emar.facility.enableFacility;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Adds a facility to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const disableFacilityToEmar = async (token, facilityId) => {
	let url = currentEnv.base + emar.facility.disableFacility;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Enables all users at a given facility for EMAR access
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const enableAllFacilityUsersToEmar = async (
	token,
	facilityId,
	applicationId
) => {
	let url = currentEnv.base + emar.facility.enableAllUsers;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Disables all users at a given facility for EMAR access
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @returns {Object}
 */
const disableAllFacilityUsersToEmar = async (
	token,
	facilityId,
	applicationId
) => {
	let url = currentEnv.base + emar.facility.disableAllUsers;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

////////////////////////////////////////////////////////////////
///////////////////////// USER EMAR ////////////////////////////
////////////////////////////////////////////////////////////////

/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const addUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.addUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const enableUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.enableUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Adds a user to the 'ChartMeds' EMAR
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid
 * @param {String} userId - User guid
 * @returns {Object}
 */
const disableUserToEmar = async (token, facilityId, userId) => {
	let url = currentEnv.base + emar.user.disableUser;
	url += "?" + new URLSearchParams({ facilityId, userId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

export { getEmarsList };

// auth
export { emarSSO };

// facility request utils
export {
	addFacilityToEmar,
	removeFacilityToEmar,
	enableFacilityToEmar,
	disableFacilityToEmar,
	enableAllFacilityUsersToEmar,
	disableAllFacilityUsersToEmar,
};

// user request utils
export { addUserToEmar, enableUserToEmar, disableUserToEmar };
