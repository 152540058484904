import React from "react";
import styles from "../css/views/DisassociateView.module.scss";
import { PropTypes } from "prop-types";
import Divider from "../components/forms/Divider";
import DisassociateFacilityFromParent from "../components/disassociate/DisassociateFacilityFromParent";
import DisassociateFacilityFromUsers from "../components/disassociate/DisassociateFacilityFromUsers";
import {
	disassociateFacilitiesFromParent,
	disassociateFacilitiesFromUsers,
	getFacilityIDsFromNames,
} from "../helpers/utils_facility";

const DisassociateView = ({
	globalState = {},
	currentFacility = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { parentsMap } = globalState;
	const { raw: facilities, ids, names } = parentsMap;

	const handleDisassociateParent = async (selectedNames = []) => {
		const { token } = currentUser;
		// Get Facility ID(s) into array
		const facilityIDs = getFacilityIDsFromNames(selectedNames, facilities);
		console.log("facilityIDs", facilityIDs);
		// Fire off request
		// const wasSaved = true;
		const wasSaved = await disassociateFacilitiesFromParent(token, facilityIDs);
		// Dispatch alert for success/failure status
		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Update was saved.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an issue. Please try again.`,
			});
		}
	};
	const handleDisassociateUsers = async (selectedNames = []) => {
		const { token } = currentUser;
		// Get Facility ID(s) into array
		const facilityIDs = getFacilityIDsFromNames(selectedNames, facilities);
		console.log("facilityIDs", facilityIDs);
		// Fire off request
		const wasSaved = await disassociateFacilitiesFromUsers(token, facilityIDs);
		// Dispatch alert for success/failure status
		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Update was saved.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an issue. Please try again.`,
			});
		}
	};

	return (
		<div className={styles.DisassociateView}>
			<div className={styles.DisassociateView_header}>
				<h2 className={styles.DisassociateView_header_title}>
					Disassociate Facility or Users{" "}
				</h2>
				<p className={styles.DisassociateView_header_desc}>
					Remove/disassociate users and/or facilities from their parent
					community.
				</p>
			</div>
			<div className={styles.DisassociateView_main}>
				<div className={styles.DisassociateView_main_section}>
					<h4 className={styles.DisassociateView_main_section_subtitle}>
						Disassociate Facilities From a Parent{" "}
					</h4>
					<div className={styles.DisassociateView_main_section_ui}>
						<DisassociateFacilityFromParent
							facilities={names}
							parentsMap={parentsMap}
							saveHandler={handleDisassociateParent}
						/>
					</div>
				</div>
				<Divider />
				<div className={styles.DisassociateView_main_section}>
					<h4 className={styles.DisassociateView_main_section_subtitle}>
						Disassociate Facilities From Its' Users{" "}
					</h4>
					<div className={styles.DisassociateView_main_section_ui}>
						<DisassociateFacilityFromUsers
							facilities={names}
							parentsMap={parentsMap}
							saveHandler={handleDisassociateUsers}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DisassociateView;

DisassociateView.defaultProps = {};

DisassociateView.propTypes = {};
