import React, { useState } from "react";
import styles from "../../css/banners/BannersController.module.scss";
import { PropTypes } from "prop-types";
import { purple } from "../../helpers/utils_styles";
import { isEmptyArray } from "../../helpers/utils_types";
import { acknowledgeBy, getBannerIDs } from "../../helpers/utils_banners";
// components
import NewBannerForm from "./NewBannerForm";
import CurrentBannersList from "./CurrentBannersList";
import UrgentBanner from "./UrgentBanner";
import ServiceBanner from "./ServiceBanner";
import ButtonSM from "../shared/ButtonSM";
import { initialSettings, useAlerts } from "../../utils/useAlerts";

// REQUIREMENTS:
// - Load banner by:
//    - Facility
//    - All currently enabled/active

const customCSS = {
	active: {
		backgroundColor: purple[700],
		color: "#ffffff",
		boxShadow: `0 14px 28px rgba(0, 0, 0, 0.25),
	0 10px 10px rgba(0, 0, 0, 0.22)`,
	},
};

const warningMsg = `By clicking 'Acknowledge' you are confirming that you've read the above notice(s).`;

const bannerTypes = {
	NEW: "Create a new banner type",
	EXISTING: "View/edit existing banners",
};

// generates a facility map by 'facilityID'
const makeFacilityMapByID = (parentsMap = {}) => {
	const { raw = [] } = parentsMap;
	const newMap = raw?.reduce((mapByID, entry) => {
		const id = entry?.facilityID;
		if (!mapByID[id]) {
			mapByID[id] = { ...entry };
			return mapByID;
		}
		return mapByID;
	}, {});

	return newMap;
};

// merges 'communityName' into its' corresponding record
const mergeFacilityNameIntoList = (banners = [], parentsMap = {}) => {
	const idMap = makeFacilityMapByID(parentsMap);

	const newList = banners.map((entry, idx) => {
		const id = entry?.FacilityId;
		const record = idMap[id];
		const name = record?.communityName ?? record?.CommunityName ?? record?.name;

		const newEntry = {
			...entry,
			FacilityName: name,
		};
		return newEntry;
	});

	return newList;
};

const BannersController = ({
	userBanners = [], // REMOVE THIS!!! //
	// REMOVE ABOVE THIS //
	activeBanners = [],
	currentUser = {},
	currentFacility = {},
	parentsMap = {},
	facilitiesMap = {},
	refetchBanners,
}) => {
	// const { raw: facilities, ids, names } = parentsMap;
	const { raw: facilities, ids, names } = facilitiesMap;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);

	const [activeSection, setActiveSection] = useState("");
	// TESTING PURPOSE - REMOVE LATER!!! //
	const [showUrgentBanner, setShowUrgentBanner] = useState(true);

	const handleSections = (type) => {
		if (activeSection === type) {
			return setActiveSection("");
		} else {
			return setActiveSection(type);
		}
	};

	return (
		<div className={styles.BannersController}>
			<div className={styles.BannersController_options}>
				<div
					className={styles.BannersController_options_item}
					onClick={() => handleSections("EXISTING")}
					style={activeSection === "EXISTING" ? customCSS.active : {}}
				>
					Show All Active Service Banners
				</div>
				<div
					className={styles.BannersController_options_item}
					onClick={() => handleSections("NEW")}
					style={activeSection === "NEW" ? customCSS.active : {}}
				>
					Create a New Service Banner
				</div>
			</div>

			{activeSection === "NEW" && (
				<section className={styles.BannersController_section}>
					<NewBannerForm
						currentUser={currentUser}
						currentFacility={currentFacility}
						refetchBanners={refetchBanners}
						dispatchAlert={dispatchAlert}
					/>
				</section>
			)}
			{activeSection === "EXISTING" && (
				<section className={styles.BannersController_section}>
					<CurrentBannersList
						currentUser={currentUser}
						currentFacility={currentFacility}
						activeBanners={[
							...mergeFacilityNameIntoList(activeBanners, facilitiesMap),
						]}
						parentsMap={parentsMap}
						facilitiesMap={facilitiesMap}
						refetchBanners={refetchBanners}
						dispatchAlert={dispatchAlert}
					/>
				</section>
			)}

			{AlertsHandler}
		</div>
	);
};

export default BannersController;

BannersController.defaultProps = {};

BannersController.propTypes = {};
