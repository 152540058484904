import React from "react";
import styles from "../../css/charts/BarChartWrapper.module.scss";
import { PropTypes } from "prop-types";
import { Bar } from "react-chartjs-2";
import { blueGrey, purple } from "../../helpers/utils_styles";

const baseOptions = {
	scales: {
		yAxis: [
			{
				gridLines: {
					color: "#ffffff",
				},
			},
		],
		xAxis: [
			{
				gridLines: [
					{
						color: "#ffffff",
					},
				],
			},
		],
	},
};

const mockData = {
	// X-Axis
	labels: ["Jan", "Feb", "Mar", "Apr", "May"],

	datasets: [
		{
			label: "Logins By Month",
			data: [12, 35, 14, 23, 18], // Y-Axis data points (1 line)
			fill: false,
			backgroundColor: "rgb(255, 99, 132)",
			borderColor: "rgba(255, 99, 132, 0.9)",
		},
		{
			label: "Blah",
			data: [10, 20, 30, 40, 32], // Y-Axis data points (1 line)
			fill: false,
			backgroundColor: purple[200],
			borderColor: purple[500],
		},
	],
};

const BarChartWrapper = ({
	title,
	subtitle,
	data = mockData,
	chartOptions = { ...baseOptions },
}) => {
	return (
		<div className={styles.BarChartWrapper}>
			<div className={styles.LineChartWrapper_header}>
				<h4 className={styles.LineChartWrapper_header_title}>{title}</h4>
				<p className={styles.LineChartWrapper_header_title}>{subtitle}</p>
			</div>
			<div className={styles.LineChartWrapper_chart}>
				<Bar data={data} options={chartOptions} />
			</div>
		</div>
	);
};

export default BarChartWrapper;

BarChartWrapper.defaultProps = {};

BarChartWrapper.propTypes = {};
