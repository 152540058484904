import { currentEnv } from "./utils_env";
import { getFileRegistry, downloads, uploads } from "./utils_endpoints";
import { format } from "date-fns";

/**
 * @description - Fetches a PDF file from ALA Services, and inits a PDF mirror via an embed element
 * @param {String} token - A base64 encoded auth token.
 * @param {Number} id - A "FileRegistryID" that refers to a file record in the ALA DMS.
 * @returns {Blob} - Returns a converted response object as a "Blob" instance. Used for embeddable content, mirror, file downloads etc.
 */
const getFileBlob = async (token, id) => {
	let url = currentEnv.base + downloads.getFile;
	url += "?id=" + id;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const blob = await request.blob();
		return blob;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a list of file records (ie 'FileRegistry') for a given facility.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByFacility = async (token, facilityID) => {
	let url = currentEnv.base + getFileRegistry.byFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a list of file records (ie 'FileRegistry') for a given resident.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByResident = async (token, residentID) => {
	let url = currentEnv.base + getFileRegistry.byResident;
	url += "?" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * Fetches a list of file records (ie 'FileRegistry') for a given user.
 * @returns {Array|Null} - Returns an array of files or null, if empty.
 */
const getFileRegistryByUser = async (token, userID) => {
	let url = currentEnv.base + getFileRegistry.byUser;
	url += "?" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 *
 * @param {String} token - Auth token
 * @param {Number} id - Numeric file ID
 * @param {String} fileName - Target file name as a string, typically
 * @returns {Object} - Returns data object along with file
 */
const getFileAndZip = async (token, id, fileName) => {
	let url = currentEnv.base + downloads.getFileAndZip;
	url += "?" + new URLSearchParams({ id });
	url += "&" + new URLSearchParams({ zipFileName: fileName });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const blob = await request.blob();
		console.log(`Response Blob:`, blob);
		return blob;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * @description - Fetches a file blob, converts it to pdf and creates the blobURL for mirroring.
 * @param {String} token - Auth token
 * @param {Number|String} fileID - A file's unique identifier or filename (w/ complete path)
 */
const getPDFSource = async (token, fileID) => {
	const blob = await getFileBlob(token, fileID);
	const pdfBlob = createBlob(blob, "application/pdf");
	const pdfURL = createURL(pdfBlob);

	return pdfURL;
};
const getIMGSource = async (token, fileID) => {
	const blob = await getFileBlob(token, fileID);
	const url = createURL(blob);
	return url;
};

// creates form-data for a file upload
const getFormData = (fileName, file) => {
	const fileForm = new FormData();
	fileForm.append(fileName, file);
	return fileForm;
};

/**
 * Uploads a file via 'formData' method.
 * @param {String} token - Auth token.
 * @param {String|Number} fileIdOrName - A file ID or filename.
 * @param {File} file - A File object or file data (ie binary data etc.)
 * @returns {Boolean} - Returns whether upload was successful or not.
 */
const uploadFile = async (token, fileIdOrName, file) => {
	let url = currentEnv.base + uploads.upload;
	url += "?" + new URLSearchParams({ id: fileIdOrName });

	const data = getFormData(fileIdOrName, file);
	console.log(`Form Data:`, data);

	try {
		const request = await fetch(url, {
			method: "PUT",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				// "Content-Type": "multipart/form-data",
			},
			body: data,
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const fileOpts = {
	file: null,
	filename: "",
	contentType: "multipart/form-data",
};
const uploadFileByType = async (token, fileSettings = { ...fileOpts }) => {
	const { file, filename, contentType = "multipart/form-data" } = fileSettings;

	let url = currentEnv.base + uploads.upload;
	url += "?" + new URLSearchParams({ id: filename });

	const data = getFormData(filename, file);

	try {
		const request = await fetch(url, {
			method: "PUT",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": contentType,
			},
			body: data,
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * File processing & data utils:
 * - Create a file blob
 * - Create an objectURL
 * - Save a file to user's local machine
 * - Convert file size to XXXX unit
 */

/**
 * @description - A helper for converting data into a file blob w/ a custom mimetype.
 * @param {Blob|Response Object} data - Any transformable data type that can be converted to a blob. Typically a response object or blob.
 * @param {String} mimeType - A custom mimetype used to set the new Blob instance to.
 * @returns {Blob} - returns a file blob, w/ a custom mimetype.
 */
const createBlob = (data, mimeType = "application/octet-stream") => {
	return new Blob([data], { type: mimeType });
};

/**
 * @description - Utility that accepts a file blob and creates an object URL.
 * @param {Blob} blob - A file blob to be used for an object URL.
 */
const createURL = (blob) => {
	const fileURL = window.URL.createObjectURL(blob);
	return fileURL;
};

/**
 * @description - A utility for creating an object URI to trigger a file download to a user's machine.
 * @param {Blob} blob - A file blob, typically transformed from the HTTP response object
 * @param {String} filename - A custom filename used for saving the file to a user's machine.
 * @returns {Blob} - Returns a fileblob that's immediately downloaded to a user's machine.
 */
const saveFile = (blob, filename) => {
	const fileURL = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = fileURL;
	link.download = filename;
	link.click();
	return window.URL.revokeObjectURL(fileURL);
};

/**
 * Writes data to specific file type and filename.
 * @param {String|Object} data - Target 'data' to be written to a file.
 * @param {String} filename - Target filename.
 * @param {String} mimeType - A string-form mimeType.
 * @returns {File} - Returns file in specific mimeType
 */
const saveDataToFile = (
	data,
	filename = `Console.json`,
	mimeType = "text/plain"
) => {
	if (!data) throw new Error(`❌ Oops! No data provided.`);
	if (typeof data === "object") {
		// pretty format JSON data
		data = JSON.stringify(data, null, 4);
	}

	// create blob data; create <a/> tag
	const blob = new Blob([data], { type: mimeType });
	const link = document.createElement("a");

	// set attr's and click link
	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	return link.click();
};

const saveDataToCSV = (data, filename = "NewFile.csv") => {
	if (!data) throw new Error(`❌ Oops! No data provided.`);
	if (typeof data === "object") {
		// pretty format JSON data
		data = JSON.stringify(data, null, 4);
	}

	// create blob data; create <a/> tag
	// const type = `data:text/csv`;
	const type = `text/csv`;
	const blob = new Blob([data], { type: type });

	console.log("blob", blob);

	const link = document.createElement("a");

	// set attr's and click link
	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	return link.click();
};

// converts an array of objects into a csv string data w/ line-breaks & headers
const convertArrayDataToCSV = (data) => {
	if (!data) throw new Error(`❌ Oops! No data provided.`);

	// create manual headers for csv file[]
	const headers = [
		"Name",
		"Email",
		"Community",
		"Last Login",
		"Resident Count",
	].join(",");

	// convert data into csv format array values & push to 'lines' array
	const lines = [];
	[...data].forEach((entry, idx) => {
		const { UserName, Email, CommunityName, LastLogin, ResidentCount } = entry;
		const line = [
			UserName,
			Email,
			CommunityName,
			LastLogin,
			ResidentCount,
		].join(",");
		lines.push(line);
	});
	const headerData = headers + "\n";
	const csvData = lines.join("\n");
	const allData = headerData + csvData;

	return allData;
};

/**
 * Converts an array of objects into CSV format with custom headers at the top.
 * - NOTE: 'csvHeaders' MUST be keys that exist within each 'data' object
 * @param {Array} csvHeaders - An array of string headers
 * @param {Array} data - An array of objects to be converted
 * @returns {String} - Returns a CSV formatted string
 */
const createCsvFromArrayOfObjects = (csvHeaders = [], data = []) => {
	const headers = [...csvHeaders].join(",") + "\n";

	const lines = [];
	[...data].forEach((entry, idx) => {
		// need to extract header keys to grab data from each record
		const line = [];
		csvHeaders.forEach((key, idx) => {
			const value = entry[key];
			line.push(value);
		});
		lines.push(line);
	});
	const formattedLines = lines.join("\n");
	const mergedData = headers + formattedLines;

	return mergedData;
};

// converts x bytes to any unit between bytes-gb
const convertBytes = (bytes, to = "KB") => {
	switch (to) {
		case "B": {
			return bytes;
		}
		case "KB": {
			const size = (bytes / 1024).toFixed(2);
			return size;
		}
		case "MB": {
			const size = (bytes / 1024 / 1024).toFixed(2);
			return size;
		}
		case "GB": {
			const size = (bytes / 1024 / 1024 / 1024).toFixed(4);
			return size;
		}
		default:
			return bytes;
	}
};

// converts bytes to target unit & formats w/ unit abbreviation
const convertAndFormatBytes = (bytes, to = "KB") => {
	switch (to) {
		case "B": {
			return `${bytes} b`;
		}
		case "KB": {
			const size = (bytes / 1024).toFixed(2);
			return `${size} KB`;
		}
		case "MB": {
			const size = (bytes / 1024 / 1024).toFixed(2);
			return `${size} MB`;
		}
		case "GB": {
			const size = (bytes / 1024 / 1024 / 1024).toFixed(4);
			return `${size} GB`;
		}
		default:
			return `${bytes} b`;
	}
};

const convertDataToMimeType = (type = "csv", data) => {
	switch (type) {
		case "csv": {
			return data.join(",");
		}
		case "json": {
			return;
		}
		case "text": {
			return;
		}
		default:
			return;
	}
};

// MIME TYPES //

const mimeTypes = {
	pdf: "application/octet-stream",
	pdfAlt: "appliclation/pdf",
	text: "text/plain",
	xls: "application/vnd.ms-excel",
	xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	csv: "text/csv",
	word: "application/msword",
	docx: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

// FILENAME GENERATOR (for resident file imports)

// generates filename: "Import--<facilityID>--Residents--<date>.xlsx"
const generateImportFileName = (facilityID, fileExt) => {
	const prefix = `Import`;
	const suffix = `Residents`;
	const date = format(new Date(), "MMDDYYYY");
	let fileName = prefix;
	fileName += `--` + facilityID;
	fileName += `--` + suffix;
	fileName += `--` + date;
	fileName += fileExt;

	return fileName;
};

// file fetching utils
export {
	getFileBlob,
	getFileRegistry,
	getFileRegistryByFacility,
	getFileRegistryByResident,
	getFileRegistryByUser,
	getFileAndZip,
};

// file-type fetch & processing utils
export { getIMGSource, getPDFSource };

export { createBlob, createURL, saveFile, saveDataToFile, saveDataToCSV };

export {
	convertAndFormatBytes,
	convertBytes,
	convertArrayDataToCSV,
	createCsvFromArrayOfObjects,
};

export { getFormData, uploadFile, uploadFileByType };

export { mimeTypes, convertDataToMimeType };

export { generateImportFileName };
