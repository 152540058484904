import React from "react";
import styles from "../css/views/ReportsView.module.scss";
import { PropTypes } from "prop-types";
import AccordionContainer from "../components/shared/AccordionContainer";
import AccordionSection from "../components/shared/AccordionSection";
import AllActiveUsersReport from "../components/reports/AllActiveUsersReport";
import CommunityActiveUsersReport from "../components/reports/CommunityActiveUsersReport";

const ReportsView = ({
	globalState = {},
	currentFacility = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { parentsMap } = globalState;
	const { raw: facilities, ids, names } = parentsMap;

	return (
		<div className={styles.ReportsView}>
			<div className={styles.ReportsView_header}>
				<h1 className={styles.ReportsView_header_title}>Internal Reports</h1>
				<p className={styles.ReportsView_header_desc}>
					Select any of the various internal reports below to expand and click
					the title again to collapse the section.
				</p>
			</div>
			<div className={styles.ReportsView_main}>
				<AccordionContainer>
					<AccordionSection label="All Active Users Report">
						<AllActiveUsersReport
							globalState={globalState}
							currentUser={currentUser}
							currentFacility={currentFacility}
						/>
					</AccordionSection>
					<AccordionSection label="Community Active Users Report ">
						<CommunityActiveUsersReport
							globalState={globalState}
							currentUser={currentUser}
							currentFacility={currentFacility}
						/>
					</AccordionSection>
				</AccordionContainer>
			</div>
		</div>
	);
};

export default ReportsView;

ReportsView.defaultProps = {};

ReportsView.propTypes = {};
