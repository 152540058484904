import React, { useRef, useEffect } from "react";
import styles from "../../css/banners/UrgentBanner.module.scss";
// import sprite from "../../assets/icons/buttons.svg";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { DIALOG_ICONS as icons, red } from "../../helpers/utils_styles";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - Auto opens onMount
// - Forces the user to click a 'confirm' button to close the modal

const customCSS = {
	confirm: {
		padding: "1.5rem 2.5rem",
		fontSize: "2rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: red[600],
	},
};

const UrgentBanner = ({
	title = "Urgent Service Notice(s)",
	subtitle = "",
	closeModal,
	confirmAcknowledgement,
	warningMsg,
	children,
}) => {
	const largeModalRef = useRef();
	useLockBodyScroll();

	return (
		<aside className={styles.UrgentBanner} ref={largeModalRef}>
			<section className={styles.UrgentBanner_top}>
				<div className={styles.UrgentBanner_top_iconWrapper}>
					<svg className={styles.UrgentBanner_top_iconWrapper_icon}>
						<use xlinkHref={`${sprite}#icon-${icons["WARN"]}`}></use>
					</svg>
				</div>
				<div className={styles.UrgentBanner_top_titles}>
					<h4 className={styles.UrgentBanner_top_titles_title}>{title}</h4>
					<h4 className={styles.UrgentBanner_top_titles_subtitle}>
						{subtitle}
					</h4>
				</div>
			</section>
			<section className={styles.UrgentBanner_main}>{children}</section>
			<section className={styles.UrgentBanner_msg}>{warningMsg}</section>
			<section className={styles.UrgentBanner_actions}>
				<ButtonSM
					handleClick={confirmAcknowledgement}
					customStyles={customCSS.confirm}
				>
					Acknowledge
				</ButtonSM>
			</section>
		</aside>
	);
};

export default UrgentBanner;

UrgentBanner.defaultProps = {};

UrgentBanner.propTypes = {};
