import React from "react";
import styles from "../../css/facility/LoginsByDate.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";

const DateEntry = ({ date, loginsByDate = [] }) => {
	return (
		<li className={styles.DateEntry}>
			<div className={styles.DateEntry_date}>{date}: </div>
			<div className={styles.DateEntry_about}>
				{loginsByDate?.length} user login(s) found for this date.
			</div>
		</li>
	);
};

const LoginsByDate = ({ loginsByDate = {} }) => {
	const dateKeys = Object.keys(loginsByDate);

	return (
		<div className={styles.LoginsByDate}>
			<div className={styles.LoginsByDate_title}>
				Dates with Login Activity <b>({dateKeys?.length}):</b>
			</div>
			<ul className={styles.LoginsByDate_list}>
				{!isEmptyArray(dateKeys) &&
					dateKeys.map((date, idx) => (
						<DateEntry
							key={`Date:${date}-${idx}`}
							date={date}
							loginsByDate={loginsByDate[date]}
						/>
					))}
			</ul>
		</div>
	);
};

export default LoginsByDate;

LoginsByDate.defaultProps = {};

LoginsByDate.propTypes = {};
