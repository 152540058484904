import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "../../css/facility/FacilitySummary.module.scss";
import { PropTypes } from "prop-types";
import { getFacilityRelationRecord } from "../../helpers/utils_facility";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";

const FacilitySummary = ({ currentUser = {}, currentFacility = {} }) => {
	const [parentName, setParentName] = useState("");

	const getParentName = useCallback(async () => {
		const { token } = currentUser;
		const { parentID } = currentFacility;
		if (!parentID || isEmptyVal(parentID)) return;

		const record = await getFacilityRelationRecord(token, parentID);

		if (!isEmptyObj(record)) {
			return setParentName(record[0]?.CommunityName);
		} else {
			return setParentName("Unknown");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch parent facility upon 'currentFacility' change
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (currentFacility?.parentID) {
			getParentName();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility?.parentID, getParentName]);

	return (
		<div className={styles.FacilitySummary}>
			<div className={styles.FacilitySummary_main}>
				<div className={styles.FacilitySummary_main_item}>
					Community Name: <b>{currentFacility?.communityName}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					Community ID: <b>{currentFacility?.facilityID}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					Current Parent:{" "}
					<b>{isEmptyVal(parentName) ? "No parent" : parentName}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					Parent ID: <b>{currentFacility?.parentID ?? "No parent"}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					Current State: <b>{currentFacility?.address?.state}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					Current Time Zone: <b>{currentFacility?.timeZone}</b>
				</div>
				<div className={styles.FacilitySummary_main_item}>
					License Number: <b>{currentFacility?.licenseNumber}</b>
				</div>
			</div>
		</div>
	);
};

export default FacilitySummary;

FacilitySummary.defaultProps = {};

FacilitySummary.propTypes = {};
