import React, { useState } from "react";
import styles from "../../css/facility/NewFacilityAdmin.module.scss";
import { PropTypes } from "prop-types";
import { blue, green, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import EmailValidator from "../forms/EmailValidator";
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	name: {
		backgroundColor: "#ffffff",
		marginBottom: "2rem",
	},
	email: {
		marginBottom: "2rem",
	},
	adminPwd: {
		backgroundColor: "#ffffff",
	},
	save: {
		padding: "1rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: blue[600],
		marginLeft: ".5rem",
	},
	cancel: {
		padding: ".8rem 1.3rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.5rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
};

const enableNewAdminBtn = (values) => {
	const {
		// admin details
		adminEmail,
		adminFirstName,
		adminLastName,
		adminPassword,
	} = values;

	const isFilled =
		!isEmptyVal(adminEmail) &&
		!isEmptyVal(adminFirstName) &&
		!isEmptyVal(adminLastName) &&
		!isEmptyVal(adminPassword);

	return isFilled;
};

const NewFacilityAdmin = ({
	vals,
	handleChange,
	handlePasswordGeneration,
	handleCopy,
	wasCopied,
	createNewAdmin,
	cancelNewAdmin,
}) => {
	return (
		<div className={styles.NewFacilityAdmin}>
			{/* ADMIN DETAILS (HEADER) */}
			<div className={styles.NewFacilityAdmin_header}>
				<div className={styles.NewFacilityAdmin_header_title}>
					New Admin Details
				</div>
			</div>
			{/* ADMIN EMAIL ADDRESS */}
			<div className={styles.NewFacilityAdmin_section}>
				<EmailValidator
					label="Admin Email"
					name="adminEmail"
					id="adminEmail"
					initialVal={vals?.adminEmail}
					handleChange={handleChange}
					placeholder="Enter admin email..."
					customStyles={customCSS.email}
				/>
			</div>
			{/* ADMIN FIRST NAME */}
			<div className={styles.NewFacilityAdmin_section}>
				<TextInput
					key="adminFirstName"
					label="Admin First"
					name="adminFirstName"
					id="adminFirstName"
					val={vals?.adminFirstName}
					handleChange={handleChange}
					customStyles={customCSS.name}
					placeholder="Enter first name..."
				/>
			</div>
			{/* ADMIN LAST NAME */}
			<div className={styles.NewFacilityAdmin_section}>
				<TextInput
					key="adminLastName"
					label="Admin Last"
					name="adminLastName"
					id="adminLastName"
					val={vals?.adminLastName}
					handleChange={handleChange}
					customStyles={customCSS.name}
					placeholder="Enter last name..."
					autoComplete="off"
				/>
			</div>
			{/* ADMIN PASSWORD */}
			<div className={styles.NewFacilityAdmin_section}>
				<PasswordInput
					label="Admin Password"
					name="adminPassword"
					id="adminPassword"
					val={vals?.adminPassword}
					handleChange={handleChange}
					customStyles={customCSS.adminPwd}
					placeholder="Enter a password..."
					autoComplete="off"
					initialVisibility={true}
				/>
				<div className={styles.NewFacilityAdmin_section_actions}>
					<button
						type="button"
						onClick={() => handlePasswordGeneration("adminPassword")}
						className={styles.NewFacilityAdmin_section_actions_generateTemp}
					>
						Generate Password
					</button>
					<button
						type="button"
						onClick={() => handleCopy("adminPassword")}
						className={styles.NewFacilityAdmin_section_actions_copyTemp}
					>
						{wasCopied ? "Copied!" : "Copy Password"}
					</button>
				</div>
				<div className={styles.NewFacilityAdmin_section_save}>
					<ButtonSM
						handleClick={cancelNewAdmin}
						customStyles={customCSS.cancel}
					>
						Cancel
					</ButtonSM>
					<ButtonSM
						isDisabled={!enableNewAdminBtn(vals)}
						handleClick={createNewAdmin}
						customStyles={customCSS.save}
					>
						Create New Admin
					</ButtonSM>
				</div>
			</div>
		</div>
	);
};

export default NewFacilityAdmin;

NewFacilityAdmin.defaultProps = {};

NewFacilityAdmin.propTypes = {};
