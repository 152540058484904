import React from "react";
import styles from "../../css/facility/ReasonForSuspension.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { addMonths, subDays } from "date-fns";
// components
import CustomCheckbox from "../shared/CustomCheckbox";
import CustomDropdown from "../shared/CustomDropdown";
import TextInput from "../shared/TextInput";
import DatePickerSM from "../shared/DatePickerSM";

const customCSS = {
	inputs: {
		backgroundColor: "#ffffff",
	},
	reason: {
		width: "35rem",
	},
	date: {
		width: "35rem",
	},
};

const suspendReasons = [
	"Cancelled",
	"Non-Payment",
	"No Contact",
	"Left Parent",
	"Switched Software",
	"Went to Paper",
	"Other",
];

const ReasonForSuspension = ({
	vals = {},
	handleChange,
	handleCheckbox,
	handleSelections,
}) => {
	return (
		<div className={styles.ReasonForSuspension}>
			<div className={styles.ReasonForSuspension_main}>
				<div className={styles.ReasonForSuspension_main_selection}>
					<CustomCheckbox
						label="Suspend Account"
						name="markForSuspend"
						id="markForSuspend"
						val={vals?.markForSuspend}
						isDisabled={vals?.markForSuspendAndDelete}
						handleCheckbox={handleCheckbox}
					/>
				</div>
				<div className={styles.ReasonForSuspension_main_selection}>
					<CustomCheckbox
						label="Suspend & Delete Account"
						name="markForSuspendAndDelete"
						id="markForSuspendAndDelete"
						val={vals?.markForSuspendAndDelete}
						isDisabled={vals?.markForSuspend}
						handleCheckbox={handleCheckbox}
					/>
				</div>

				<div className={styles.ReasonForSuspension_main_selection}>
					<DatePickerSM
						name="targetDate"
						id="targetDate"
						label="Select Date to Suspend Account:"
						placeholder="Date to suspend..."
						val={vals?.targetDate}
						handleDate={handleSelections}
						customStyles={customCSS.date}
						focusMode={true}
						restrictions={{
							isActive: true,
							rangeStart: subDays(new Date(), 1),
							rangeEnd: addMonths(new Date(), 2),
						}}
					/>
				</div>
				<div className={styles.ReasonForSuspension_main_selection}>
					<CustomDropdown
						label="Reason for Suspension"
						name="reasonForSuspend"
						id="reasonForSuspend"
						placeholder="Select a reason..."
						selection={vals?.reasonForSuspend}
						setSelection={handleSelections}
						options={suspendReasons}
						customStyles={customCSS.reason}
					/>
				</div>
				<div className={styles.ReasonForSuspension_main_options}>
					{vals?.reasonForSuspend === "Left Parent" && (
						<TextInput
							key={`SuspendComment-LeftParent`}
							name="suspendComment"
							id="suspendComment"
							val={vals?.suspendComment}
							label="Specify Which Parent"
							handleChange={handleChange}
							customStyles={customCSS.inputs}
							placeholder="Enter parent..."
						/>
					)}
					{vals?.reasonForSuspend === "Other" && (
						<TextInput
							key={`SuspendComment-Other`}
							name="suspendComment"
							id="suspendComment"
							val={vals?.suspendComment}
							label="Enter Other Reason"
							handleChange={handleChange}
							customStyles={customCSS.inputs}
							placeholder="Enter reason..."
						/>
					)}
					{vals?.reasonForSuspend === "Switched Software" && (
						<TextInput
							key={`SuspendComment-SwitchedSoftware`}
							name="suspendComment"
							id="suspendComment"
							val={vals?.suspendComment}
							label="Which Software Have They Switched To?"
							handleChange={handleChange}
							customStyles={customCSS.inputs}
							placeholder="Enter software..."
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReasonForSuspension;

ReasonForSuspension.defaultProps = {};

ReasonForSuspension.propTypes = {};
