import React from "react";
import styles from "../../css/vendor/VendorMapsTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyObj } from "../../helpers/utils_types";
import VendorMapsTableRow from "./VendorMapsTableRow";
import VendorMapsTableColumns from "./VendorMapsTableColumns";

// TABLE COLUMNS:
// SOURCE:
// 	- ALA Source Name
// 	- Vendor Source Name
// FIELD-NAME:
// 	- ALA Field Name
// 	- Vendor Field Name
// FIELD-VALUE:
// 	- ALA Field Value
// 	- Vendor Field Value

const isRowSelected = (row, selectedRow) => {
	const selectedID = selectedRow?.data?.VendorDataMapID;
	const rowID = row?.VendorDataMapID;

	return selectedID === rowID;
};

const NoRecordsFound = () => {
	return (
		<div className={styles.NoRecordsFound}>
			<div className={styles.NoRecordsFound_msg}>
				No mapping records found for this vendor!
			</div>
		</div>
	);
};

const VendorMapsTable = ({
	rows = [],
	columns = [],
	selectRow,
	selectedRow,
	selectedVendor = {},
	hasLoaded = false,
}) => {
	console.log("selectedRow:", selectedRow);

	return (
		<div className={styles.VendorMapsTable}>
			<div className={styles.VendorMapsTable_columns}>
				<VendorMapsTableColumns columns={columns} />
			</div>
			<div className={styles.VendorMapsTable_rows}>
				{hasLoaded && isEmptyArray(rows) && <NoRecordsFound />}
				{!isEmptyArray(rows) &&
					!isEmptyObj(selectedVendor?.record) &&
					rows.map((row, idx) => (
						<VendorMapsTableRow
							key={`VENDOR-ROW-${row?.VendorDataMapID}-${idx}-${selectedRow?.data?.VendorDataMapID}`}
							row={row}
							selectRow={() => selectRow(idx, row)}
							isSelected={isRowSelected(row, selectedRow)}
						/>
					))}
			</div>
		</div>
	);
};

export default VendorMapsTable;

VendorMapsTable.defaultProps = {};

VendorMapsTable.propTypes = {};
