import { user } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { sortAlphaAscByKey } from "./utils_processing";
import { isEmptyObj, isEmptyVal } from "./utils_types";
import { emailAnywhereReg, getMatchGroups } from "./utils_validation";

/**
 * 'User Requests':
 * - Various user-related http request utils.
 */

const pageParams = {
	index: 0,
	rows: 100,
};

// USER TYPES

const USER_TYPES = [
	`Administrator`,
	`Facility Administrator`,
	`Super User`,
	`Contactor`,
	`Manager`,
	`Staff`,
];

// fetches ALL users by user email
const getAllUsersByUserEmail = async (token, params = { ...pageParams }) => {
	let url = currentEnv.base + user.getAllUsers;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetch a user's custom ALA Profile.
 * - Returns a profile 'massaged' by ALA Services.
 */
const getUserProfile = async (token, userID) => {
	let url = currentEnv.base + user.getProfile;
	url += "?userId=" + userID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};
const getUserProfileByEmail = async (token, email) => {
	let url = currentEnv.base + user.getProfileByEmail;
	url += "?" + new URLSearchParams({ userEmail: email });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const profile = response.Data;
		return profile;
	} catch (err) {
		return;
	}
};

const processUserProfile = (profile = {}) => {
	const {
		AdvUser,
		AdvUserCommunities = [],
		UserLogin,
		UserLoginFacilities = [],
	} = profile;

	console.log("profile", profile);

	return {
		AdvUser,
		AdvUserCommunities,
		UserLogin,
		UserLoginFacilities,
	};
};

// PROCESS 'USERINFO'
const processProfile = (profile = {}) => {
	return {
		firstName: profile?.FirstName ?? "",
		lastName: profile?.LastName ?? "",
		middleName: profile?.MiddleName ?? "",
		displayName: profile?.DisplayName ?? "",
		facilityID: profile?.FacilityId ?? "",
		createdDate: profile?.CreatedDate ?? "",
		profileID: profile?.UserProfileID ?? 0,
		isActive: profile?.IsActive ?? true,
		jobTitleID: profile?.UserTitleID ?? 0,
		userTypeID: profile?.UserTypeID ?? 0,
		isLockedOut: profile?.IsLockOut ?? false,
		lockoutDate: profile?.LockOutDate ?? profile?.LastLockOutDate ?? "",
	};
};

const processUserInfo = (userInfo = {}) => {
	const {
		AdvUserCommunities,
		AdvUsers,
		UserEmails,
		UserLogins,
		UserProfile,
		UserRoles,
		UserRoleGroups,
	} = userInfo;

	const userProfile = processProfile(UserProfile);

	return {
		advUser: AdvUsers?.[0] ?? {},
		userEmails: UserEmails,
		userLogins: UserLogins,
		userRoles: UserRoles,
		userRoleGroups: UserRoleGroups,
		userFacilities: AdvUserCommunities ?? [],
		userProfile: userProfile,
	};
};

/**
 * Checks if a user's email is marked with a 'deleted' marking on their email.
 * - This implies the user is *likely* no longer in use.
 * @param {String} email - A user's email address from the ADVUSER table.
 */
const isEmailDeleted = (email) => {
	const deletedReg = /deleted/gim;
	const isDeleted = deletedReg.test(email);
	return isDeleted;
};

// ADMIN & ROLE RELATED UTILS //

/**
 * Gives an 'ADMIN' regional access to a target facility. Used for regional reports etc.
 * @param {String} token - Auth token
 * @param {String} userID - Target user guid (ie. typically an 'ADMIN')
 * @param {String} regionalID - Target 'regional' facility guid.
 * @returns {String} - Returns string-form response.
 */
const giveRegionalAccessToUser = async (token, userID, regionalID) => {
	let url = currentEnv.base + user.edit.giveRegionalAccess;
	url += "?" + new URLSearchParams({ UserID: userID });
	url += "&" + new URLSearchParams({ FacilityID: regionalID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Processes HTTP response & returns custom response object w/ status and a string message.
 * @param {String} response - String-form http response (eg. either 'SUCCESS' or 'USER MUST HAVE ACCESS TO THAT COMMUNITY FIRST')
 * @returns {Object} - Returns object w/ success status and message
 */
const processRegionalAccessResponse = (response) => {
	const resp = response?.[0]?.Result ?? "";
	const wasSuccessful = resp === "SUCCESS";
	const error = !wasSuccessful ? response : "";

	if (!wasSuccessful) {
		return { msg: error, wasSuccessful: wasSuccessful };
	} else {
		return { msg: error, wasSuccessful: wasSuccessful };
	}
};

const giveRegionalAccessAndProcess = async (token, userID, regionalID) => {
	const accessResponse = await giveRegionalAccessToUser(
		token,
		userID,
		regionalID
	);
	const wasSuccessful = processRegionalAccessResponse(accessResponse);

	return wasSuccessful;
};

// MATCH USER FROM XXXX //

const matchUsernameFromList = (username, list = []) => {
	const user = list.filter((user) => user.email === username);

	return user?.[0];
};

const matchUserFromNames = (name, namesMap = {}) => {
	if (isEmptyVal(name)) return {};
	const user = namesMap?.[name] ?? {};
	return user;
};

// map by logins
const matchUserFromLogins = (login, namesMap = {}) => {
	if (isEmptyVal(login) || isEmptyObj(namesMap)) return {};
	const user = namesMap?.[login] ?? {};
	return user;
};

const formatUserList = (users = []) => {
	const userList = sortAlphaAscByKey("lastName", users).map((user) => {
		const text = `${user?.lastName}, ${user?.firstName} (${user?.email})`;
		return text;
	});

	return userList;
};

// extracts email address from any string with other chars:
// - 'Account, Xmas (email@gmail.com)' => 'email@gmail.com'
const extractEmailAndMatch = (selection, namesMap = {}) => {
	const groups = getMatchGroups(selection, emailAnywhereReg);
	const email = groups?.email ?? "";

	const userRecord = matchUserFromNames(email, namesMap);

	console.group("Extract/Match");
	console.log("groups:", groups);
	console.log("email:", email);
	console.log("userRecord", userRecord);
	console.groupEnd();

	return userRecord?.UserLoginID;
};

export { processUserInfo, processProfile, isEmailDeleted, formatUserList };

export { processUserProfile };

export {
	getAllUsersByUserEmail,
	getUserProfile,
	getUserProfileByEmail,
	giveRegionalAccessToUser,
	giveRegionalAccessAndProcess,
};

export { processRegionalAccessResponse };

export { USER_TYPES };

export {
	matchUserFromNames,
	matchUserFromLogins,
	matchUsernameFromList,
	extractEmailAndMatch,
};
