import React, { useState } from "react";
import styles from "../../css/reports/UserReportEntry.module.scss";
import { PropTypes } from "prop-types";
import {
	addEllipsis,
	enforceStrMaxLength,
} from "../../helpers/utils_processing";
import UserReportDetails from "./UserReportDetails";
import ModalLG from "../shared/ModalLG";
import { format } from "date-fns";
import { isEmptyVal } from "../../helpers/utils_types";

// REQUIREMENTS:
// - First Name
// - Last Name
// - Email
// - Company/Community (if with multiple communities just list the parent's name)

const getName = (first, last) => {
	const merged = `${last}, ${first}`;
	const clean = addEllipsis(merged, 30);

	return clean;
};

const getRawName = (first, last) => {
	const merged = `${last}, ${first}`;

	return merged;
};

const UserReportEntry = ({
	user = {},
	entryNumber,
	currentUser = {},
	facilityData = {},
}) => {
	const email = user?.LoginNameByEmail ?? user?.LoginName ?? "";
	const {
		FirstName: firstName,
		LastName: lastName,
		CommunityName: communityName,
		// LastLogin: lastLogin,
		// ResidentCount: residentCount,
	} = user;
	const { ResidentCount: residentCount } = facilityData;
	const lastLogin = !isEmptyVal(user?.LastLoginDate)
		? format(user?.LastLoginDate, "MM/DD/YYYY")
		: "UNKNOWN";
	// const lastLogin = format("2023-08-28T16:31:26.383Z", "M/D/YY h:mm A");

	const [showDetails, setShowDetails] = useState(false);

	const openDetails = (e) => {
		setShowDetails(true);
	};

	return (
		<>
			<div className={styles.UserReportEntry} onClick={openDetails}>
				<div
					className={styles.UserReportEntry_name}
					title={getRawName(firstName, lastName)}
				>
					{getName(firstName, lastName)}
				</div>
				<div className={styles.UserReportEntry_email} title={email}>
					{addEllipsis(email, 35)}
				</div>
				<div className={styles.UserReportEntry_community} title={communityName}>
					{addEllipsis(communityName, 30)}
				</div>
				<div className={styles.UserReportEntry_login} title={lastLogin}>
					{lastLogin}
				</div>
				<div className={styles.UserReportEntry_count} title={residentCount}>
					{residentCount}
				</div>
			</div>

			{showDetails && (
				<ModalLG
					title={`Active User Details: ${firstName} ${lastName}`}
					closeModal={() => setShowDetails(false)}
				>
					<UserReportDetails
						user={user}
						currentUser={currentUser}
						facilityData={facilityData}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default UserReportEntry;

UserReportEntry.defaultProps = {};

UserReportEntry.propTypes = {};
