import { currentEnv } from "./utils_env";
import { security } from "./utils_endpoints";
import { defaultParams } from "./utils_params";
import { isEmptyArray } from "./utils_types";

/**
 * Fetches a user's security info: profile, logins, alerts, emails roles, communities etc.
 * @param {String} token - Auth token
 * @param {String} userIdOrUsername - This arg accepts either 'UserId' or a 'Username/Email'
 */
const getUserSecurityInfo = async (token, userIdOrUsername) => {
	let url = currentEnv.base + security.info.get;
	url += "?" + new URLSearchParams({ IdOrName: userIdOrUsername });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// 'UserType' & 'UserTitle' utils

/**
 * Fetches ALL user type categories: 'Admin', 'Manager', 'Staff', 'Contractor' etc
 */
const getAllUserTypes = async (token, params = { ...defaultParams }) => {
	let url = currentEnv.base + security.userType.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches user titles
 */
const getAllUserTitles = async (token, params = { ...defaultParams }) => {
	let url = currentEnv.base + security.userTitle.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches 'UserTypes' & 'UserTitles'
const getTypesAndTitles = async (token, params = { ...defaultParams }) => {
	const [userTypes, userTitles] = await Promise.all([
		getAllUserTypes(token, params),
		getAllUserTitles(token, params),
	]);

	return {
		userTypes,
		userTitles,
	};
};

// fetches & formats 'User Types'
const getAndProcessUserTypes = async (token, params = {}) => {
	const userTypes = await getAllUserTypes(token, params);

	if (!isEmptyArray(userTypes)) {
		const cleanTypes = processUserTypes(userTypes);
		return cleanTypes;
	} else {
		return [];
	}
};

// PROCESSING UTILS
/**
 * Processes & inits client-formatted user titles.
 * @returns {Array} - Returns an array of formatted objects.
 */
const processUserTitles = (allTitles = []) => {
	if (isEmptyArray(allTitles)) return [];
	return allTitles.reduce((userTitles, titleRecord) => {
		const { Name, Description, UserTitleID } = titleRecord;
		const newObj = { name: Name, desc: Description, titleID: UserTitleID };
		userTitles = [...userTitles, newObj];
		return userTitles;
	}, []);
};
/**
 * Processes & inits client-formatted user types.
 * @returns {Array} - Returns an array of formatted objects.
 */
const processUserTypes = (allTypes = []) => {
	if (isEmptyArray(allTypes)) return [];
	return allTypes.reduce((userTypes, typeRecord) => {
		const { Name, Description, UserTypeID } = typeRecord;
		const newObj = { name: Name, desc: Description, typeID: UserTypeID };
		userTypes = [...userTypes, newObj];
		return userTypes;
	}, []);
};
/**
 * Processes & inits client-formatted user types and titles.
 * @returns {Object} - Returns an object of 'userTypes' & 'userTitles'.
 * @property {Array} userTypes - Formatted user types.
 * @property {Array} userTitles - Formatted user titles.
 */
const processTypesAndTitles = (allTypes = [], allTitles = []) => {
	const types = processUserTypes(allTypes);
	const titles = processUserTitles(allTitles);

	return {
		userTypes: types,
		userTitles: titles,
	};
};

export { getUserSecurityInfo };

export {
	getAllUserTitles,
	getAllUserTypes,
	getTypesAndTitles,
	getAndProcessUserTypes,
};

export { processUserTitles, processUserTypes, processTypesAndTitles };
