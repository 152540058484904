import React from "react";
import styles from "../../css/ui/FileCard.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import template from "../../assets/docs/ImportDataTemplate.xlsx";
// components
import ImgWithFallback from "../shared/ImgWithFallback";

const fileIcons = {
	dark: {
		xlsx: "document-file-xlsx1",
		xls: "document-file-xls1",
		docx: "document-file-docx1",
		doc: "document-file-doc1",
		pdf: "document-file-pdf1",
		png: "document-file-png1",
		css: "document-file-css1",
		html: "document-file-html1",
		num: "document-file-num1",
		file: "file",
		fileText: "file-text",
	},
	light: {
		xlsx: "document-file-xlsx",
		xls: "document-file-xls",
		docx: "document-file-docx",
		doc: "document-file-doc",
		pdf: "document-file-pdf",
		png: "document-file-png",
		css: "document-file-css",
		html: "document-file-html",
		num: "document-file-num",
		// labelled
	},
	download: {
		download: "download2",
		download1: "cloud-download",
		download2: "cloud-download2",
	},
	labelled: {
		text: "file-text",
		text2: "file-text-o",
		pdf: "file-pdf-o",
		word: "file-word-o",
		excel: "file-excel-o",
		powerpoint: "file-powerpoint-o",
		image: "file-image-o",
		zip: "file-zip-o",
	},
};

// determines 'href' source (local file or link to sharepoint file)
const getDownloadSource = (fileHref, fileSrc) => {
	if (!isEmptyVal(fileHref)) {
		return fileHref;
	} else {
		return fileSrc;
	}
};

const getLinkProps = (fileHref, fileSrc) => {
	if (!isEmptyVal(fileHref)) {
		return {
			// target: '_blank'
		};
	} else {
		return {
			target: "_blank",
			rel: "noopener noreferrer",
		};
	}
};

const FileCard = ({
	previewSrc,
	previewAlt,
	fileHref = null,
	fileSrc,
	fileName,
	size,
	docType = "xlsx",
	enableNewTab = true,
}) => {
	return (
		<div className={styles.FileCard}>
			<a
				className={styles.FileCard_wrapper}
				// href={template}
				href={getDownloadSource(fileHref, fileSrc)}
				download={fileName}
				{...getLinkProps(fileHref, fileSrc)}
			>
				<div className={styles.FileCard_wrapper_img}>
					<ImgWithFallback
						size="FULL"
						src={previewSrc}
						alt={previewAlt}
						type="image/png"
						fit="fill"
					/>
				</div>
				<div className={styles.FileCard_wrapper_info}>
					<div className={styles.FileCard_wrapper_info_name}>{fileName}</div>
					<div className={styles.FileCard_wrapper_info_size}>{size}</div>
				</div>
			</a>
		</div>
	);
};

export default FileCard;

FileCard.defaultProps = {};

FileCard.propTypes = {};
