import React from "react";
import styles from "../../css/facility/MakeUserAdmin.module.scss";
import { PropTypes } from "prop-types";
import { blue, red } from "../../helpers/utils_styles";
import { isEmptyArray } from "../../helpers/utils_types";
// components
import ButtonSM from "../shared/ButtonSM";
import UserDropdown from "../user/UserDropdown";

const customCSS = {
	selector: {
		width: "35rem",
	},
	save: {
		padding: "1rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: blue[600],
		marginLeft: ".5rem",
	},
	cancel: {
		backgroundColor: "transparent",
		color: red[600],
		padding: ".8rem 1.3rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
};

const MakeUserAdmin = ({
	selectedUsers = [],
	facilityUsers = [],
	handleSelection,
	cancelAdmin,
	saveAsAdmin,
}) => {
	return (
		<div className={styles.MakeUserAdmin}>
			<div className={styles.MakeUserAdmin_options}>
				<UserDropdown
					key={`USER-ADMIN-SELECTOR`}
					name="selectedUser"
					id="selectedUser"
					label="Select User(s) to Make Admin"
					usersList={facilityUsers}
					handleUserSelections={handleSelection}
					placeholder="Select a user..."
					customStyles={customCSS.selector}
					disableRemove={true}
				/>
			</div>

			<div className={styles.MakeUserAdmin_actions}>
				<ButtonSM customStyles={customCSS.cancel} handleClick={cancelAdmin}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={isEmptyArray(selectedUsers)}
					customStyles={customCSS.save}
					handleClick={saveAsAdmin}
				>
					Make Admin
				</ButtonSM>
			</div>
		</div>
	);
};

export default MakeUserAdmin;

MakeUserAdmin.defaultProps = {};

MakeUserAdmin.propTypes = {};
