import React, { useReducer, useState, useCallback, useEffect } from "react";
import styles from "../../css/vendor/VendorMapsController.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	deleteVendorMapping,
	getVendorDataMap,
	matchVendorByDesc,
	prepareMappingRecord,
	processDataMapRecords,
} from "../../helpers/utils_vendors";
import {
	isEmptyObj,
	isEmptyVal,
	isEmptyArray,
} from "../../helpers/utils_types";
// components
import CustomDropdown from "../shared/CustomDropdown";
import ModalLG from "../shared/ModalLG";
import ModalSM from "../shared/ModalSM";
import VendorMapsTable from "./VendorMapsTable";
import VendorMapsTableForm from "./VendorMapsTableForm";
import CreateNewVendorRowForm from "./CreateNewVendorRowForm";
import VendorTableActions from "./VendorTableActions";
import VendorMapsActions from "./VendorMapsActions";

// REQUIREMENTS:
// - Vendor Selection dropdown
// - Vendor Maps table
// - Vendor Row Form (for editing existing rows)
// - Vendor New Row Form

const customCSS = {
	selector: {
		width: "35rem",
	},
};

const getActiveRows = (searchVal, vendorTableState = {}) => {
	if (isEmptyVal(searchVal)) {
		return vendorTableState?.rows;
	} else {
		return vendorTableState?.searchedRows;
	}
};

const getVendorOptions = (list = []) => {
	if (isEmptyArray(list)) return [];
	const descs = list?.map(({ Description }) => Description);
	return descs;
};

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		if (
			option?.ALAFieldName.toLowerCase().startsWith(val) ||
			option?.ALAFieldName.toLowerCase().includes(val) ||
			option?.ALAFieldValue.toLowerCase().startsWith(val) ||
			option?.ALAFieldValue.toLowerCase().includes(val) ||
			option?.VendorFieldValue.toLowerCase().startsWith(val) ||
			option?.VendorFieldValue.toLowerCase().includes(val) ||
			option?.VendorFieldName.toLowerCase().startsWith(val) ||
			option?.VendorFieldName.toLowerCase().includes(val) ||
			option?.VendorSourceName.toLowerCase().startsWith(val) ||
			option?.VendorSourceName.toLowerCase().includes(val)
		) {
			// matches
			return option;
		} else {
			return null;
		}
	});
};

const tableReducer = (state, action) => {
	switch (action.type) {
		// LOADS VENDOR'S DATA MAP RECORDS
		case "LOAD-VENDOR": {
			const { dataMap } = action.data;

			if (isEmptyArray(dataMap)) {
				return {
					...state,
					hasLoaded: true,
					rows: [],
				};
			} else {
				const rows = processDataMapRecords(dataMap);

				return {
					...state,
					hasLoaded: true,
					rows: [...rows],
					searchedRows: [...rows],
				};
			}
		}
		case "SELECT-ROW": {
			const { rowIdx, row } = action.data;

			return {
				...state,
				selectedRow: {
					idx: rowIdx,
					data: { ...row },
				},
			};
		}
		case "EDIT-ROW": {
			return state;
		}
		case "DELETE-ROW": {
			// state
			const { rows } = state;
			// arguments
			const { selectedRow } = action.data;
			const { VendorDataMapID: targetID } = selectedRow?.data;
			const newRows = [...rows.filter((x) => targetID !== x?.VendorDataMapID)];

			// set state to new rows without deleted & reset 'selectedRow' state
			return {
				...state,
				rows: [...newRows],
				selectedRow: {
					idx: null,
					data: {},
				},
			};
		}
		case "SYNC-NEW-ROW": {
			const { newRow } = action.data;
			const newRows = [...state?.rows, newRow];

			return {
				...state,
				rows: [...newRows],
			};
		}
		case "SEARCH": {
			const { searchVal, rows, searchedRows } = action.data;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					rows: rows,
					searchedRows: rows,
				};
			} else {
				// search here!!!
				const searchResults = advancedSearch(searchVal, rows);

				return {
					...state,
					rows: [...rows],
					searchedRows: searchResults,
				};
			}
		}
		default:
			return state;
	}
};

const defaultCols = [
	"ALA Source Name",
	"Vendor Source Name",
	"ALA Field Name",
	"Vendor Field Name",
	"ALA Field Value",
	"Vendor Field Value",
];

const initialState = {
	hasLoaded: false,
	rows: [],
	searchedRows: [],
	selectedRow: {
		idx: null,
		data: {},
	},
};

const RowCount = ({ numberOfRows }) => {
	return (
		<div className={styles.RowCount}>
			Showing <b>{numberOfRows}</b> Row(s)
		</div>
	);
};

const VendorTableSearch = ({ val, handleSearch }) => {
	return (
		<div className={styles.VendorTableSearch}>
			<input
				type="text"
				name="searchTable"
				id="searchTable"
				value={val}
				onChange={handleSearch}
				className={styles.VendorTableSearch_input}
				placeholder="Search table..."
			/>
		</div>
	);
};

const VendorMapsController = ({
	vendorsList = [],
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
}) => {
	// selected vendor (from dropdown)
	const [selectedVendor, setSelectedVendor] = useState({
		desc: "",
		record: {},
	});
	// currently selected row index
	const [selectedRowIdx, setSelectedRowIdx] = useState(null);
	// vendor map state
	const [vendorTableState, vendorTableDispatch] = useReducer(tableReducer, {
		hasLoaded: false,
		rows: [],
		searchedRows: [],
		selectedRow: {
			idx: null,
			data: {},
		},
	});
	const { selectedRow } = vendorTableState;
	// selectedRow values
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// 'Source'
		ALASourceName: selectedRow?.data?.["ALASourceName"] ?? "",
		VendorSourceName: selectedRow?.data?.["VendorSourceName"] ?? "",
		// 'Field-Name'
		ALAFieldName: selectedRow?.data?.["ALAFieldName"] ?? "",
		VendorFieldName: selectedRow?.data?.["VendorFieldName"] ?? "",
		// 'Field-Value'
		ALAFieldValue: selectedRow?.data?.["ALAFieldValue"] ?? "",
		VendorFieldValue: selectedRow?.data?.["VendorFieldValue"] ?? "",
	});
	const { values, touched } = formState;
	// search value
	const [searchVal, setSearchVal] = useState("");

	// new row modal
	const [showNewEntryModal, setShowNewEntryModal] = useState(false);

	const handleVendor = (name, val) => {
		// de-selecting or clearing out selection
		if (isEmptyVal(val)) {
			return setSelectedVendor({
				desc: "",
				record: {},
			});
		} else {
			// making selection
			const record = matchVendorByDesc(val, vendorsList);

			return setSelectedVendor({
				desc: val,
				record: record,
			});
		}
	};

	const selectRow = (idx, row) => {
		const isSameRow =
			row?.VendorDataMapID === selectedRow?.data?.VendorDataMapID ||
			idx === selectedRow?.idx;
		// de-select or empty selection
		if (isEmptyObj(row) || isSameRow) {
			setSelectedRowIdx(null);

			setFormState({
				...formState,
				values: {
					// 'Source'
					ALASourceName: "",
					VendorSourceName: "",
					// 'Field-Name'
					ALAFieldName: "",
					VendorFieldName: "",
					// 'Field-Value'
					ALAFieldValue: "",
					VendorFieldValue: "",
				},
			});
			return vendorTableDispatch({
				type: "SELECT-ROW",
				data: {
					rowIdx: null,
					row: { idx: null, data: {} },
				},
			});
		} else {
			setSelectedRowIdx(idx);
			setFormState({
				...formState,
				values: {
					// 'Source'
					ALASourceName: row?.ALASourceName,
					VendorSourceName: row?.VendorSourceName,
					// 'Field-Name'
					ALAFieldName: row?.ALAFieldName,
					VendorFieldName: row?.VendorFieldName,
					// 'Field-Value'
					ALAFieldValue: row?.ALAFieldValue,
					VendorFieldValue: row?.VendorFieldValue,
				},
			});
			return vendorTableDispatch({
				type: "SELECT-ROW",
				data: {
					rowIdx: idx,
					row: row,
				},
			});
		}
	};

	const initNewRowEntry = () => {
		setShowNewEntryModal(true);
	};

	const closeNewEntryModal = () => {
		setShowNewEntryModal(false);
	};

	const clearForm = (e) => {
		handleReset(e);
	};

	const deleteSelectedRow = async () => {
		const { token } = currentUser;
		// convert field names to server-side format
		const preparedRecord = prepareMappingRecord(currentUser, selectedRow?.data);
		// fire off request to delete/deactivate record
		const wasDeleted = true;
		// const wasDeleted = await deleteVendorMapping(token, preparedRecord);

		if (wasDeleted) {
			// delete by index and/or id
			vendorTableDispatch({
				type: "DELETE-ROW",
				data: {
					selectedRow: selectedRow,
				},
			});
			return dispatchAlert("SUCCESS", {
				heading: `Record was deleted!!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `There was an issue!`,
				subheading: `Please try again.`,
			});
		}
	};

	const syncNewRow = async (newRow) => {
		// fire off request
		// sync to state
		const wasCreated = true;

		if (wasCreated) {
			setShowNewEntryModal(false);
			return vendorTableDispatch({
				type: "SYNC-NEW-ROW",
				data: {
					newRow: { ...newRow, VendorDataMapID: wasCreated },
				},
			});
		} else {
			return setShowNewEntryModal(true);
		}
	};

	const searchVendorTable = (e) => {
		const { value } = e.target;

		setSearchVal(value);
		vendorTableDispatch({
			type: "SEARCH",
			data: {
				searchVal: value,
				rows: vendorTableState?.rows,
				searchedRows: vendorTableState?.searchedRows,
			},
		});
	};

	// fetches vendor data map records
	const fetchVendorData = useCallback(async () => {
		// if no selection
		if (isEmptyVal(selectedVendor?.record?.VendorID)) return;
		// if selection is made or selection has changed
		const { token } = currentUser;
		const { VendorID: vendorID } = selectedVendor?.record;
		const dataMap = await getVendorDataMap(token, vendorID);
		console.log("dataMap", dataMap);

		if (!isEmptyArray(dataMap)) {
			return vendorTableDispatch({
				type: "LOAD-VENDOR",
				data: {
					dataMap,
				},
			});
		} else {
			return vendorTableDispatch({
				type: "LOAD-VENDOR",
				data: {
					dataMap: [],
					msg: "NO RECORDS FOR THIS VENDOR!",
				},
			});
		}
	}, [currentUser, selectedVendor?.record]);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchVendorData();

		return () => {
			isMounted = false;
		};
	}, [fetchVendorData, selectedVendor?.record?.VendorID]);

	return (
		<>
			<div className={styles.VendorMapsController}>
				<div className={styles.VendorMapsController_selector}>
					<CustomDropdown
						name="selectedVendor"
						id="selectedVendor"
						label="Select Vendor"
						selection={selectedVendor?.desc}
						setSelection={handleVendor}
						options={getVendorOptions(vendorsList)}
						customStyles={customCSS.selector}
						placeholder="Click to load a vendor..."
					/>
				</div>
				<div className={styles.VendorMapsController_table}>
					<div className={styles.VendorMapsController_table_top}>
						<VendorTableActions initNewRowEntry={initNewRowEntry} />
						<VendorTableSearch
							val={searchVal}
							handleSearch={searchVendorTable}
						/>
						<RowCount numberOfRows={vendorTableState?.rows?.length ?? 0} />
					</div>

					<VendorMapsTable
						key={`VENDOR-TABLE-${vendorTableState?.rows?.length}`}
						// rows={vendorTableState?.rows}
						rows={getActiveRows(searchVal, vendorTableState)}
						tableDispatch={vendorTableDispatch}
						columns={defaultCols}
						selectRow={selectRow}
						selectedRow={selectedRow}
						selectedVendor={selectedVendor}
						hasLoaded={vendorTableState?.hasLoaded ?? false}
					/>
					<VendorMapsActions
						selectedRow={selectedRow}
						deleteRow={deleteSelectedRow}
					/>
				</div>
				<div className={styles.VendorMapsController_form}>
					<VendorMapsTableForm
						key={`VENDOR-FORM-${selectedRow?.idx}-${values?.ALASourceName}`}
						vals={values}
						formState={formState}
						handleChange={handleChange}
						resetForm={clearForm}
						selectedRow={selectedRow}
					/>
				</div>
			</div>

			{/* NEW ROW ENTRY MODAL */}
			{showNewEntryModal && (
				<ModalSM title="Create New Row Entry" closeModal={closeNewEntryModal}>
					<CreateNewVendorRowForm createNewRowEntry={syncNewRow} />
				</ModalSM>
			)}
		</>
	);
};

export default VendorMapsController;

VendorMapsController.defaultProps = {};

VendorMapsController.propTypes = {};
