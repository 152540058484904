import React, { useReducer, createContext } from "react";

const initialState = {
	currentUser: {
		username: "",
		password: "",
		firstName: "",
		lastName: "",
		middleName: "",
		userID: "",
		profileID: "",
		token: null,
		facilities: [],
		security: {},
	},
	currentFacility: {
		communityName: "",
		facilityID: "",
		parentID: "",
		licenseNumber: "",
		execDirector: "",
		alaDirector: "",
		timeZone: "",
		address: {},
		shifts: [],
	},
};

const stateReducer = (state, action) => {
	switch (action.type) {
		case "INITIAL_RESOURCE": {
			const { newState } = action.data;

			return {
				...state,
				...newState,
			};
		}
		case "SET_FACILITY": {
			const { facility, userData } = action.data;
			const { userList, userMaps } = userData;

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					...facility,
					users: userList,
					userMaps: userMaps,
				},
			};
		}
		case "SET_FACILITY_USERS": {
			const { userData } = action.data;
			const { users, userMaps } = userData;

			return {
				...state,
				currentFacility: {
					...state.currentFacility,
					users,
					userMaps,
				},
			};
		}
		case "CLEAR_FACILITY": {
			return {
				...state,
				currentFacility: {
					...initialState?.currentFacility,
				},
			};
		}
		case "MANUAL_LOGOUT": {
			return { ...initialState };
		}
		case "FORCE_LOGOUT": {
			return { ...initialState };
		}
		default:
			return { ...state };
	}
};

const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState);

	return (
		<GlobalStateContext.Provider value={{ state, dispatch }}>
			{children}
		</GlobalStateContext.Provider>
	);
};

export { GlobalStateContext, GlobalStateProvider, initialState };
