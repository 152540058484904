import React from "react";
import styles from "../../css/admin/CreateAdmin.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import TextInput from "../shared/TextInput";
import PasswordInput from "../shared/PasswordInput";
import EmailValidator from "../forms/EmailValidator";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - This ONLY shows when making a community an independent!!!

const customCSS = {
	name: {
		width: "35rem",
		backgroundColor: "#ffffff",
	},
	create: {
		padding: ".8rem 1.5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "500",
	},
};

const disableBtn = (vals = {}) => {
	const {
		newAdminEmail,
		newAdminFirstName,
		newAdminLastName,
		newAdminPassword,
	} = vals;

	const shouldEnable =
		!isEmptyVal(newAdminEmail) &&
		!isEmptyVal(newAdminFirstName) &&
		!isEmptyVal(newAdminLastName) &&
		!isEmptyVal(newAdminPassword);

	return !shouldEnable;
};

const CreateAdmin = ({
	vals = {},
	handleChange,
	handleEmail,
	handlePassword,
	createAdmin,
	currentUser = {},
	currentFacility = {},
}) => {
	return (
		<div className={styles.CreateAdmin}>
			<div className={styles.CreateAdmin_top}>
				<div className={styles.CreateAdmin_top_title}>Create New Admin:</div>
			</div>
			<form className={styles.CreateAdmin_form} autoComplete="new-password">
				<input type="hidden" auto-complete="new-password" />
				<div className={styles.CreateAdmin_form_entry}>
					<EmailValidator
						name="newAdminEmail"
						id="newAdminEmail"
						label="Admin Email"
						placeholder="Admin email..."
						val={vals?.newAdminEmail}
						handleChange={handleChange}
						token={currentUser?.token}
						autoComplete="new-email"
					/>
				</div>
				<div className={styles.CreateAdmin_form_entry}>
					<TextInput
						name="newAdminFirstName"
						id="newAdminFirstName"
						label="Admin First Name"
						placeholder="Enter first name..."
						val={vals?.newAdminFirstName}
						handleChange={handleChange}
						customStyles={customCSS.name}
						autoComplete="new-firstname"
					/>
				</div>
				<div className={styles.CreateAdmin_form_entry}>
					<TextInput
						name="newAdminLastName"
						id="newAdminLastName"
						label="Admin Last Name"
						placeholder="Enter last name..."
						val={vals?.newAdminLastName}
						handleChange={handleChange}
						customStyles={customCSS.name}
						autoComplete="new-lastname"
					/>
				</div>
				<div className={styles.CreateAdmin_form_entry}>
					<PasswordInput
						name="newAdminPassword"
						id="newAdminPassword"
						label="Create Admin Password"
						placeholder="Enter password..."
						val={vals?.newAdminPassword}
						handleChange={handleChange}
						customStyles={customCSS.name}
						autoComplete="new-password"
					/>
				</div>
				<div className={styles.CreateAdmin_form_actions}>
					<ButtonSM
						type="button"
						isDisabled={disableBtn(vals)}
						customStyles={customCSS.create}
						handleClick={createAdmin}
					>
						Create Admin
					</ButtonSM>
				</div>
			</form>
		</div>
	);
};

export default CreateAdmin;

CreateAdmin.defaultProps = {};

CreateAdmin.propTypes = {};
