import { generic } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { params } from "./utils_params";
import { isEmptyArray, isEmptyVal } from "./utils_types";

/**
 * Application IDs: unique identifiers in the 'Application' table.
 */
const appIDs = {
	CareManager: 1,
	SeniorCareVB: 1,
	CareTracker: 2,
	AdvantageTracker: 2,
	AdminPortal: 3,
	ALAServices: 4,
	AccufloInterface: 5,
};
const appNames = {
	1: "SeniorCareVB",
	2: "AdvantageTracker",
	3: "AdminPortal",
	4: "ALAServices",
	5: "AccufloInterface",
	19: "ChartMedsInterface",
};

const SERVICES = {
	SeniorCareVB: {
		appID: 1,
		primary: "Care Manager",
		alias: "SeniorCareVB",
		name: "Senior Care VB",
		desc: "EHR Care Platform",
		url: "https://app.aladvantage.com/Account/Login.aspx",
	},
	AdvantageTracker: {
		appID: 2,
		primary: "Care Tracker",
		alias: "AdvantageTracker",
		name: "Care Tracker",
		desc: "Care Tracking & Reporting Platform",
		url: "https://tracker.aladvantage.com",
		// url: "http://localhost:3001", // TEST ENV ONLY!!!
	},
	AdminPortal: {
		appID: 3,
		alias: "AdminPortal",
		name: "ALA Admin Portal",
		desc: "Portal for ALA app settings, user management and single-sign-on (SSO).",
		url: "https://portal.aladvantage.com",
	},
	ALAServices: {
		appID: 4,
		alias: "ALAServices",
		name: "ALA Services",
		desc: "Web services for connecting to ALA's backend infrastructure.",
		url: "https://apitest.aladvantage.com/alaservices/v1",
	},
	AccufloInterface: {
		appID: 5,
		alias: "AccufloInterface",
		name: "Accu-Flow Interface",
		desc: "Third-party interface for transmitting resident data.",
		url: null,
	},
	ChartMedsInterface: {
		appID: 19,
		alias: "ChartMedsInterface",
		name: "Chart Meds Interface",
		desc: "Third-party interface for transmitting clinical & pharmacy data.",
		url: null,
	},
	"Chart Meds": {
		appID: 19,
		alias: "ChartMedsInterface",
		name: "Chart Meds Interface",
		desc: "Third-party interface for transmitting clinical & pharmacy data.",
		url: null,
	},
	Stax: {
		appID: 19,
		alias: "StaxInterface",
		name: "Stax Interface",
		desc: "Stax windows service interface application",
		url: null,
	},
};

const getAppNameFromID = (id) => {
	return appNames?.[id];
};
// returns app ID
const getAppIDFromName = (name) => {
	return appIDs?.[name];
};

// checks for FacilityApp access (ApplicationByFacility) otherwise creates default record.
const findAppAccessOrFallback = (appID, list = []) => {
	const appMatch = list.filter((x) => x.ApplicationID === appID);
	const appRecord = appMatch?.[0] ?? {
		ApplicationByFacilityID: 0,
		ApplicationID: appID,
		FacilityID: null,
		IsAccessible: false,
	};

	return appRecord;
};

// finds ApplicationByFacility record by appID from list of legacy-formatted records.
const findAppInAccessList = (appID, list = []) => {
	const appRecord = list.filter((x) => x.ApplicationID === appID);

	return appRecord?.[0] ?? {};
};
// APP ACCESS UTILS & REQUESTS //

// fetches ALL app access record (ie 'GetApplicationByFacility' records)
const getAppAccessByFacility = async (token, facilityID) => {
	const appAccess = await Promise.all([
		getFacilityAppAccessByApp(token, facilityID, 1), // LEGACY EHR
		getFacilityAppAccessByApp(token, facilityID, 2), // TRACKER
		getFacilityAppAccessByApp(token, facilityID, 3), // ADMIN-PORTAL
		getFacilityAppAccessByApp(token, facilityID, 19), // EMAR/CHART-MEDS
		getFacilityAppAccessByApp(token, facilityID, 22), // STAX
	]);

	const legacy = findAppAccessOrFallback(1, appAccess);
	const tracker = findAppAccessOrFallback(2, appAccess);
	const portal = findAppAccessOrFallback(3, appAccess);
	const emar = findAppAccessOrFallback(19, appAccess);
	const stax = findAppAccessOrFallback(22, appAccess);

	console.group("Get App Access By Facility");
	console.log("legacy:", legacy);
	console.log("tracker:", tracker);
	console.log("portal:", portal);
	console.log("emar:", emar);
	console.log("stax:", stax);
	console.groupEnd();

	return {
		tracker: {
			...tracker,
			IsAccessible: tracker?.IsAccessible,
			FacilityID: facilityID,
		},
		legacy: {
			...legacy,
			IsAccessible: true,
			FacilityID: facilityID,
		},
		portal: {
			...portal,
			IsAccessible: true,
			FacilityID: facilityID,
		},
		emar: {
			...emar,
			IsAccessible: emar?.IsAccessible,
			FacilityID: facilityID,
		},
		stax: {
			...stax,
			IsAccessible: stax?.IsAccessible,
			FacilityID: facilityID,
		},
	};
};

const getFacilityAppAccessByApp = async (token, facilityID, appID) => {
	if (isEmptyVal(facilityID) || isEmptyVal(appID)) return {};
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByFacility });
	url += "&" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ FacilityID: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response?.Data?.[0] ?? {};
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

/**
 * Returns a list of 'ApplicationByFacility' records that are enabled for the 'ApplicationID' provided.
 * @returns {Array|Null} - Returns an array of records OR null if NO matching records found.
 */
const getFacilityAccessByApp = async (token, appID = 2) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByFacility });
	url += "&" + new URLSearchParams({ IsAccessible: true });
	url += "&" + new URLSearchParams({ ApplicationID: appID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

// fetches access records and extracts the facilityIDs w/ access
const getFacilityAccess = async (token) => {
	const accessRecords = await getFacilityAccessByApp(token, 2);
	if (!isEmptyArray(accessRecords)) {
		const accessList = getFacilityAccessList(accessRecords);
		return accessList;
	} else {
		return [];
	}
};

/**
 * Iterate thru app-access records & create a list of facilities that have access to a specific app.
 * @param {Array} accessRecords - An array of records that indicate a facility's access to a specific app.
 */
const getFacilityAccessList = (accessRecords = []) => {
	return accessRecords.reduce((accessIDs, record) => {
		const { FacilityID } = record;
		accessIDs.push(FacilityID);
		return accessIDs;
	}, []);
};

export { appIDs, appNames, SERVICES };
export { getAppIDFromName, getAppNameFromID, findAppInAccessList };

export {
	getFacilityAccessByApp,
	getFacilityAccessList,
	getFacilityAccess,
	// primary handler for app access by facility
	getAppAccessByFacility,
};
