import { facility } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { isEmptyVal } from "./utils_types";

/**
 *
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facility guid string
 * @param {Object} transportRule - Configuration object w/ data export settings (eg. 'Email', 'Database', etc)
 * @returns {Object} - Returns DataFileRegistry object w/ fileID for exported data file (eg. .zip file)
 */
const exportFacilityData = async (token, facilityId, transportRule = {}) => {
	let url = currentEnv.base + facility.closeOut.exportData;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(transportRule),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 *
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facility guid string
 * @param {Object} transportRule - Configuration object w/ data export settings (eg. 'Email', 'Database', etc)
 * @returns {Object} - Returns DataFileRegistry object w/ fileID for exported data file (eg. .zip file)
 */
const exportFacilityTrackerData = async (
	token,
	facilityId,
	transportRule = {}
) => {
	let url = currentEnv.base + facility.closeOut.exportTrackerData;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(transportRule),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// Export Data UI utils //

// merges array of emails into semi-colon-separated string then injects into an array
const getEmailRecipients = (emails = []) => {
	const recipients = emails.reduce((mergedEmails, email) => {
		mergedEmails += `${email};`;

		return mergedEmails;
	}, "");

	return [recipients];
};

// EXPORT DATA MODEL(S) //

class ExportTransportRuleModel {
	constructor(config = {}) {
		// action types
		this._toEmail = this._methodName === "SendToEmailAddress" ?? false;
		this._toAzure = this._methodName === "SendToAzure" ?? false;
		this._toDB = this._methodName === "SendToDatabase" ?? false;
		this._toFTP = this._methodName === "SendToFtp" ?? false;
		this._toHTTP = this._methodName === "SendToHttp" ?? false;
		// method & required fields
		this._methodName = config?.exportMethod ?? "SendToEmailAddress";
		this._facilityName = config?.targetFacilityName ?? "";
		this._emailRecipients = config?.emailRecipients ?? [];
		// email data
		this._emailSubject = config?.emailSubject ?? "Facility Data Export";
		this._emailBody = config?.emailBody ?? null;

		this._model = {
			SendToEmailAddress: this._toEmail,
			SaveToAzure: this._toAzure,
			SaveToDatabase: this._toDB,
			SaveToFtp: this._toFTP,
			SaveToHttp: this._toHTTP,
			EmailSubject: this._emailSubject,
			EmailBody: this._emailBody,
			EmailBodySettingSector: "Notification",
			EmailBodySettingName: "EmailExportFacilityBody",
			EmailBodyContentVars: "FACILITY-NAME",
			EmailBodyContentParms: {
				"FACILITY-NAME": this._facilityName,
			},
			EmailIsHtml: true,
			EmailAddresses: this._emailRecipients,
			EmailReplyAddresses: [],
			AzureFolderPath: null,
			DatabaseName: null,
			TableName: null,
			FieldName: null,
			UrlPath: null,
			hdrParms: {},
			qryParms: {},
			formParms: {},
		};
	}
	setProp(name, val) {
		this._model = {
			...this._model,
			[name]: val,
		};
		return this._model;
	}
	getProp(name) {
		return this._model[name];
	}
	getModel() {
		return this._model;
	}
}
class ExportViaEmailTransportModel {
	constructor(config = {}) {
		this._methodName = config?.exportMethod ?? "SendToEmailAddress";
		this._facilityName = config?.targetFacilityName ?? "";
		this._emailRecipients = config?.emailRecipients ?? [];
		// email data
		this._emailSubject = config?.emailSubject ?? "Facility Data Export";
		this._emailBody = config?.emailBody ?? null;
		// action types
		this._toEmail = this._methodName === "SendToEmailAddress" ?? false;
		this._toAzure = this._methodName === "SendToAzure" ?? false;
		this._toDB = this._methodName === "SendToDatabase" ?? false;
		this._toFTP = this._methodName === "SendToFtp" ?? false;
		this._toHTTP = this._methodName === "SendToHttp" ?? false;

		this._model = {
			SendToEmailAddress: this._toEmail,
			SaveToAzure: this._toAzure,
			SaveToDatabase: this._toDB,
			SaveToFtp: this._toFTP,
			SaveToHttp: this._toHTTP,
			EmailSubject: this._emailSubject,
			EmailBody: this._emailBody,
			EmailBodySettingSector: "Notification",
			EmailBodySettingName: "EmailExportFacilityBody",
			EmailBodyContentVars: "FACILITY-NAME",
			EmailBodyContentParms: {
				"FACILITY-NAME": this._facilityName,
			},
			EmailIsHtml: true,
			EmailAddresses: this._emailRecipients,
			EmailReplyAddresses: [],
			AzureFolderPath: null,
			DatabaseName: null,
			TableName: null,
			FieldName: null,
			UrlPath: null,
			hdrParms: {},
			qryParms: {},
			formParms: {},
		};
	}
	setProp(name, val) {
		this._model = {
			...this._model,
			[name]: val,
		};
		return this._model;
	}
	getProp(name) {
		return this._model[name];
	}
	getModel() {
		return this._model;
	}
}
class ExportTrackerTransportRuleModel {
	constructor(config = {}) {
		this._methodName = config?.exportMethod ?? "SendToEmailAddress";
		this._facilityName = config?.targetFacilityName ?? "";
		this._emailRecipients = config?.emailRecipients ?? [];
		// email data
		this._emailSubject = config?.emailSubject ?? "Facility Data Export";
		this._emailBody = config?.emailBody ?? null;
		// action types
		this._toEmail = this._methodName === "SendToEmailAddress" ?? false;
		this._toAzure = this._methodName === "SendToAzure" ?? false;
		this._toDB = this._methodName === "SendToDatabase" ?? false;
		this._toFTP = this._methodName === "SendToFtp" ?? false;
		this._toHTTP = this._methodName === "SendToHttp" ?? false;

		this._model = {
			SendToEmailAddress: this._toEmail,
			SaveToAzure: this._toAzure,
			SaveToDatabase: this._toDB,
			SaveToFtp: this._toFTP,
			SaveToHttp: this._toHTTP,
			EmailSubject: this._emailSubject,
			EmailBody: this._emailBody,
			EmailBodySettingSector: "Notification",
			EmailBodySettingName: "EmailExportFacilityBody",
			EmailBodyContentVars: "FACILITY-NAME",
			EmailBodyContentParms: {
				"FACILITY-NAME": this._facilityName,
			},
			EmailIsHtml: true,
			EmailAddresses: this._emailRecipients,
			EmailReplyAddresses: [],
			AzureFolderPath: null,
			DatabaseName: null,
			TableName: null,
			FieldName: null,
			UrlPath: null,
			UrlHdrParms: {},
			UrlQryParms: {},
			UrlFormParms: {},
		};
	}
	setProp(name, val) {
		this._model = {
			...this._model,
			[name]: val,
		};
		return this._model;
	}
	getProp(name) {
		return this._model[name];
	}
	getModel() {
		return this._model;
	}
}

const updateEmailTransportModel = (config = {}) => {
	const raw = new ExportViaEmailTransportModel(config);
	const model = raw.getModel();

	return model;
};

// export methods

const EXPORT_METHODS = {
	email: {
		name: `SendToEmailAddress`,
		label: `Send to Email(s)`,
	},
	azure: {
		name: `SendToAzure`,
		label: `Send to Azure`,
	},
	database: {
		name: `SendToDatabase`,
		label: `Send to database`,
	},
	ftp: {
		name: `SendToFtp`,
		label: `Send to FTP`,
	},
	http: {
		name: `SendToHttp`,
		label: `Send to HTTP`,
	},
};
const EXPORT_OPTIONS = [
	`Send to Email(s)`,
	`Send to Azure`,
	`Send to database`,
	`Send to FTP`,
	`Send to HTTP`,
];

export { exportFacilityData, exportFacilityTrackerData };

export {
	ExportTransportRuleModel,
	ExportViaEmailTransportModel,
	ExportTrackerTransportRuleModel,
};

export { EXPORT_METHODS, EXPORT_OPTIONS };

// utils
export { getEmailRecipients, updateEmailTransportModel };
