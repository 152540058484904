import React, { useState, useEffect } from "react";
import styles from "../../css/facility/CommunityActivitySummary.module.scss";
import sprite from "../../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";
import { processLastLogins } from "../../helpers/utils_facility";
import { green, red } from "../../helpers/utils_styles";
// components
import LoginsByDate from "./LoginsByDate";
import LoginsByUser from "./LoginsByUser";
import { isEmptyVal } from "../../helpers/utils_types";
import LoginsByDateViz from "./LoginsByDateViz";

// REQUIREMENTS:
// - Provides 'high-level' summary of logins:
//    - How many logins occurred?
//    - How many users logged in?

const baseModels = {
	activitySummary: {
		dateSummary: `There were logins on X different dates.`,
		loginSummary: {
			AdvantageTracker: `There were X logins in the AdvantageTracker app.`,
			SeniorCareVB: `There were X logins in the SeniorCareVB app.`,
		},
		userList: `Recent user logins (X): email1, email2, email3, ...`,
		userSummary: `There were X different users who logged in recently`,
	},
	loginsByApp: {
		// custom login records grouped by app
		AdvantageTracker: [],
		SeniorCareVB: [],
	},
	loginsByDate: {
		// custom records grouped by date of login
		"2021-08-13": [],
		"2021-08-15": [],
		"2021-08-19": [],
	},
	loginsByUser: [], // user emails' list
};

// models' map
const baseSummary = {
	activitySummary: {},
	loginsByApp: {},
	loginsByDate: {},
	loginsByUser: [],
};

const getDateSummary = (summaryData = {}) => {
	return summaryData?.activitySummary?.dateSummary ?? ``;
};
const getUserSummary = (summaryData = {}) => {
	return summaryData?.activitySummary?.userSummary ?? ``;
};
const getUserSummaryList = (summaryData = {}) => {
	return summaryData?.activitySummary?.userList ?? ``;
};

const hasActivity = (target) => {
	if (target?.length > 0) return true;
	return false;
};

// const success = `check_circle_outline`;
const success = `check_circle`;
const error = `warning1`;

const ActivityDesc = ({ desc }) => {
	return (
		<div className={styles.ActivityDesc}>
			<div
				className={styles.ActivityDesc_iconWrapper}
				style={{ backgroundColor: green[200] }}
			>
				<svg
					className={styles.ActivityDesc_iconWrapper_icon}
					style={{ fill: green[500] }}
				>
					<use xlinkHref={`${sprite}#icon-${success}`}></use>
				</svg>
			</div>
			<span className={styles.ActivityDesc_desc}>{desc}</span>
		</div>
	);
};
const NoActivityDesc = ({ desc }) => {
	return (
		<div className={styles.NoActivityDesc}>
			<div
				className={styles.NoActivityDesc_iconWrapper}
				style={{ backgroundColor: red[200] }}
			>
				<svg
					className={styles.NoActivityDesc_iconWrapper_icon}
					style={{ fill: red[600] }}
				>
					<use xlinkHref={`${sprite}#icon-${error}`}></use>
				</svg>
			</div>
			<span className={styles.NoActivityDesc_desc}>{desc}</span>
		</div>
	);
};

const SummaryDesc = ({
	desc,
	fallback = "No activity found.",
	hasActivity = false,
}) => {
	return (
		<div className={styles.SummaryDesc}>
			{hasActivity && <ActivityDesc desc={desc} />}
			{!hasActivity && <NoActivityDesc desc={fallback} />}
		</div>
	);
};

const CommunityActivitySummary = ({
	currentFacility = {},
	startDate,
	activity = [],
}) => {
	const [summary, setSummary] = useState(() => {
		return processLastLogins(activity);
	});

	// when 'activity' changes update the 'summary'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setSummary(() => {
			return processLastLogins(activity);
		});

		return () => {
			isMounted = false;
		};
	}, [activity]);

	return (
		<div className={styles.CommunityActivitySummary}>
			<div className={styles.CommunityActivitySummary_section}>
				<LoginsByDateViz logins={activity} />
			</div>

			{!isEmptyVal(currentFacility?.facilityID) && (
				<>
					<div className={styles.CommunityActivitySummary_section}>
						<SummaryDesc
							desc={getDateSummary(summary)}
							hasActivity={hasActivity(summary?.activitySummary?.dateSummary)}
						/>
					</div>
					<div className={styles.CommunityActivitySummary_section}>
						<SummaryDesc
							desc={getUserSummary(summary)}
							hasActivity={hasActivity(summary?.activitySummary?.userSummary)}
						/>
					</div>
					<div className={styles.CommunityActivitySummary_section}>
						<SummaryDesc
							desc={getUserSummaryList(summary)}
							hasActivity={hasActivity(summary?.activitySummary?.userList)}
						/>
					</div>
					<LoginsByUser loginsByUser={summary.loginsByUser} />
					<LoginsByDate loginsByDate={summary.loginsByDate} />
				</>
			)}
		</div>
	);
};

export default CommunityActivitySummary;

CommunityActivitySummary.defaultProps = {};

CommunityActivitySummary.propTypes = {};
