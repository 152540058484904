import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppProviders } from "./state/AppProviders";
import "./App.scss";
// components: pages, views etc.
import Main from "./components/Main";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./auth/ProtectedRoute";
import AuthTimeout from "./auth/AuthTimeout";
import DemoPage from "./pages/DemoPage";

export const history = createBrowserHistory();

function App() {
	return (
		<Router history={history}>
			<AppProviders>
				<AuthTimeout>
					<div className="App">
						<Main>
							<Switch>
								<Route exact path="/" component={LoginPage} />
								<ProtectedRoute path="/internal" component={AdminPage} />
								{/* DUMMY PAGE (FOR TESTING ONLY!!) */}
								<Route path="/demo" component={DemoPage} />
							</Switch>
						</Main>
					</div>
				</AuthTimeout>
			</AppProviders>
		</Router>
	);
}

export default App;
