import { vendors } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { isEmptyArray } from "./utils_types";

const defaultVendors = {
	index: 0,
	rows: 100,
};

/**
 * Fetches the list of available ALA Vendors from ALADVSystem DB
 * @param {String} token - Auth token
 * @param {Object} params - Query params (optional)
 * @returns {Array} - Returns array of Vendor objects
 */
const getVendorsList = async (token, params = { ...defaultVendors }) => {
	let url = currentEnv.base + vendors.getVendors;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches the list of available ALA Vendors from ALADVSystem DB
 * @param {String} token - Auth token
 * @param {String} desc - Query params (optional)
 * @returns {Object} - Returns Vendor object
 */
const getVendorByDesc = async (token, desc) => {
	let url = currentEnv.base + vendors.getVendor;
	url += "?" + new URLSearchParams({ ...defaultVendors });
	url += "?" + new URLSearchParams({ Description: desc });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches the list of available ALA Vendors from ALADVSystem DB
 * @param {String} token - Auth token
 * @param {String} name - Vendor 'Name' field
 * @returns {Object} - Returns Vendor object
 */
const getVendorByName = async (token, name) => {
	let url = currentEnv.base + vendors.getVendor;
	url += "?" + new URLSearchParams({ ...defaultVendors });
	url += "?" + new URLSearchParams({ Name: name });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches the list of available ALA Vendors from ALADVSystem DB
 * @param {String} token - Auth token
 * @param {Number} id - VendorID for vendor
 * @returns {Object} - Returns Vendor object
 */
const getVendorByID = async (token, id) => {
	let url = currentEnv.base + vendors.getVendor;
	url += "?" + new URLSearchParams({ ...defaultVendors });
	url += "?" + new URLSearchParams({ VendorID: id });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches a single vendors data map records.
 * @param {String} token - Auth token
 * @param {Number} vendorID - Numeric 'VendorID' value.
 * @returns {Array} - Returns array of data map records
 */
const getVendorDataMap = async (token, vendorID) => {
	let url = currentEnv?.base + vendors?.getVendorDataMaps2;
	url += "?" + new URLSearchParams({ VendorID: vendorID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Updates an existing VendorDataMap record and returns true, if successful.
 * @param {String} token - Auth token
 * @param {Object} updatedRecord - Updated 'VendorDataMap' record to be saved
 * @returns {Number} - Returns new record's 'VendorDataMapID'
 */
const updateExistingVendorMapping = async (token, updatedRecord = {}) => {
	let url = currentEnv.base + vendors.updateMapping;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updatedRecord),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Saves a new VendorDataMap record and returns it's 'id', upon creation.
 * @param {String} token - Auth token
 * @param {Object} mappingRecord - New 'VendorDataMap' record to be saved
 * @returns {Number} - Returns new record's 'VendorDataMapID'
 */
const saveNewVendorMapping = async (token, mappingRecord = {}) => {
	let url = currentEnv.base + vendors.saveMapping;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(mappingRecord),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Removes a single VendorDataMap record via the 'IsActive' flag.
 * @param {String} token - Auth token
 * @param {Object} mappingRecord - Target 'VendorDataMap' record to remove.
 * @returns {Boolean} - Returns whether successful (eg. true|false)
 */
const removeVendorMapping = async (token, mappingRecord = {}) => {
	let url = currentEnv.base + vendors.removeMapping;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(mappingRecord),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Removes a single VendorDataMap record via the 'IsActive' flag.
 * @param {String} token - Auth token
 * @param {Object} mappingRecord - Target 'VendorDataMap' record to remove.
 * @returns {Boolean} - Returns whether successful (eg. true|false)
 */
const deleteVendorMapping = async (token, mappingRecord = {}) => {
	let url = currentEnv.base + vendors.deactivateMapping;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(mappingRecord),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// VENDOR MATCHING UTILS //

// finds matching 'Vendor' record by vendors description/name
const matchVendorByDesc = (desc, vendors = []) => {
	const match = vendors.reduce((record, vendor) => {
		const newDesc = desc?.toLowerCase();
		const vendorDesc = vendor?.Description?.toLowerCase();

		if (newDesc === vendorDesc) {
			record = vendor;
			return record;
		}
		return record;
	}, {});

	return match;
};

// data processing utils //

// formats a single VendorDataMap record
const processDataMapRecord = (record = {}) => {
	const {
		IsActive,
		VendorID,
		VendorDataMapID,
		// FIELD-NAME
		FieldNameAla: ALAFieldName,
		FieldNameVendor: VendorFieldName,
		// FIELD-VALUE
		FieldValueAla: ALAFieldValue,
		FieldValueVendor: VendorFieldValue,
		// SOURCE-NAME
		SourceNameAla: ALASourceName,
		SourceNameVendor: VendorSourceName,
	} = record;

	const newRecord = {
		IsActive,
		VendorID,
		VendorDataMapID,
		ALAFieldName,
		VendorFieldName,
		ALAFieldValue,
		VendorFieldValue,
		ALASourceName,
		VendorSourceName,
	};

	return newRecord;
};

// formats an array of VendorDataMap records
const processDataMapRecords = (dataMapRecords = []) => {
	if (isEmptyArray(dataMapRecords)) return [];

	const processed = dataMapRecords?.map((record) =>
		processDataMapRecord(record)
	);

	return processed;
};

// prepares new record for saving new entry
const prepareNewRowEntry = (
	currentUser = {},
	selectedVendor = {},
	vals = {}
) => {
	const {
		ALASourceName,
		VendorSourceName,
		ALAFieldName,
		VendorFieldName,
		ALAFieldValue,
		VendorFieldValue,
	} = vals;
	const { userID } = currentUser;
	const vendorRecord = selectedVendor?.record ?? {};
	// default record to be populated
	const newRecord = {
		VendorDataMapID: 0,
		VendorID: vendorRecord?.VendorID,
		SourceNameAla: ALASourceName,
		SourceNameVendor: VendorSourceName,
		FieldNameAla: ALAFieldName,
		FieldNameVendor: VendorFieldName,
		FieldValueAla: ALAFieldValue,
		FieldValueVendor: VendorFieldValue,
		IsActive: true,
		CreatedDate: new Date().toISOString(),
		CreatedBy: userID,
		// CreatedLoginBy: "string",
		// CreatedStation: "string",
		// ModifiedDate: "2023-04-26T15:09:11.019Z",
		// ModifiedBy: "00000000-0000-0000-0000-000000000000",
		// ModifiedLoginBy: "string",
		// ModifiedStation: "string",
	};

	return newRecord;
};
// prepares map record to be updated server-side
const prepareMappingRecord = (currentUser = {}, selectedRowRecord = {}) => {
	const {
		ALASourceName,
		VendorSourceName,
		ALAFieldName,
		VendorFieldName,
		ALAFieldValue,
		VendorFieldValue,
	} = selectedRowRecord;
	const { userID } = currentUser;
	// default record to be populated
	const newRecord = {
		...selectedRowRecord,
		SourceNameAla: ALASourceName,
		SourceNameVendor: VendorSourceName,
		FieldNameAla: ALAFieldName,
		FieldNameVendor: VendorFieldName,
		FieldValueAla: ALAFieldValue,
		FieldValueVendor: VendorFieldValue,
		// IsActive: true,
		// CreatedDate: new Date().toISOString(),
		// CreatedBy: userID,
		// CreatedLoginBy: "string",
		// CreatedStation: "string",
		// ModifiedDate: "2023-04-26T15:09:11.019Z",
		ModifiedBy: userID,
		// ModifiedLoginBy: "string",
		// ModifiedStation: "string",
	};

	return newRecord;
};

export {
	// gets
	getVendorsList,
	getVendorByDesc,
	getVendorByName,
	getVendorByID,
	getVendorDataMap,
	// save/updates
	saveNewVendorMapping,
	updateExistingVendorMapping,
	removeVendorMapping,
	deleteVendorMapping,
};

export { matchVendorByDesc };

export {
	processDataMapRecord,
	processDataMapRecords,
	// record preparation utils
	prepareNewRowEntry,
	prepareMappingRecord,
};
