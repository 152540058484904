import React, { useState } from "react";
import styles from "../../css/facility/MoveToParentFacility.module.scss";
import { PropTypes } from "prop-types";
import {
	matchFacilityByName,
	moveFacilityTo,
	sortParents,
} from "../../helpers/utils_facility";
// components
import CustomSelect from "../shared/CustomSelect";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - Option #2: Moves child to a different parent facility

const customCSS = {
	target: {
		backgroundColor: "#ffffff",
		width: "35rem",
	},
	cancel: {
		padding: "1rem 1.8rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
	save: {
		padding: "1rem 1.6rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const MoveToParentFacility = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
}) => {
	const [targetParent, setTargetParent] = useState("");
	const { facilities } = currentUser;

	const handleParentSelect = (selection) => {
		const target = selection?.[0] ?? "";
		setTargetParent(target);
	};

	const moveChildToParent = async () => {
		const { token, facilities } = currentUser;
		const { facilityID } = currentFacility;
		const match = matchFacilityByName(targetParent, facilities); // get parent facilityID from 'targetParent'
		const parentID = match.facilityID;
		// fire off request
		const wasMoved = await moveFacilityTo(token, {
			actionType: "Move Community to New Parent",
			childFacilityID: facilityID,
			parentFacilityID: parentID,
			scheduleFor: null,
		});

		if (wasMoved) {
			return dispatchAlert("SUCCESS", {
				heading: `Child was moved to new parent!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred`,
				subheading: `Please try again later.`,
			});
		}
	};

	const cancelMove = () => {
		setTargetParent("");
	};

	return (
		<div className={styles.MoveToParentFacility}>
			<section className={styles.MoveToParentFacility_section}>
				<label htmlFor="targetParentFacility">
					Select Parent Facility to Move Child to:
				</label>
				<CustomSelect
					key={`Parents: ${facilities?.length}`}
					name="targetParentFacility"
					id="targetParentFacility"
					placeholder="Select target facility..."
					disableMultiSelect={true}
					options={sortParents(facilities)}
					handleCustomSelect={handleParentSelect}
					customStyles={customCSS.target}
				/>
			</section>
			<section className={styles.MoveToParentFacility_actions}>
				<ButtonSM handleClick={cancelMove} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={
						isEmptyVal(currentFacility.facilityID) || isEmptyVal(targetParent)
					}
					handleClick={moveChildToParent}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</section>
		</div>
	);
};

export default MoveToParentFacility;

MoveToParentFacility.defaultProps = {};

MoveToParentFacility.propTypes = {};
