import React from "react";
import styles from "../../css/charts/LineChartWrapper.module.scss";
import { PropTypes } from "prop-types";
import { Line } from "react-chartjs-2";

/**
 * 'LineChartWrapper': wraps the <Line/> component & accepts the formatted data.
 * - 'data': MUST be formatted & processed prior to being provided.
 * - 'title': title of chart/data-viz
 */

const LineChartWrapper = ({ title, subtitle, data }) => {
	return (
		<div className={styles.LineChartWrapper}>
			<div className={styles.LineChartWrapper_header}>
				<h4 className={styles.LineChartWrapper_header_title}>{title}</h4>
				<p className={styles.LineChartWrapper_header_title}>{subtitle}</p>
			</div>
			<div className={styles.LineChartWrapper_chart}>
				<Line data={data} />
			</div>
		</div>
	);
};

export default LineChartWrapper;

LineChartWrapper.defaultProps = {};

LineChartWrapper.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	data: PropTypes.object,
};
