import { isEmptyVal } from "./utils_types";

// formats 'alert.type' to match a valid alert
const getAlertType = (val) => {
	if (isEmptyVal(val)) return "SUCCESS";
	return val?.toUpperCase();
};

const hasMsg = (msg = {}) => {
	const hasHeading = !isEmptyVal(msg?.heading);
	const hasSubheading = !isEmptyVal(msg?.subheading);

	if (!hasHeading && !hasSubheading) {
		return false;
	} else {
		return true;
	}
};

// ALERTS FOR OFFLINE

export { getAlertType, hasMsg };
