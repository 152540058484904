import React from "react";
import styles from "../css/views/RenameCommunityView.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import { purple, red } from "../helpers/utils_styles";
import FacilitySummary from "../components/facility/FacilitySummary";
import RenameFacility from "../components/facility/RenameFacility";
import ButtonSM from "../components/shared/ButtonSM";
import { isEmptyVal } from "../helpers/utils_types";
import { changeFacilityName } from "../helpers/utils_facility";

const customCSS = {
	cancel: {
		padding: ".8rem 1.4rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
	},
	submit: {
		padding: ".8rem 1.4rem",
		backgroundColor: purple[700],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const RenameCommunityView = ({
	allFacilities = [],
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
	dispatchToState,
}) => {
	const { formState, handleChange, handleReset } = useForm({
		newCommunityName: "",
	});
	const { values } = formState;

	const saveNameChange = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const { newCommunityName } = values;

		// fire off request
		const wasChanged = await changeFacilityName(token, {
			guidFacility: facilityID,
			CommunityName: newCommunityName,
		});
		console.log(`Changing community name to:`, values.newCommunityName);

		if (wasChanged) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Community name was changed successfully!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an error. Please try again later!`,
			});
		}
	};
	const cancelNameChange = (e) => {
		handleReset(e);

		console.log(`Cancelling name change!`);
	};
	return (
		<div className={styles.RenameCommunityView}>
			<div className={styles.RenameCommunityView_selectedName}>
				{currentFacility?.communityName}
			</div>
			<div className={styles.RenameCommunityView_main}>
				<FacilitySummary currentFacility={currentFacility} />
				<RenameFacility
					vals={values}
					handleChange={handleChange}
					saveNameChange={saveNameChange}
					cancelNameChange={cancelNameChange}
				/>
			</div>
		</div>
	);
};

export default RenameCommunityView;

RenameCommunityView.defaultProps = {};

RenameCommunityView.propTypes = {};
