import React, { useState, useEffect, useCallback } from "react";
import styles from "../css/views/DisableFacilityAppAccessView.module.scss";
import { PropTypes } from "prop-types";
import { getAppAccessByFacility, SERVICES } from "../helpers/utils_apps";
import { setFacilityAppAccess } from "../helpers/utils_facility";
import { red } from "../helpers/utils_styles";
import { useForm } from "../utils/useForm";
import { isEmptyVal } from "../helpers/utils_types";
import { phoneReg } from "../helpers/utils_validation";
import { featureFlags } from "../helpers/utils_permissions";
// components
import AppAccessController from "../components/facility/AppAccessController";
import EmarAccessController from "../components/facility/EmarAccessController";
import StaxAccessController from "../components/stax/StaxAccessController";

// REQUIREMENTS:
// - View facility's current app access:
//    - SeniorCareVB
//    - AdminPortal
//    - AdvantageTracker
// - Disable/Enable app access for entire facility:
//    - SeniorCareVB
//    - AdminPortal
//    - AdvantageTracker

const customCSS = {
	save: {
		padding: ".7rem 1.5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
};

const getAppRecord = (records = {}) => {};

const wasTouched = (keyName, touched) => {
	const wasTouched = touched[keyName] ?? false;
	return wasTouched;
};

const enableBtn = (formState, currentFacility) => {
	if (isEmptyVal(currentFacility?.facilityID)) return false;

	const { touched } = formState;
	const trackerTouched = wasTouched("AdvantageTracker", touched);
	const legacyTouched = wasTouched("SeniorCareVB", touched);

	const hasChanges = trackerTouched || legacyTouched;

	return hasChanges;
};

const apps = {
	AdminPortal: {
		appName: "AdminPortal",
		label: "Admin Portal (SSO)",
	},
	AdvantageTracker: {
		appName: "AdvantageTracker",
		label: "Advantage Tracker",
	},
	SeniorCareVB: {
		appName: "SeniorCareVB",
		label: "Legacy Senior Care (EHR)",
	},
	// CHART MEDS INTERFACE
	Emar: {
		appName: "Chart Meds",
		label: "Chart Meds (EMAR)",
	},
	// STAX INTERFACE
	Stax: {
		appName: "Stax",
		label: "Stax (ePay)",
	},
};

const DisableFacilityAppAccessView = ({
	allFacilities = [],
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
}) => {
	// stores app access states for each app (eg. { tracker: {...}, legacy: {...} etc etc })
	const [rawAccess, setRawAccess] = useState({
		tracker: {},
		portal: {},
		legacy: {},
		emar: {}, // ChartMeds Interface
		// stax: {}
	});

	// triggers alert & resets state if request failed
	const handleAlert = (appName, wasUpdated) => {
		if (wasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `App access was updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `Update failed. Please try again later.`,
			});
		}
	};

	const handleChartMeds = () => {
		setRawAccess({
			...rawAccess,
		});
	};

	const fetchFacilityInfo = useCallback(async () => {
		if (isEmptyVal(currentFacility?.facilityID)) return;
		// fetch facility's app access records here...
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const appAccess = await getAppAccessByFacility(token, facilityID);
		console.log(`App Access(fetchFacilityInfo):`, appAccess);

		setRawAccess(appAccess);

		return appAccess;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (currentFacility?.facilityID) {
			fetchFacilityInfo();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility?.facilityID, fetchFacilityInfo]);

	return (
		<div className={styles.DisableFacilityAppAccessView}>
			<div className={styles.DisableFacilityAppAccessView_facility}>
				<span className={styles.DisableFacilityAppAccessView_facility_title}>
					Community Name: <b>{currentFacility?.communityName ?? ""}</b>
				</span>
			</div>
			<div className={styles.DisableFacilityAppAccessView_header}>
				<div className={styles.DisableFacilityAppAccessView_header_title}>
					Facility Application Access
				</div>
				<div className={styles.DisableFacilityAppAccessView_header_subtitle}>
					Change Facility's App Access.
				</div>
				<div className={styles.DisableFacilityAppAccessView_header_subtitle}>
					Warning! Changes made here will effect the application access for an
					entire facility's users.
				</div>
			</div>
			{/* PORTAL ACCESS */}
			{featureFlags.facility.enablePortalAccessToggle && (
				<div className={styles.DisableFacilityAppAccessView_access}>
					<AppAccessController
						key={`ADMIN-PORTAL-ACCESS: ${rawAccess?.portal?.IsAccessible}`}
						app={apps["AdminPortal"]}
						initialVal={rawAccess?.["portal"]?.IsAccessible ?? true}
						currentUser={currentUser}
						currentFacility={currentFacility}
						handleAlert={handleAlert}
					/>
				</div>
			)}
			{/* TRACKER ACCESS */}
			{featureFlags.facility.enableTrackerAccessToggle && (
				<div className={styles.DisableFacilityAppAccessView_access}>
					<AppAccessController
						key={`ADVANTAGE-TRACKER-ACCESS: ${rawAccess?.tracker?.IsAccessible}`}
						app={apps["AdvantageTracker"]}
						initialVal={rawAccess?.["tracker"]?.IsAccessible ?? false}
						currentUser={currentUser}
						currentFacility={currentFacility}
						handleAlert={handleAlert}
					/>
				</div>
			)}
			{/* LEGACY ACCESS */}
			{featureFlags.facility.enableLegacyAccessToggle && (
				<div className={styles.DisableFacilityAppAccessView_access}>
					<AppAccessController
						key={`LEGACY-EHR-ACCESS: ${rawAccess?.legacy?.IsAccessible}`}
						app={apps["SeniorCareVB"]}
						initialVal={rawAccess?.["legacy"]?.IsAccessible ?? true}
						currentUser={currentUser}
						currentFacility={currentFacility}
						handleAlert={handleAlert}
					/>
				</div>
			)}
			{/* CHART-MEDS EMAR ACCESS */}
			{featureFlags.facility.enableEmarAccessToggle && (
				<div className={styles.DisableFacilityAppAccessView_access}>
					<EmarAccessController
						key={`EMAR-ACCESS: ${rawAccess?.["emar"]?.IsAccessible}`}
						app={apps["Emar"]}
						initialVal={rawAccess?.["emar"]?.IsAccessible ?? false}
						currentUser={currentUser}
						currentFacility={currentFacility}
						handleAlert={handleAlert}
					/>
				</div>
			)}
			{/* STAX APP ACCESS */}
			{featureFlags.facility.enableStaxAccessToggle && (
				<div className={styles.DisableFacilityAppAccessView_access}>
					<StaxAccessController
						key={`STAX-ACCESS: ${rawAccess?.["stax"]?.IsAccessible}`}
						app={apps["Stax"]}
						initialVal={rawAccess?.["stax"]?.IsAccessible ?? false}
						currentUser={currentUser}
						currentFacility={currentFacility}
						handleAlert={handleAlert}
					/>
				</div>
			)}
		</div>
	);
};

export default DisableFacilityAppAccessView;

DisableFacilityAppAccessView.defaultProps = {};

DisableFacilityAppAccessView.propTypes = {};
