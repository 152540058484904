import { settings } from "./utils_endpoints";
import { currentEnv } from "./utils_env";

const params = {
	Name: "PORTAL_LOGIN",
};

const cacheNames = [
	"READ_ONLY_USERS",
	"CHARTING",
	"LOG",
	"SPECIAL10",
	"SPECIAL1",
	"SPECIAL1NEWRULES",
	"SPECIAL3",
	"SPECIAL4",
	"SPECIAL5",
	"SPECIAL6",
	"MEDONLY",
	"SPECIAL_CAL",
	"SPECIAL_HARMONY",
	"SPECIAL_PSL",
	"SPECIAL_SUMMERSET",
	"Assessments",
	"SPECIAL9",
	"ResidentEvaluations",
	"SPECIAL7",
	"SPECIAL8",
	"QM_FACILITIES",
	"YARDI_NUMBER_REQUIRED",
	"EXTENDEDASSESSMENT",
	"SPECIAL_FL",
	"SPECIAL_PAINTBRUSH",
	"GEN_INTERVENTIONS",
	"GEN_GOALS",
	"REGIONAL_REPORTS",
	"GEN_PROBLEMS",
	"ResidentWeights",
	"SPECIAL_NC",
	"SPECIAL_SRG_FL",
	"INCIDENT_TYPES",
	"INCIDENT_LOCATIONS",
	"Residents",
	"PORTAL_LOGIN",
	"USER_MAINT_PORTAL",
	"COMMUNITY_MAINT_PORTAL",
	"SPECIAL12",
];

// fetches settings object
const getSSOSettings = async (token) => {
	let url = currentEnv.base + settings.getSSO2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data?.[0];
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// saves/updates SSO settings
const saveSSOSettings = async (token, isEnabled) => {
	let url = currentEnv.base + settings.toggleSSO;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ ID: 3, Name: "PORTAL_LOGIN", Display: isEnabled }),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// clears a given cache by cacheName
const clearCache = async (token, cacheName = "PORTAL_LOGIN") => {
	let url = currentEnv.base + settings.clearCache;
	url += "?" + new URLSearchParams({ cacheName });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const saveSSOAndClearCache = async (token, isEnabled) => {
	const [wasUpdated, wasCleared] = await Promise.all([
		saveSSOSettings(token, isEnabled),
		clearCache(token),
	]);

	return {
		wasUpdated,
		wasCleared,
	};
};

// OTHER ADMIN SETTINGS //

const getUserMaintenanceSettings = async (token) => {
	let url = currentEnv.base + settings.getSetting;
	url += "?" + new URLSearchParams({ ID: 1 });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response?.Data?.[0] ?? {};
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
const getCommunityMaintenanceSettings = async (token) => {
	let url = currentEnv.base + settings.getSetting;
	url += "?" + new URLSearchParams({ ID: 2 });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response?.Data?.[0] ?? {};
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const getCurrentMaintenanceSettings = async (token) => {
	const [userMtc, communityMtc] = await Promise.all([
		getUserMaintenanceSettings(token),
		getCommunityMaintenanceSettings(token),
	]);

	console.group("Settings");
	console.log("userMtc:", userMtc);
	console.log("communityMtc:", communityMtc);
	console.groupEnd();

	return {
		userMtc,
		communityMtc,
	};
};

const saveUserMaintenanceSettings = async (token, isEnabled) => {
	let url = currentEnv.base + settings.anySetting;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				ID: 1,
				Name: "USER_MAINT_PORTAL",
				Display: isEnabled,
			}),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
const saveCommunityMaintenanceSettings = async (token, isEnabled) => {
	let url = currentEnv.base + settings.anySetting;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				ID: 2,
				Name: "COMMUNITY_MAINT_PORTAL",
				Display: isEnabled,
			}),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// VARIOUS URLS to Access In Legacy //

const siteUrls = {
	cache: "/Account/cacheadmin.aspx",
	admin: "/Account/newCommunityAdmin.aspx",
};

export { getSSOSettings, saveSSOSettings, clearCache, saveSSOAndClearCache };

// other admin settings
export {
	getUserMaintenanceSettings,
	saveUserMaintenanceSettings,
	getCommunityMaintenanceSettings,
	saveCommunityMaintenanceSettings,
	// fetches all
	getCurrentMaintenanceSettings,
};

// legacy site urls
export { siteUrls, cacheNames };
