import { user } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { updateUserAdminModel } from "./utils_models";

/**
 * Creates a new facility admin entry.
 * @param {String} token - Auth token
 * @param {String} facilityId - String guid for facility
 * @param {Object} adminModel - Object of new admin values.
 * @param {String} adminModel.AdminEmail - Admin email
 * @param {String} adminModel.AdminPassword - Admin password
 * @param {String} adminModel.AdminFirstName - Admin's first name
 * @param {String} adminModel.AdminLastName - Admin's last name
 * @returns {Object} - Returns if request was successful
 */
const createFacilityAdmin = async (token, facilityId, adminModel) => {
	let url = currentEnv.base + user.create.facilityAdmin;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(adminModel),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Makes one or more users a facility admin via toggling 'bitFacilityAdministrator' to true
 * @param {String} token - Auth token
 * @param {Array.<{guidUser: String, bitFacilityAdministrator: Boolean}>} userRecords - An array of user-field objects w/ 'userID' and 'bitFacilityAdmin'
 * @param {String} userRecord.userID - 'guidUser' field for target user.
 * @param {String} userRecord.bitFacilityAdministrator - 'bitFacilityAdministrator' field.
 * @returns {Object} - REturns whether request was successful
 */
const makeUsersFacilityAdmin = async (token, userRecords = []) => {
	let url = currentEnv.base + user.update.users;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(userRecords),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Makes an existing user a facility admin via toggling 'bitFacilityAdministrator' to true
 * @param {String} token - Auth token
 * @param {Array.<{guidUser: String, bitFacilityAdministrator: Boolean}>} userRecords - Object record of target user values.
 * @param {String} userRecord.userID - 'guidUser' field for target user.
 * @param {String} userRecord.bitFacilityAdministrator - 'bitFacilityAdministrator' field.
 * @returns {Object} - REturns whether request was successful
 */
const makeUserListFacilityAdmins = async (token, targetUsers = []) => {
	// throw if not an array
	if (!Array.isArray(targetUsers))
		throw new Error(`❌ Whoops! 'targetUsers' is NOT an array!!`, targetUsers);
	// generates/formats required fields and models
	const partialModels = generateFacilityAdminModels(targetUsers);
	// fires off request for ALL users at once
	const wasUpdated = await makeUsersFacilityAdmin(token, partialModels);

	if (!Array.isArray(targetUsers))
		throw new Error(`❌ Whoops! 'targetUsers' is NOT in array form.`);

	if (wasUpdated) {
		console.log(`✅ Success! User(s) were made facility admin(s)`, wasUpdated);
		return wasUpdated;
	} else {
		console.log(
			`❌ Ooops! Failed to make user(s) facility admin(s):`,
			wasUpdated
		);
		return wasUpdated;
	}
};

// SET USER TYPE REQUEST UTILS //

// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsAdmin = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asAdmin;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsContractor = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asContractor;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsFacilityAdmin = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asFacilityAdmin;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsManager = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asManager;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsStaff = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asStaff;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsSuperUser = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asSuperUser;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// handles ALL 'UserType' updates regardless of type
const updateUserType = async (token, userID, userType) => {
	switch (userType) {
		case "Admin":
		case "Administrator": {
			const wasUpdated = await updateUserAsAdmin(token, userID);
			return wasUpdated;
		}
		case "FacilityAdmin":
		case "Facility Admin":
		case "Facility Administrator": {
			const wasUpdated = await updateUserAsFacilityAdmin(token, userID);
			return wasUpdated;
		}
		case "SuperUser":
		case "Super User":
		case "Super-User": {
			const wasUpdated = await updateUserAsSuperUser(token, userID);
			return wasUpdated;
		}
		case "Staff": {
			const wasUpdated = await updateUserAsStaff(token, userID);
			return wasUpdated;
		}
		case "Contractor": {
			const wasUpdated = await updateUserAsContractor(token, userID);
			return wasUpdated;
		}
		case "Manager": {
			const wasUpdated = await updateUserAsManager(token, userID);
			return wasUpdated;
		}
		default:
			throw new Error(`❌ Ooops! Unrecognized 'userType' provided:`, userType);
	}
};

/**
 * Handles applying multiple user type updates to a single user at once.
 * @param {String} token - Auth token
 * @param {String} userID - Target user guid string
 * @param {Array} userTypes - Array of user type string indicators
 * @returns {Array} - Returns array of HTTP responses.
 */
const updateUserTypesMany = async (token, userID, userTypes = []) => {
	const responses = await Promise.allSettled([
		userTypes.includes("Administrator") &&
			updateUserType(token, userID, "Admin"),
		userTypes.includes("Facility Administrator") &&
			updateUserType(token, userID, "Facility Admin"),
		userTypes.includes("Super User") &&
			updateUserType(token, userID, "Super User"),
	]);

	console.log(`Response(s):`, responses);

	return responses;
};

const getTypeNamesFromSelections = (vals = {}) => {
	const { enableAdminType, enableFacilityAdminType, enableSuperUserType } =
		vals;
	let typesList = [];
	if (enableAdminType) {
		typesList.push("Admin");
	}
	if (enableFacilityAdminType) {
		typesList.push("Facility Admin");
	}
	if (enableSuperUserType) {
		typesList.push("Super User");
	}

	return typesList;
};

// USER ADMIN UTILS //

/**
 * Generates required models for assigning existing users as facility admins.
 * @param {Array.<Object>} targetUsers - Array of target users in client-format.
 * @returns {Array.<Object>} - Returns array of partial user objects: {'guidUser', 'bitFacilityAdministrator'}
 */
const generateFacilityAdminModels = (targetUsers = []) => {
	// extracts 'userID' from each user & merges w/ 'bitFacilityAdministrator' field for each record
	const partialUserModels = targetUsers.map((user) => {
		const { userID } = user;
		const adminModel = {
			guidUser: userID,
			bitFacilityAdministrator: true,
		};
		return adminModel;
	});
	return partialUserModels;
};

// takes the selected users array & generates 'NewUserAdmin' model for each
const generateNewAdminModels = (targetUsers = []) => {
	const adminModels = [];
	targetUsers.map((user) => {
		const { firstName, lastName, email, userID } = user;
		const newModel = updateUserAdminModel({
			adminUserID: userID,
			adminEmail: email,
			adminFirstName: firstName,
			adminLastName: lastName,
			adminPassword: "THIS-NEEDS-BE-FILLED-OUT",
		});
		return adminModels.push(newModel);
	});

	return adminModels;
};

export { generateNewAdminModels, generateFacilityAdminModels };

export {
	createFacilityAdmin,
	makeUsersFacilityAdmin,
	makeUserListFacilityAdmins,
};

export { getTypeNamesFromSelections };

// generic user type handlers
export {
	// most common user type(s)
	updateUserAsAdmin,
	updateUserAsFacilityAdmin,
	updateUserAsSuperUser,
	// less used
	updateUserAsStaff,
	updateUserAsContractor,
	updateUserAsManager,
	// universal 'userType' handler
	updateUserType,
	updateUserTypesMany,
};
