import { getUserAccessByEmail } from "./utils_auth";
import {
	getFacilitiesByUserEmail,
	getParents,
	getParentsMap,
	processAndFormatCommunities,
	processAndFormatParentsMap,
	processFacilityList,
} from "./utils_facility";
import { getUserSecurityInfo } from "./utils_security";
import { isEmptyVal } from "./utils_types";
import { processUserInfo } from "./utils_user";

const getInitialResource = async (auth) => {
	if (isEmptyVal(auth?.token) || isEmptyVal(auth?.username)) return;

	const { token, username } = auth;
	const [facilityList, userAccess, securityInfo, parents] = await Promise.all([
		getFacilitiesByUserEmail(token, username),
		getUserAccessByEmail(token, username),
		getUserSecurityInfo(token, username),
		getParents(token),
	]);

	return {
		facilityList,
		userAccess,
		auth,
		securityInfo,
		parents,
	};
};

const populateState = (newData, state) => {
	const { facilityList, userAccess, auth, securityInfo, parents } = newData;

	// process data for client
	const userInfo = processUserInfo(securityInfo);
	// facility list (client-formatted)
	const list = processFacilityList(facilityList);
	// parents (client-formatted)
	const parentsMap = processAndFormatParentsMap(parents);
	const communityMap = processAndFormatCommunities(facilityList);
	const userLogins = userInfo.userLogins;
	const userID = !userInfo?.advUser?.guidUser
		? userLogins?.[0]?.UserLoginID
		: userInfo?.advUser?.guidUser;
	const first =
		userInfo?.userProfile?.firstName ?? userInfo?.advUser?.strFirstName;
	const last =
		userInfo?.userProfile?.lastName ?? userInfo?.advUser?.strLastName;

	const newState = {
		...state,
		currentUser: {
			...state.currentUser,
			firstName: first,
			lastName: last,
			middleName: "",
			userID: userID,
			profileID: userInfo?.userProfile?.UserProfileID,
			token: auth.token,
			username: auth.username,
			password: auth.password,
			facilities: list,
			security: { ...userInfo },
		},
		parentsMap: parentsMap,
		facilitiesMap: communityMap,
	};

	return newState;
};

export { getInitialResource };
export { populateState };
