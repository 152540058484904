import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/banners/CurrentBannerEntry.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { matchFacilityByID } from "../../helpers/utils_facility";
import { format } from "date-fns";
import { blue, green, red } from "../../helpers/utils_styles";
import ModalLG from "../shared/ModalLG";
import UpdateBannerForm from "./UpdateBannerForm";
import { useForm } from "../../utils/useForm";
import {
	deleteServiceBanner,
	updateServiceBanner,
	updateServiceBannerModel,
} from "../../helpers/utils_banners";

// REQUIREMENTS:
// - Community Name
// - Message ('ExpiredMessage')
// - Expiry ('ExpiredDate')
// - IsEnabled ('IsEnable')

const customCSS = {
	confirmed: {
		color: "#ffffff",
		backgroundColor: green[500],
	},
	notConfirmed: {
		color: "#ffffff",
		backgroundColor: red[600],
	},
	isEditing: {
		// boxShadow: `10px 10px 40px 40px #0000ff4d`,
	},
	name: {
		color: blue[700],
		fontWeight: 800,
	},
	date: {
		fontFamily: "Open Sans",
	},
};

const getFacilityName = (bannerEntry = {}, parentsMap = {}) => {
	const { raw } = parentsMap;
	const { FacilityId: id } = bannerEntry;
	const match = matchFacilityByID(id, raw);
	const name =
		match?.communityName ?? match?.CommunityName ?? match?.FacilityName;

	return name;
};

const CurrentBannerEntry = ({
	bannerEntry = {},
	parentsMap = {},
	facilitiesMap = {},
	currentUser = {},
	acknowledgement = {},
	refetchBanners,
	dispatchAlert,
}) => {
	// const { raw: facilities, ids, names } = parentsMap;
	const { raw: facilities } = facilitiesMap;
	const wasAcked = !isEmptyObj(acknowledgement);
	// const wasAcked = false;
	// editable service banner state
	const { formState, setFormState, handleChange, handleCheckbox } = useForm({
		isEnabled: bannerEntry?.IsEnable,
		expiry: bannerEntry?.ExpiredDate,
		message: bannerEntry?.ExpiredMessage,
		createdDate: bannerEntry?.CreatedDate,
		bannerID: bannerEntry?.FacilityBannerId,
		wasAcknowledged: wasAcked,
		facilityName: getFacilityName(bannerEntry, facilitiesMap),
		facilityID: bannerEntry?.FacilityId,
	});
	const { values, touched } = formState;
	const [showEditEntryModal, setShowEditEntryModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	// update(s) state
	const [wasSaved, setWasSaved] = useState(false);
	const resetModal = useCallback(() => {
		if (wasSaved) {
			setShowEditEntryModal(false);
		}
	}, [wasSaved]);

	const editEntry = () => {
		setIsEditing(true);
		setShowEditEntryModal(true);
	};
	const closeEdit = () => {
		setIsEditing(false);
		setShowEditEntryModal(false);
	};

	const handleDate = (name, val) => {
		if (isEmptyVal(val)) {
			return setFormState({
				...formState,
				values: {
					...values,
					expiry: "",
				},
			});
		} else {
			return setFormState({
				...formState,
				values: {
					...values,
					expiry: val,
				},
			});
		}
	};

	const saveBannerUpdates = async (e) => {
		const { token } = currentUser;
		const updatedModel = updateServiceBannerModel(values);

		const wasSaved = await updateServiceBanner(token, updatedModel);
		// const wasSaved = true;

		if (wasSaved) {
			dispatchAlert("SUCCESS", {
				heading: "Banner was updated!",
			});
			setWasSaved(true);
			closeEdit();
			return refetchBanners();
		} else {
			dispatchAlert("ERROR", {
				heading: "Update failed. Sorry.",
			});
			setWasSaved(false);
			closeEdit();
			return refetchBanners();
		}
	};

	const deleteEntry = async () => {
		const { token } = currentUser;
		const { bannerID } = values;

		const dataResp = await deleteServiceBanner(token, bannerID);

		if (dataResp) {
			dispatchAlert("SUCCESS", {
				heading: "Banner was deleted!",
			});
			setWasSaved(true);
			refetchBanners();
		} else {
			dispatchAlert("ERROR", {
				heading: `Failed: ${dataResp?.ErrorMessage}`,
			});
			return setWasSaved(false);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		resetModal();

		return () => {
			isMounted = false;
		};
	}, [resetModal]);

	// set banner data to state for updates
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(bannerEntry?.FacilityBannerId)) {
			setFormState({
				...formState,
				values: {
					...values,
					isEnabled: bannerEntry?.IsEnable,
					expiry: bannerEntry?.ExpiredDate,
					message: bannerEntry?.ExpiredMessage,
					createdDate: bannerEntry?.CreatedDate,
					bannerID: bannerEntry?.FacilityBannerId,
					wasAcknowledged: wasAcked,
					facilityName: getFacilityName(bannerEntry, facilitiesMap),
					facilityID: bannerEntry?.FacilityId,
				},
			});
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={styles.CurrentBannerEntry}
				style={isEditing ? customCSS?.isEditing : {}}
			>
				<div className={styles.CurrentBannerEntry_top}>
					<div className={styles.CurrentBannerEntry_top_title}>
						Service Banner
					</div>
				</div>
				<div className={styles.CurrentBannerEntry_details}>
					<div className={styles.CurrentBannerEntry_details_item}>
						Effected Community:{" "}
						<b style={customCSS.name}>{values?.facilityName}</b>
					</div>
					<div className={styles.CurrentBannerEntry_details_item}>
						Created Date:{" "}
						<b style={customCSS.date}>
							{format(new Date(values?.createdDate), "MM/DD/YYYY")}
						</b>
					</div>
					<div className={styles.CurrentBannerEntry_details_item}>
						Expiration Date:{" "}
						<b style={customCSS.date}>
							{format(new Date(values?.expiry), "MM/DD/YYYY")}
						</b>
					</div>
					<div className={styles.CurrentBannerEntry_details_confirmed}>
						Acknowledged?{" "}
						<b
							style={
								values?.wasAcknowledged
									? customCSS.confirmed
									: customCSS.notConfirmed
							}
						>
							{values?.wasAcknowledged ? "Yes" : "No"}
						</b>
					</div>
				</div>
				<div className={styles.CurrentBannerEntry_actions}>
					<button
						type="button"
						onClick={editEntry}
						className={styles.CurrentBannerEntry_actions_edit}
					>
						Edit Entry
					</button>
				</div>
			</div>

			{showEditEntryModal && (
				<ModalLG title="Edit Service Banner Entry" closeModal={closeEdit}>
					<UpdateBannerForm
						key={`UPDATE-BANNER-${bannerEntry?.FacilityBannerId}`}
						vals={values}
						formState={formState}
						parentsMap={parentsMap}
						handleChange={handleChange}
						handleCheckbox={handleCheckbox}
						handleDate={handleDate}
						handleDelete={deleteEntry}
						saveBannerUpdates={saveBannerUpdates}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default CurrentBannerEntry;

CurrentBannerEntry.defaultProps = {};

CurrentBannerEntry.propTypes = {};
