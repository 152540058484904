import React from "react";
import styles from "../../css/facility/SuccessIndicator.module.scss";
import sprite from "../../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";

const SuccessIndicator = ({ children }) => {
	return (
		<div className={styles.SuccessIndicator}>
			<div className={styles.SuccessIndicator_top}>
				<div className={styles.SuccessIndicator_top_iconWrapper}>
					<svg className={styles.SuccessIndicator_top_iconWrapper_icon}>
						<use xlinkHref={`${sprite}#icon-check_circle`}></use>
					</svg>
				</div>
				<div className={styles.SuccessIndicator_top_title}>Success!</div>
			</div>
			<div className={styles.SuccessIndicator_top}>{children}</div>
		</div>
	);
};

export default SuccessIndicator;

SuccessIndicator.defaultProps = {};

SuccessIndicator.propTypes = {
	children: PropTypes.any,
};
