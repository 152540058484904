/**
 * 'UserAdminModel': custom data structure for creating a new user admin account for a facility.
 * - Creates a user admin account structure.
 */
class UserAdminModel {
	constructor(userVals = {}) {
		this._adminEmail = userVals?.adminEmail ?? "";
		this._adminPassword = userVals?.adminPassword ?? "";
		this._adminFirstName = userVals?.adminFirstName ?? "";
		this._adminLastName = userVals?.adminLastName ?? "";

		this._model = {
			AdminEmail: this._adminEmail,
			AdminPassword: this._adminPassword,
			AdminFirstName: this._adminFirstName,
			AdminLastName: this._adminLastName,
		};
	}
	getModel() {
		return this._model;
	}
}

class NewFacilityModel {
	constructor(facilityVals = {}) {
		// parent facility
		this._parentID = facilityVals?.parentFacilityID ?? "";
		// facilityVals?.parentFacilityID ?? "00000000-0000-0000-0000-000000000000";

		this._communityName = facilityVals?.communityName;
		this._street = facilityVals?.street;
		this._suiteNumber = facilityVals?.suiteNumber;
		this._city = facilityVals?.city;
		this._state = facilityVals?.state;
		this._zip = facilityVals?.zip;
		this._phoneNumber = facilityVals?.phoneNumber;
		this._faxNumber = facilityVals?.faxNumber;
		this._email = facilityVals?.email;
		this._execDirector = facilityVals?.execDirector;
		this._alaDirector = facilityVals?.alaDirector;
		this._licenseNumber = facilityVals?.licenseNumber;
		this._localTimeZone = facilityVals?.localTimeZone;
		// community settings
		this._enableMeds = facilityVals?.enableMeds;
		this._enableContacts = facilityVals?.enableContacts;
		this._enableAssessments = facilityVals?.enableAssessments;
		this._enableALAAdmin = facilityVals?.enableALAAdmin;

		this._model = {
			guidFacility: "00000000-0000-0000-0000-000000000000",
			CommunityName: this._communityName,
			ExecDirector: this._execDirector,
			ALDirector: this._alaDirector,
			Address1: this._street,
			Address2: this._suiteNumber,
			City: this._city,
			State: this._state,
			ZIP: this._zip,
			Phone: this._phoneNumber,
			Fax: this._faxNumber,
			Email: this._email,
			LicenseNumber: this._licenseNumber,
			guidParentFacility: this._parentID,
			APName: "",
			APAddress1: "",
			APAddress2: "",
			APCity: "",
			APState: "",
			APZIP: "",
			APEmail: "",
			APCorpName: "",
			APLastINVno: 0,
			APLastQTY: 0,
			APRate: 0,
			LocalTimeZoneID: this._localTimeZone,
			bitALAAssessment: 1,
			bitMedications: this._enableMeds,
			bitContacts: this._enableContacts,
			bitAssessmentALA: this._enableAssessments,
			QMLink: false,
			QMAuthID: "",
			MedicaidProviderNum: "",
			CreatedDateTime: new Date().toISOString(),
			bitSuspendSubscription: false,
			SuspendReasonTypeId: null,
			SuspendReasonNotes: "",
			SuspendStartDate: "",
		};
	}
	getModel() {
		return this._model;
	}
}

class ExportTransportRuleModel {
	constructor(config = {}) {
		this._methodName = config?.exportMethod ?? "SendToEmailAddress";
		this._facilityName = config?.targetFacilityName ?? "";

		this._model = {
			SendToEmailAddress: this._methodName === "SendToEmailAddress",
			SaveToAzure: this._methodName === "SendToAzure",
			SaveToDatabase: this._methodName === "SendToDatabase",
			SaveToFtp: this._methodName === "SendToFtp",
			SaveToHttp: this._methodName === "SendToHttp",
			EmailSubject: null,
			EmailBody: null,
			EmailBodySettingSector: "Notification",
			EmailBodySettingName: "EmailExportFacilityBody",
			EmailBodyContentVars: "FACILITY-NAME",
			EmailBodyContentParms: {
				"FACILITY-NAME": this._facilityName,
			},
			EmailIsHtml: true,
			EmailAddresses: ["jose@aladvantage.com;miller.brian@gmail.com"],
			EmailReplyAddresses: [],
			AzureFolderPath: null,
			DatabaseName: null,
			TableName: null,
			FieldName: null,
			UrlPath: null,
			hdrParms: {},
			qryParms: {},
			formParms: {},
		};
	}
}

/**
 * Model updaters/generator utils:
 * - Generate and populate a new data model
 */

const updateNewFacilityModel = (vals = {}) => {
	const base = new NewFacilityModel(vals);
	const model = base.getModel();
	console.log(`Model:`, model);
	return model;
};
const updateUserAdminModel = (vals = {}) => {
	const base = new UserAdminModel(vals);
	const model = base.getModel();
	console.log(`Model:`, model);
	return model;
};

// user-related models
export { UserAdminModel };
// facility-related models
export { NewFacilityModel };

// model updater utils
export { updateNewFacilityModel, updateUserAdminModel };
